<template>
  <header class="app-header navbar" v-if="showHambergerBar">
    <button class="navbar-toggler sidebar-toggler d-md-down-none mr-auto"
    type="button" @click="sidebarToggle">
      <span><i class="fa fa-navicon hambergerSlidebar"></i></span>
    </button>
    <button class="navbar-toggler mobile-sidebar-toggler d-lg-none"
    type="button" @click="mobileSidebarToggle">
      <span><i class="fa fa-navicon hambergerSlidebar"></i></span>
    </button>
    <b-navbar-nav class="ml-auto">
      <span class="username" v-if="name">{{name}}</span>
      <HeaderDropdownAccnt/>
    </b-navbar-nav>
  </header>
</template>

<script>
import {
  mapGetters,
} from 'vuex';
import HeaderDropdownAccnt from './HeaderDropdownAccnt.vue';

export default {
  name: 'c-header',
  components: {
    HeaderDropdownAccnt,
  },
  data() {
    return {
      showHambergerBar: true,
    };
  },
  mounted() {
    const { path } = this.$route;
    if (path === '/checkstatus/check' || path === '/checkstatus/form1' || path === '/checkstatus/form2') {
      this.showHambergerBar = false;
    }
  },
  computed: {
    ...mapGetters('User', ['name']),
  },
  methods: {
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle('sidebar-hidden');
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle('sidebar-mobile-show');
    },
  },
};
</script>

<style lang="css">
  .app-header {
    border: 0px;
  }

  .username {
    margin-right: 20px;
  }

  .hambergerSlidebar {
    color: #231f20;
  }
</style>
