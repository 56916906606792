<template>
  <div>
    <b-row>
      <b-col sm="4">
        <b-form-group label="หมวดหมู่มองรู้ดูออก" label-for="category">
          <b-form-select @input="updateData({category_id: $event})"
          :value="knowledgeDetail.category_id">
          <option v-for="category in listCategory" :key="category.id"
          :value="category.id">{{category.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="4">
        <b-form-group label="สถานะ" label-for="public">
          <b-form-select @input="updateData({public: $event})"
          :value="knowledgeDetail.public">
          <option v-for="publics in pub" :key="publics.value"
          :value="publics.value">{{publics.text}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="4">
        <b-form-group label="keyword ที่ใช้ SEO" label-for="keyword">
          <b-input-group>
            <b-form-input type="text" name="keyword" :value="knowledgeDetail.keyword"
            @change="updateData({keyword: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <br>
    <b-row v-if="!knowledgeDetail.is_media">
      <b-col sm="3">
        <b-form-group label="รูปปกของมองรู้ดูออก" label-for="profile_img">
          <b-img fluid-grow :src="knowledgeDetail.profile_img"
          v-if="knowledgeDetail.profile_img" alt="profile image">
          </b-img>
          <br>
          <label for="uploadKnowledgeProfile" class="labelBtnUploadImage">
            อัปโหลดรูปปก
          </label>
          <input id="uploadKnowledgeProfile" type="file" ref="knowledgeProfile"
          accept=".jpg, .png, .bmp" @change="uploadKnowledgeProfileFile()"/>
          <p v-if="verifySizeProfile">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="รูป banner" label-for="banner_img">
          <b-img fluid-grow :src="knowledgeDetail.banner_img"
          v-if="knowledgeDetail.banner_img && !knowledgeDetail.is_Media" alt="banner image"></b-img>
          <br>
          <label for="uploadKnowledgeBanner" class="labelBtnUploadImage">
            อัปโหลด Banner
          </label>
          <input id="uploadKnowledgeBanner" type="file" ref="knowledgeBanner"
          accept=".jpg, .png, .bmp" @change="uploadKnowledgeBannerFile()"/>
          <p v-if="verifySizeBanner">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
        </b-form-group>
      </b-col>
    </b-row>
    <div v-if="!createMode">
      <br>
      <p>เวลาที่สร้าง {{formateDate(knowledgeDetail.create_at)}}</p>
      <p>เวลาที่อัปเดตล่าสุด {{formateDate(knowledgeDetail.update_at)}}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Knowledge', ['knowledgeDetail', 'listCategory', 'createMode']),
  },
  data() {
    return {
      pub: [
        {
          value: 0,
          text: 'แบบร่าง',
        },
        {
          value: 1,
          text: 'เผยแพร่',
        },
      ],
      verifySizeBanner: false,
      verifySizeProfile: false,
    };
  },
  methods: {
    ...mapActions('Knowledge', ['updateField', 'setLoading']),
    formateDate(date) {
      return date ? moment(date).format('DD/MM/yyyy, h:mm:ss a') : moment(this.knowledgeDetail.create_at).format('DD/MM/yyyy, h:mm:ss a');
    },
    async uploadKnowledgeProfileFile() {
      this.setLoading(true);
      if (this.$refs.knowledgeProfile.files[0]) {
        const file = this.$refs.knowledgeProfile.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeProfile = true;
        } else {
          this.verifySizeProfile = false;
          const uploadRes = await this.uploadLocalFile(file, name);
          if (uploadRes.status === 200) {
            this.updateData({ profile_img: uploadRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadKnowledgeBannerFile() {
      this.setLoading(true);
      if (this.$refs.knowledgeBanner.files[0]) {
        const file = this.$refs.knowledgeBanner.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeBanner = true;
        } else {
          this.verifySizeBanner = false;
          const uplaodRes = await this.uploadLocalFile(file, name);
          if (uplaodRes.status === 200) {
            this.updateData({ banner_img: uplaodRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedKnowledge', this.knowledgeDetail);
    },
  },
};
</script>

<style>
  .titleStatusRegist {
    font-size: 30px;
    color: #173B6B;
  }
  .lastNote {
    margin-bottom: 0;
  }
  .seoHeigth {
    height: 50px;
  }
</style>
