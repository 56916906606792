/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import productAPI from '@/api/product';
import _ from 'lodash';

const state = {
  tableData: [],
  selectedPublic: 'All',
  listProduct: [],
  listPublicType: ['All', 'เผยแพร่', 'แบบร่าง'],
  showLoading: false,
  productDetail: {},
  listArea: [],
  listHeritage: [],
  listCategory: [],
  createMode: false,
  selectedCategory: 'All',
  listCategoryFilter: [],
  checkNameTh: false,
  checkNameEng: false,
  editorOption: {
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', { align: ['', 'center', 'right'] }, 'blockquote', 'link',
            { list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    },
  },
};

const getters = {
  selectedPublic: (state) => state.selectedPublic,
  listProduct: (state) => state.listProduct,
  listPublicType: (state) => state.listPublicType,
  showLoading: (state) => state.showLoading,
  productDetail: (state) => state.productDetail,
  listArea: (state) => state.listArea,
  listHeritage: (state) => state.listHeritage,
  listCategory: (state) => state.listCategory,
  createMode: (state) => state.createMode,
  editorOption: (state) => state.editorOption,
  selectedCategory: (state) => state.selectedCategory,
  listCategoryFilter: (state) => state.listCategoryFilter,
  checkNameTh: (state) => state.checkNameTh,
  checkNameEng: (state) => state.checkNameEng,
};

const actions = {
  async getData({
    commit,
  }) {
    commit('SET_SHOW_LOADING', true);
    commit('SET_LIST_PRODUCT', []);
    const listProduct = [];
    const allProduct = await productAPI.getProduct();
    const allHeritage = await productAPI.getHeritage();
    const allArea = await productAPI.getArea();
    const allCategory = await productAPI.getProductCategory();

    const products = allProduct.data;
    const heritages = allHeritage.data;
    const areas = allArea.data;
    const categories = allCategory.data;

    for (let i = 0; i < products.length; i += 1) {
      let heritage = '';
      for (let j = 0; j < heritages.length; j += 1) {
        if (products[i].heritage_id === heritages[j].id) {
          heritage = heritages[j].name;
          break;
        }
      }
      let area = '';
      for (let j = 0; j < areas.length; j += 1) {
        if (products[i].area_id === areas[j].id) {
          area = areas[j].name;
          break;
        }
      }
      let category = '';
      for (let j = 0; j < categories.length; j += 1) {
        if (products[i].category_id === categories[j].id) {
          category = categories[j].name;
          break;
        }
      }
      let publicText;
      if (products[i].public === 0) {
        publicText = 'แบบร่าง';
      } else {
        publicText = 'เผยแพร่';
      }
      const combineProduct = {
        name: products[i].name,
        category_id: category,
        area_id: area,
        heritage_id: heritage,
        public: publicText,
        view: products[i].view,
        create_at: products[i].create_at,
        update_at: products[i].update_at,
        id: products[i].id,
      };
      listProduct.push(combineProduct);
    }

    const listCategoryFilter = [];
    listCategoryFilter.push('All');
    for (let i = 0; i < categories.length; i += 1) {
      listCategoryFilter.push(categories[i].name);
    }

    commit('SET_LIST_CATEGORY_FILTER', listCategoryFilter);
    await commit('SET_LIST_PRODUCT', listProduct);
    await commit('SET_DATA', listProduct);
    await commit('UPDATE_TABLE', listProduct);
    commit('SET_SHOW_LOADING', false);
  },
  async getProductDetail({
    commit,
    dispatch,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    let productDetail;
    if (id === 'create') {
      productDetail = {
        name: '',
        name_eng: '',
        category_id: 1,
        area_id: 1,
        profile_img: null,
        banner_img: null,
        public: 0,
      };
      commit('SET_CREATE_MODE', true);
    } else {
      productDetail = await productAPI.getProductById(id);

      const productVdo = await productAPI.getProductVdo(id);
      const allProductSub = await productAPI.getProductSub(id);

      const vdoTh = [];
      const vdoEng = [];
      const subTh = [];
      const subEng = [];
      for (let i = 0; i < productVdo.data.length; i += 1) {
        if (productVdo.data[i].name) {
          vdoTh.push(productVdo.data[i]);
        } else {
          vdoEng.push(productVdo.data[i]);
        }
      }

      for (let i = 0; i < allProductSub.data.length; i += 1) {
        if (allProductSub.data[i].name) {
          subTh.push(allProductSub.data[i]);
        } else {
          subEng.push(allProductSub.data[i]);
        }
      }

      _.extend(productDetail, { productVdo: vdoTh },
        { productVdoEng: vdoEng });

      const productSub = subTh;
      let subTitle1;
      let subTitle2;

      switch (subTh.length) {
        case 2:
          if (productSub[0].product_media_id === vdoTh[0].id) {
            // sub 1 == video 1
            subTitle1 = productSub[0]; // eslint-disable-line
            subTitle2 = productSub[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = productSub[1]; // eslint-disable-line
            subTitle2 = productSub[0]; // eslint-disable-line
          }
          _.extend(productDetail, { sub1: subTitle1 }, { sub2: subTitle2 });
          break;
        case 1:
          if (vdoTh.length > 1) {
            if (productSub[0].product_media_id === vdoTh[0].id) {
              // sub 1 == video 1
              subTitle1 = productSub[0]; // eslint-disable-line
              _.extend(productDetail, { sub1: subTitle1 });
            } else {
              // sub 2 == vdo 1
              subTitle2 = productSub[0]; // eslint-disable-line
              _.extend(productDetail, { sub2: subTitle2 });
            }
          } else {
            subTitle1 = productSub[0]; // eslint-disable-line
            _.extend(productDetail, { sub1: subTitle1 });
          }
          break;
        default:
          break;
      }

      const productSubEng = subEng;
      let subTitleEng1;
      let subTitleEng2;

      switch (subEng.length) {
        case 2:
          if (productSubEng[0].product_media_id === vdoEng[0].id) {
            // sub 1 == video 1
            subTitleEng1 = productSubEng[0]; // eslint-disable-line
            subTitleEng2 = productSubEng[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitleEng1 = productSubEng[1]; // eslint-disable-line
            subTitleEng2 = productSubEng[0]; // eslint-disable-line
          }
          _.extend(productDetail, { subEng1: subTitleEng1 }, { subEng2: subTitleEng2 });
          break;
        case 1:
          if (vdoEng.length > 1) {
            if (productSubEng[0].product_media_id === vdoEng[0].id) {
              // sub 1 == video 1
              subTitleEng1 = productSubEng[0]; // eslint-disable-line
              _.extend(productDetail, { subEng1: subTitleEng1 });
            } else {
              // sub 2 == vdo 1
              subTitleEng2 = productSubEng[0]; // eslint-disable-line
              _.extend(productDetail, { subEng2: subTitleEng2 });
            }
          } else {
            subTitleEng1 = productSubEng[0]; // eslint-disable-line
            _.extend(productDetail, { subEng1: subTitleEng1 });
          }
          break;
        default:
          break;
      }

      const allProductPicture = await productAPI.getProductPicture(id);
      _.extend(productDetail, { productMultiFilesOld: allProductPicture.data });

      commit('SET_CREATE_MODE', false);
    }
    commit('SET_PRODUCT_DETAIL', productDetail);
    await dispatch('getAllOptions');
    commit('SET_SHOW_LOADING', false);
  },
  async getAllOptions({
    commit,
  }) {
    const listArea = await productAPI.getArea();
    const listAllHeritage = await productAPI.getHeritage();
    const listCategory = await productAPI.getProductCategory();

    const heritages = listAllHeritage.data;

    const listHeritage = [];
    for (let i = 0; i < heritages.length; i += 1) {
      let heritageName;
      if (heritages[i].name.length > 100) {
        heritageName = `${heritages[i].name.substring(0, 101)}...`;
      } else {
        heritageName = heritages[i].name;
      }

      const showHeritage = {
        id: heritages[i].id,
        name: heritageName,
        name_eng: heritages[i].name_eng,
      };
      listHeritage.push(showHeritage);
    }

    await commit('SET_LIST_AREA', listArea.data);
    await commit('SET_LIST_HERITAGE', listHeritage);
    await commit('SET_LIST_CATEGORY', listCategory.data);
  },
  async updateProductDetail({
    commit,
    state,
  }) {
    commit('SET_SHOW_LOADING', true);
    console.log('updateProductDetail');
    const updateDetail = state.productDetail;
    const timeNow = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const updateProduct = {
      name: updateDetail.name,
      category_id: updateDetail.category_id,
      area_id: updateDetail.category_id,
      concept: updateDetail.concept,
      heritage_id: updateDetail.heritage_id,
      contact: updateDetail.contact,
      product_ip: updateDetail.product_ip,
      detail_concept: updateDetail.detail_concept,
      detail_show: updateDetail.detail_show,
      detail_number_people: updateDetail.detail_number_people,
      detail_length: updateDetail.detail_length,
      profile_img: updateDetail.profile_img,
      banner_img: updateDetail.banner_img,
      public: updateDetail.public,
      keyword: updateDetail.keyword,
      name_eng: updateDetail.name_eng,
      concept_eng: updateDetail.concept_eng,
      contact_eng: updateDetail.contact_eng,
      product_ip_eng: updateDetail.product_ip_eng,
      detail_concept_eng: updateDetail.detail_concept_eng,
      detail_show_eng: updateDetail.detail_show_eng,
      detail_number_people_eng: updateDetail.detail_number_people_eng,
      detail_length_eng: updateDetail.detail_length_eng,
      update_at: timeNow,
    };
    let updateRes;
    if (state.createMode) {
      _.extend(updateProduct, { create_at: timeNow, view: 0 });
      updateRes = await productAPI.createProduct(updateProduct);

      if (updateDetail.vdo1) {
        const updateVdo1 = {
          product_id: updateRes.id,
          name: updateDetail.vdo1.name,
          file_path: updateDetail.vdo1.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          product_media_id: null,
          name_eng: null,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdo1 = await productAPI.createProductMedia(updateVdo1);

        if (updateDetail.sub1) {
          // create sub 1
          const updateSub1 = {
            product_id: updateRes.id,
            name: updateDetail.sub1.name,
            file_path: updateDetail.sub1.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            product_media_id: vdo1.id,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await productAPI.createProductMedia(updateSub1);
        }
      }

      if (updateDetail.vdo2) {
        const updateVdo2 = {
          product_id: updateRes.id,
          name: updateDetail.vdo2.name,
          file_path: updateDetail.vdo2.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          product_media_id: null,
          name_eng: null,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdo2 = await productAPI.createProductMedia(updateVdo2);

        if (updateDetail.sub2) {
          // create sub 2
          const updateSub2 = {
            product_id: updateRes.id,
            name: updateDetail.sub2.name,
            file_path: updateDetail.sub2.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            product_media_id: vdo2.id,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await productAPI.createProductMedia(updateSub2);
        }
      }

      if (updateDetail.vdoEng1) {
        const updateVdoEng1 = {
          product_id: updateRes.id,
          name: null,
          file_path: updateDetail.vdoEng1.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          product_media_id: null,
          name_eng: updateDetail.vdoEng1.name_eng,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdoEng1 = await productAPI.createProductMedia(updateVdoEng1);

        if (updateDetail.subEng1) {
          // create sub 1
          const updateSubEng1 = {
            product_id: updateRes.id,
            name: null,
            file_path: updateDetail.subEng1.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            product_media_id: vdoEng1.id,
            name_eng: updateDetail.subEng1.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await productAPI.createProductMedia(updateSubEng1);
        }
      }

      if (updateDetail.vdoEng2) {
        const updateVdoEng2 = {
          product_id: updateRes.id,
          name: null,
          file_path: updateDetail.vdoEng2.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          product_media_id: null,
          name_eng: updateDetail.vdoEng2.name_eng,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdoEng2 = await productAPI.createProductMedia(updateVdoEng2);

        if (updateDetail.subEng2) {
          // create sub 2
          const updateSubEng2 = {
            product_id: updateRes.id,
            name: null,
            file_path: updateDetail.subEng2.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            product_media_id: vdoEng2.id,
            name_eng: updateDetail.subEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await productAPI.createProductMedia(updateSubEng2);
        }
      }

      if (updateDetail.productMultiFiles) {
        const newMultiFile = [];
        for (let i = 0; i < updateDetail.productMultiFiles.length; i += 1) {
          const multiFile = {
            product_id: updateRes.id,
            name: updateDetail.productMultiFiles[i].key,
            file_path: updateDetail.productMultiFiles[i].location,
            is_picture: true,
            is_vdo: false,
            is_subtitle: false,
            product_media_id: null,
            name_eng: updateDetail.productMultiFiles[i].key,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          newMultiFile.push(multiFile);
        }

        const promises = newMultiFile.map((val) => productAPI.createProductMedia(val)
          .then((results) => {
            console.log(results);
            return val;
          }));
        Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
    } else {
      const idNum = updateDetail.id;
      updateRes = await productAPI.updateProduct(idNum, updateProduct);

      const allProductVdo = await productAPI.getProductVdo(idNum);
      const allProductSub = await productAPI.getProductSub(idNum);
      const productVdo = allProductVdo.data;
      const productSub = allProductSub.data;

      const vdoTh = [];
      const vdoEng = [];
      const subTh = [];
      const subEng = [];
      for (let i = 0; i < productVdo.length; i += 1) {
        if (productVdo[i].name) {
          vdoTh.push(productVdo[i]);
        } else {
          vdoEng.push(productVdo[i]);
        }
      }

      for (let i = 0; i < productSub.length; i += 1) {
        if (productSub[i].name) {
          subTh.push(productSub[i]);
        } else {
          subEng.push(productSub[i]);
        }
      }

      // create vdo sub th
      if (vdoTh.length === 2) {
        // Heritage has 2 vdo
        if (updateDetail.vdo1) {
          // update vdo 1
          const updateVdo1 = {
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
          };
          await productAPI.patchProductMedia(vdoTh[0].id, updateVdo1);
        }

        if (updateDetail.vdo2) {
          // update vdo 2
          const updateVdo2 = {
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
          };
          await productAPI.patchProductMedia(vdoTh[1].id, updateVdo2);
        }

        let subTitle1;
        let subTitle2;
        if (subTh.length === 2) {
          // have 2 subtitle
          if (subTh[0].product_media_id === vdoTh[0].id) {
            // sub 1 == video 1
            subTitle1 = subTh[0]; // eslint-disable-line
            subTitle2 = subTh[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = subTh[1]; // eslint-disable-line
            subTitle2 = subTh[0]; // eslint-disable-line
          }

          if (updateDetail.sub1) {
            // update sub 1
            const updateSub1 = {
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
            };
            await productAPI.patchProductMedia(subTitle1.id, updateSub1);
          }

          if (updateDetail.sub2) {
            // update sub 2
            const updateSub2 = {
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
            };
            await productAPI.patchProductMedia(subTitle2.id, updateSub2);
          }
        }
        if (subTh.length === 1) {
          // heritage has 1 subtitle
          if (subTh[0].product_media_id === vdoTh[0].id) {
            // has subtitle in vdo 1
            subTitle1 = subTh[0]; // eslint-disable-line

            if (updateDetail.sub1) {
              // update subtitle 1
              const updateSub1 = {
                name: updateDetail.sub1.name,
                file_path: updateDetail.sub1.file_path,
              };
              await productAPI.patchProductMedia(subTitle1.id, updateSub1);
            }

            if (updateDetail.sub2) {
              // create subtitle 2
              const createSub2 = {
                product_id: idNum,
                name: updateDetail.sub2.name,
                file_path: updateDetail.sub2.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                product_media_id: vdoTh[1].id,
                name_eng: null,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await productAPI.createProductMedia(createSub2);
            }
          } else {
            // has subtitle in vdo 2
            subTitle2 = subTh[0]; // eslint-disable-line

            if (updateDetail.sub2) {
              // update subtitle 2
              const updateSub2 = {
                name: updateDetail.sub2.name,
                file_path: updateDetail.sub2.file_path,
              };
              await productAPI.patchProductMedia(subTitle2.id, updateSub2);
            }

            if (updateDetail.sub1) {
              // create subtitle 1
              const createSub1 = {
                product_id: idNum,
                name: updateDetail.sub1.name,
                file_path: updateDetail.sub1.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                product_media_id: vdoTh[0].id,
                name_eng: null,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await productAPI.createProductMedia(createSub1);
            }
          }
        } else if (subTh.length === 0) {
          // heritage has no subtitle
          if (updateDetail.sub1) {
            // create subtitle 1
            const createSub1 = {
              product_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdoTh[0].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(createSub1);
          }

          if (updateDetail.sub2) {
            // create subtitle 2
            const createSub2 = {
              product_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdoTh[1].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(createSub2);
          }
        }
      } else if (vdoTh.length === 1) {
        // heritage has 1 video
        if (updateDetail.vdo1) {
          // update vdo 1
          const updateVdo1 = {
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
          };
          await productAPI.patchProductMedia(vdoTh[0].id, updateVdo1);
        }

        let subTitle1;
        if (subTh.length === 1) {
          // heritage has 1 subtitle
          subTitle1 = subTh[0] // eslint-disable-line
          if (updateDetail.sub1) {
            // update subtitle 1
            const updateSub1 = {
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
            };
            await productAPI.patchProductMedia(subTitle1.id, updateSub1);
          }
        } else {
          if (updateDetail.sub1) { // eslint-disable-line
            const updateSub1 = {
              product_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdoTh[0].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub1);
          }
        }

        if (updateDetail.vdo2) {
          // crate vdo 2
          const updateVdo2 = {
            product_id: idNum,
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            product_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await productAPI.createProductMedia(updateVdo2);

          if (updateDetail.sub2) {
            // create sub 2
            const updateSub2 = {
              product_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdo2.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub2);
          }
        }
      } else {
        // heritage has no vdo
        if (updateDetail.vdo1) {
          // create vdo 1
          const updateVdo1 = {
            product_id: idNum,
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            product_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo1 = await productAPI.createProductMedia(updateVdo1);

          if (updateDetail.sub1) {
            // create sub 2
            const updateSub1 = {
              product_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdo1.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub1);
          }
        }

        if (updateDetail.vdo2) {
          // create vdo 2
          const updateVdo2 = {
            product_id: idNum,
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            product_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await productAPI.createProductMedia(updateVdo2);

          if (updateDetail.sub2) {
            // create sub 2
            const updateSub2 = {
              product_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdo2.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub2);
          }
        }
      }

      // create vdo sub eng
      if (vdoEng.length === 2) {
        // Heritage has 2 vdo
        if (updateDetail.vdoEng1) {
          // update vdo 1
          const updateVdo1 = {
            name_eng: updateDetail.vdoEng1.name_eng,
            file_path: updateDetail.vdoEng1.file_path,
          };
          await productAPI.patchProductMedia(vdoEng[0].id, updateVdo1);
        }

        if (updateDetail.vdoEng2) {
          // update vdo 2
          const updateVdo2 = {
            name_eng: updateDetail.vdoEng2.name_eng,
            file_path: updateDetail.vdoEng2.file_path,
          };
          await productAPI.patchProductMedia(vdoEng[1].id, updateVdo2);
        }

        let subTitle1;
        let subTitle2;
        if (subEng.length === 2) {
          // have 2 subtitle
          if (subEng[0].product_media_id === vdoEng[0].id) {
            // sub 1 == video 1
            subTitle1 = subEng[0]; // eslint-disable-line
            subTitle2 = subEng[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = subEng[1]; // eslint-disable-line
            subTitle2 = subEng[0]; // eslint-disable-line
          }

          if (updateDetail.subEng1) {
            // update sub 1
            const updateSub1 = {
              name_eng: updateDetail.subEng1.name_eng,
              file_path: updateDetail.subEng1.file_path,
            };
            await productAPI.patchProductMedia(subTitle1.id, updateSub1);
          }

          if (updateDetail.subEng2) {
            // update sub 2
            const updateSub2 = {
              name_eng: updateDetail.subEng2.name_eng,
              file_path: updateDetail.subEng2.file_path,
            };
            await productAPI.patchProductMedia(subTitle2.id, updateSub2);
          }
        }
        if (subEng.length === 1) {
          // heritage has 1 subtitle
          if (subEng[0].product_media_id === vdoEng[0].id) {
            // has subtitle in vdo 1
            subTitle1 = subEng[0]; // eslint-disable-line

            if (updateDetail.subEng1) {
              // update subtitle 1
              const updateSub1 = {
                name_eng: updateDetail.subEng1.name_eng,
                file_path: updateDetail.subEng1.file_path,
              };
              await productAPI.patchProductMedia(subTitle1.id, updateSub1);
            }

            if (updateDetail.subEng2) {
              // create subtitle 2
              const createSub2 = {
                product_id: idNum,
                name: null,
                file_path: updateDetail.subEng2.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                product_media_id: vdoEng[1].id,
                name_eng: updateDetail.subEng2.name_eng,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await productAPI.createProductMedia(createSub2);
            }
          } else {
            // has subtitle in vdo 2
            subTitle2 = subEng[0]; // eslint-disable-line

            if (updateDetail.subEng2) {
              // update subtitle 2
              const updateSub2 = {
                name_eng: updateDetail.subEng2.name_eng,
                file_path: updateDetail.subEng2.file_path,
              };
              await productAPI.patchProductMedia(subTitle2.id, updateSub2);
            }

            if (updateDetail.subEng1) {
              // create subtitle 1
              const createSub1 = {
                product_id: idNum,
                name: null,
                file_path: updateDetail.subEng1.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                product_media_id: vdoEng[0].id,
                name_eng: updateDetail.subEng1.name_eng,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await productAPI.createProductMedia(createSub1);
            }
          }
        } else if (subEng.length === 0) {
          // heritage has no subtitle
          if (updateDetail.subEng1) {
            // create subtitle 1
            const createSub1 = {
              product_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdoEng[0].id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(createSub1);
          }

          if (updateDetail.subEng2) {
            // create subtitle 2
            const createSub2 = {
              product_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdoEng[1].id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(createSub2);
          }
        }
      } else if (vdoEng.length === 1) {
        console.log('updateDetail Eng vdo 1', updateDetail.subEng1);
        // heritage has 1 video
        if (updateDetail.vdoEng1) {
          console.log('updateDetail Eng if 1', updateDetail.subEng1);
          // update vdo 1
          const updateVdo1 = {
            name_eng: updateDetail.vdoEng1.name_eng,
            file_path: updateDetail.vdoEng1.file_path,
          };
          await productAPI.patchProductMedia(vdoEng[0].id, updateVdo1);
        }

        let subTitle1;
        if (subEng.length === 1) {
          // heritage has 1 subtitle
          subTitle1 = subEng[0] // eslint-disable-line
          if (updateDetail.subEng1) {
            console.log('updateDetail Eng else  1', updateDetail.subEng1);
            // update subtitle 1
            const updateSub1 = {
              name_eng: updateDetail.subEng1.name_eng,
              file_path: updateDetail.subEng1.file_path,
            };
            await productAPI.patchProductMedia(subTitle1.id, updateSub1);
          }
        } else {
          console.log('updateDetail Eng 1', updateDetail.subEng1);

          if (updateDetail.subEng1) { // eslint-disable-line
            const updateSub1 = {
              product_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdoEng[0].id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub1);
          }
        }

        if (updateDetail.vdoEng2) {
          // crate vdo 2

          console.log('create vdo 2');
          const updateVdo2 = {
            product_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            product_media_id: null,
            name_eng: updateDetail.vdoEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await productAPI.createProductMedia(updateVdo2);

          if (updateDetail.subEng2) {
            // create sub 2
            console.log('create sub 2');

            const updateSub2 = {
              product_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdo2.id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub2);
          }
        }
      } else {
        // heritage has no vdo
        if (updateDetail.vdoEng1) {
          // create vdo 1
          const updateVdo1 = {
            product_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng1.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            product_media_id: null,
            name_eng: updateDetail.vdoEng1.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo1 = await productAPI.createProductMedia(updateVdo1);

          if (updateDetail.subEng1) {
            // create sub 2
            const updateSub1 = {
              product_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdo1.id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub1);
          }
        }

        if (updateDetail.vdoEng2) {
          // create vdo 2
          const updateVdo2 = {
            product_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            product_media_id: null,
            name_eng: updateDetail.vdoEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await productAPI.createProductMedia(updateVdo2);

          if (updateDetail.subEng2) {
            // create sub 2
            const updateSub2 = {
              product_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              product_media_id: vdo2.id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await productAPI.createProductMedia(updateSub2);
          }
        }
      }

      if (updateDetail.productMultiFiles) {
        const newMultiFile = [];
        for (let i = 0; i < updateDetail.productMultiFiles.length; i += 1) {
          const multiFile = {
            product_id: updateRes.id,
            name: updateDetail.productMultiFiles[i].key,
            file_path: updateDetail.productMultiFiles[i].location,
            is_picture: true,
            is_vdo: false,
            is_subtitle: false,
            product_media_id: null,
            name_eng: updateDetail.productMultiFiles[i].key,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          newMultiFile.push(multiFile);
        }

        const promises = newMultiFile.map((val) => productAPI.createProductMedia(val)
          .then((results) => {
            console.log(results);
            return val;
          }));
        Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
      if (updateDetail.deleteImageFiles) {
        const promises = updateDetail.deleteImageFiles.map((val) => productAPI.deleteProductMedia(val) // eslint-disable-line
          .then((results) => {
            console.log(results);
            return results;
          }));
        await Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
    }
    if (updateRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  async deleteProduct({
    commit,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    const delRes = await productAPI.deleteProduct(id);
    if (delRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  setLoading({
    commit,
  }, data) {
    commit('SET_SHOW_LOADING', data);
  },
  updateField({
    commit,
  }, data) {
    Object.keys(data).forEach((key) => {
      commit('UPDATE_FIELD', {
        [key]: data[key],
      });
    });
  },
  clearState({
    commit,
  }) {
    commit('CLEAR_STATE');
  },
  setSelectedPublic({
    commit,
  }, data) {
    commit('SET_SELECTED_PUBLIC_TYPE', data);
  },
  setSelectedCategory({
    commit,
  }, data) {
    commit('SET_SELECTED_CATEGORY', data);
  },
  filterCustom({
    commit,
  }, payload) {
    commit('SET_CUSTOM_FILTER', payload);
  },
  setCheckNameTh({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_TH', data);
  },
  setCheckNameEng({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_ENG', data);
  },
};

const mutations = {
  UPDATE_TABLE(state, data) {
    state.data = data;
  },
  SET_DATA(state, data) {
    state.tableData = data;
  },
  SET_SHOW_LOADING(state, data) {
    state.showLoading = data;
  },
  SET_LIST_PRODUCT(state, data) {
    state.listProduct = data;
  },
  CLEAR_STATE(state) {
    state.showLoading = false;
    state.selectedPublic = 'All';
    state.selectedCategory = 'All';
    state.createMode = false;
    state.productDetail = {};
    state.checkNameTh = false;
    state.checkNameEng = false;
  },
  SET_SELECTED_PUBLIC_TYPE(state, data) {
    state.selectedPublic = data;
  },
  SET_LIST_CATEGORY_FILTER(state, data) {
    state.listCategoryFilter = data;
  },
  SET_SELECTED_CATEGORY(state, data) {
    state.selectedCategory = data;
  },
  UPDATE_FIELD(state, data) {
    Object.assign(state.productDetail, data);
  },
  SET_PRODUCT_DETAIL(state, data) {
    state.productDetail = data;
  },
  SET_LIST_HERITAGE(state, data) {
    state.listHeritage = data;
  },
  SET_LIST_AREA(state, data) {
    state.listArea = data;
  },
  SET_LIST_CATEGORY(state, data) {
    state.listCategory = data;
  },
  SET_CREATE_MODE(state, data) {
    state.createMode = data;
  },
  SET_CHECK_NAME_TH(state, data) {
    state.checkNameTh = data;
  },
  SET_CHECK_NAME_ENG(state, data) {
    state.checkNameEng = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
