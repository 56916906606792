<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group class="nameLabelProduct" label-for="product_name_eng"
        label-cols-sm="5" :style="checkNameEng ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อผลิตภัณฑ์ภาษาอังกฤษ <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="product_name_eng" :value="productDetail.name_eng"
            @change="updateData({name_eng: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="แนวคิดจากชุมชนภาษาอังกฤษ"
        label-for="product_concept_eng" label-cols-sm="5">
          <quill-editor v-model="concept_eng" :options="editorOption" class="editorHeight"
          @change="updateData({concept_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ติดต่อประสานงานภาษาอังกฤษ"
        label-for="contact_eng" label-cols-sm="5">
          <quill-editor v-model="contact_eng" :options="editorOption" class="editorHeight"
          @change="updateData({contact_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ทรัพย์สินทางปัญญาภาษาอังกฤษ"
        label-for="product_ip_eng" label-cols-sm="5">
          <quill-editor v-model="product_ip_eng" :options="editorOption" class="editorHeight"
          @change="updateData({product_ip_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="แนวความคิดภาษาอังกฤษ"
        label-for="detail_concept_eng" label-cols-sm="5">
          <quill-editor v-model="detail_concept_eng" :options="editorOption" class="editorHeight"
          @change="updateData({detail_concept_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="รูปแบบการแสดงภาษาอังกฤษ"
        label-for="detail_show_eng" label-cols-sm="5">
          <quill-editor v-model="detail_show_eng" :options="editorOption" class="editorHeight"
          @change="updateData({detail_show_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

         <b-form-group label="จำนวนนักแสดงภาษาอังกฤษ"
         label-for="detail_number_people_eng" label-cols-sm="5">
          <quill-editor v-model="detail_number_people_eng" :options="editorOption"
          class="editorHeight"
          @change="updateData({detail_number_people_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ความยาวของการแสดงภาษาอังกฤษ"
        label-for="detail_length_eng" label-cols-sm="5">
          <quill-editor v-model="detail_length_eng" :options="editorOption" class="editorHeight"
          @change="updateData({detail_length_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('Product', ['productDetail', 'editorOption', 'checkNameEng']),
    ProductData() {
      return this.productDetail;
    },
  },
  watch: {
    ProductData() {
      this.concept_eng = this.productDetail.concept_eng;
      this.contact_eng = this.productDetail.contact_eng;
      this.product_ip_eng = this.productDetail.product_ip_eng;
      this.detail_concept_eng = this.productDetail.detail_concept_eng;
      this.detail_show_eng = this.productDetail.detail_show_eng;
      this.detail_number_people_eng = this.productDetail.detail_number_people_eng;
      this.detail_length_eng = this.productDetail.detail_length_eng;
    },
  },
  data() {
    return {
      concept_eng: '',
      contact_eng: '',
      product_ip_eng: '',
      detail_concept_eng: '',
      detail_show_eng: '',
      detail_number_people_eng: '',
      detail_length_eng: '',
    };
  },
  methods: {
    ...mapActions('Product', ['updateField']),
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedProductDetail', this.productDetail);
    },
  },
};
</script>

<style>
  .titleDetailProduct {
    font-size: 20px;
    color: #173B6B;
  }
  .editorHeight {
    height: 200px;
  }
  .nameLabelProduct {
    height: 60px;
  }
</style>
