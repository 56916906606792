<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label-for="knowledge_name" label-cols-sm="5"
        class="nameHeigth" :style="checkNameTh ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อมองรู้ดูออก <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="knowledge_name" :value="knowledgeDetail.name"
            @change="updateData({name: $event})" :autofocus="createMode">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="แท็กของมองรู้ดูออก" label-for="knowledge_tag" label-cols-sm="5"
        class="nameHeigth">
          <b-input-group>
            <b-form-input type="text" name="knowledge_tag" :value="knowledgeDetail.tag"
              @change="updateData({tag: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="รายละเอียดมองรู้ดูออก" label-for="detail" label-cols-sm="5"
          v-if="knowledgeDetail.is_publication || knowledgeDetail.is_article"
          class="gridLabel">
            <quill-editor v-model="detail" :options="editorOption" class="editorHeight"
            @change="updateData({detail:
            `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
            <input type="file" id="getFile" @change="uploadFunction($event)" />
          <br><br><br>
        </b-form-group>

        <br>
        <b-form-group label="ไฟล์ .pdf" label-for="knowledge_file_pdf" label-cols-sm="5"
        v-if="knowledgeDetail.is_publication">
          <b-button variant="warning" @click="openFile(knowledgeDetail.file_pdf)"
          v-if="knowledgeDetail.file_pdf" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadKnowledgePdf" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadKnowledgePdf" type="file" ref="knowledge_pdfFile" accept=".pdf"
          @change="uploadKnowledgePdfFile()"/>
        </b-form-group>

        <b-form-group label="ไฟล์ .docx" label-for="knowledge_file_word" label-cols-sm="5"
        v-if="knowledgeDetail.is_publication">
          <b-button variant="warning" @click="openFile(knowledgeDetail.file_word)"
          v-if="knowledgeDetail.file_word" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadKnowledgeWord" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadKnowledgeWord" type="file" ref="knowledge_wordFile" accept=".docx, .doc"
          @change="uploadKnowledgeWordFile()"/>
        </b-form-group>

        <b-col sm="12" v-if="knowledgeDetail.is_media">
          <br>
          <video width="60%" height="auto" controls v-if="detail">
            <source :src="detail"/>
          </video>
          <br>
          <label for="uploadKnowledgeVdoTh" class="labelBtnUploadImage">
            อัปโหลดวิดีโอ
          </label>
          <input id="uploadKnowledgeVdoTh" type="file" ref="knowledgeVdoTh"
          accept=".mp4"
          @change="uploadKnowledgeVdoTh()"/>
          <br>
          <br>
          <b-form-group label="ไฟล์คำบรรยาย" label-for="knowledge_sub_path" label-cols-sm="5"
          v-if="knowledgeDetail.is_media">
            <b-button variant="warning" @click="openFile(knowledgeDetail.sub_path)"
            v-if="knowledgeDetail.sub_path" class="labelBtnOpenFile subFile">
              เปิดไฟล์</b-button>
            <label for="uploadKnowledgeSub" class="labelBtnUploadFile subFile">
              อัปโหลดไฟล์
            </label>
            <input id="uploadKnowledgeSub" type="file" ref="knowledge_subPath" accept=".vtt"
            @change="uploadKnowledgeSubFile()"/>
          </b-form-group>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Knowledge', ['knowledgeDetail', 'createMode', 'checkNameTh']),
    KnowledgeData() {
      return this.knowledgeDetail;
    },
  },
  watch: {
    KnowledgeData() {
      this.detail = this.knowledgeDetail.detail ? this.knowledgeDetail.detail : '';
    },
  },
  data() {
    return {
      detail: '',
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike', { align: ['', 'center', 'right'] }, 'blockquote', 'link',
                { color: ['#000000', '#e60000', '#0000ff', '#ffff00', '#008a00'] }, { list: 'ordered' }, { list: 'bullet' },
                'image', 'video'],
            ],
            handlers: {
              image: this.insertFile,
              video: this.insertFile,
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions('Knowledge', ['updateField', 'setLoading']),
    openFile(data) {
      window.open(data, '_blank');
    },
    async uploadKnowledgePdfFile() {
      this.setLoading(true);
      if (this.$refs.knowledge_pdfFile.files[0]) {
        const file = this.$refs.knowledge_pdfFile.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_pdf: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadKnowledgeWordFile() {
      this.setLoading(true);
      if (this.$refs.knowledge_wordFile.files[0]) {
        const file = this.$refs.knowledge_wordFile.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_word: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadKnowledgeVdoTh() {
      this.setLoading(true);
      if (this.$refs.knowledgeVdoTh.files[0]) {
        this.detail = false;
        const file = this.$refs.knowledgeVdoTh.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ detail: uploadRes.data.url });
          this.updateData({ profile_img: uploadRes.data.url });
          this.updateData({ banner_img: uploadRes.data.url });
        }
        this.detail = uploadRes.data.url;
      }
      this.setLoading(false);
    },
    async uploadKnowledgeSubFile() {
      this.setLoading(true);
      if (this.$refs.knowledge_subPath.files[0]) {
        const file = this.$refs.knowledge_subPath.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ sub_path: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    insertFile() {
      document.getElementById('getFile').click();
    },
    async uploadFunction(e) {
      console.log('uploadFunction', e.target.files);
      this.setLoading(true);
      const file = e.target.files[0];
      console.log(file);
      const uploadRes = await this.uploadLocalFile(file, file.name);
      const fileLocation = uploadRes.data.url;
      const splitFileType = file.type.split('/');
      console.log(splitFileType);

      if (splitFileType[0] === 'image') {
        this.detail += `<img src="${fileLocation}"/>`;
      } else {
        console.log('else');
        this.detail += `<video width="500" controls src="${fileLocation}"></video>`;
      }
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
    },
  },
};
</script>

<style scope>
  .checkForm {
    padding-left: 5px;
  }
  .noteReason {
    font-size: 14px;
    color: red;
  }
  .editorHeight {
    height: 200px;
  }
  input[type="file"] {
    display: none;
  }
  .labelBtnOpenFile {
    margin-right: 20px;
    margin-bottom: 2px;
  }
  .labelBtnUploadFile {
    color: #FFF200!important;
    background-color: #532ce0!important;
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #cccccc;
    color: #666666;
  }
  .nameHeigth {
    height: 65px;
  }
  .gridLabel label {
    height: 60px;
  }
  .subFile {
    margin-bottom: 20px;
  }
</style>
