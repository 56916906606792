<template>
  <div id="productDetail">
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <span class="titleProduct">{{userDetail.name}}</span>
    <UserDetail></UserDetail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserDetail from '../components/Users/UserDetail.vue';

export default {
  components: {
    UserDetail,
  },
  async created() {
    const { id } = this.$route.params;
    await this.$store.dispatch('Users/clearState');
    await this.$store.dispatch('Users/getUserDetail', id);
  },
  computed: {
    ...mapGetters('Users', ['userDetail', 'showLoading']),
  },
};
</script>

<style>
  .titleProduct {
    font-size: 30px;
    color: #173B6B;
  }
  .adminLatestUpdate {
    margin-left: 30px;
    color: #28a745;
  }
  .smeLatestUpdate {
    margin-left: 30px;
    color: red;
  }
  #productDetail {
    padding-left: 20px;
    padding-right: 20px;
  }
  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }
</style>
