<template>
  <div>
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <HeritageTable></HeritageTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userAPI from '@/api/user';
import HeritageTable from '../components/Heritage/HeritageTable.vue';

export default {
  components: {
    HeritageTable,
  },
  computed: {
    ...mapGetters('Heritage', ['showLoading']),
    ...mapGetters('User', ['userData']),
  },
  async created() {
    if (this.userData.role === 'admin') {
      const checkToken = await userAPI.getUser(this.userData);
      console.log('checkToken', checkToken);
    }
  },
};
</script>

<style>
  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }
</style>
