import Vue from 'vue';
import VueRouter from 'vue-router';
import Full from '@/containers/Full.vue';
import Dashboard from '@/views/Dashboard.vue';
import store from '@/store';
import Detail from '@/views/Detail.vue';
import Report from '@/views/Report.vue';
import Heritage from './heritage';
import ConceptProduct from './conceptProduct';
import Product from './product';
import Knowledge from './knowledge';
import Users from './users';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/backoffice/dashboard',
    name: 'Home',
    component: Full,
    children: [
      {
        path: '/backoffice/dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      Heritage,
      ConceptProduct,
      Product,
      Knowledge,
      {
        path: '/backoffice/detail',
        name: 'Detail',
        component: Detail,
      },
      Users,
      {
        path: '/backoffice/report',
        name: 'Report',
        component: Report,
      },
    ],
  },
  {
    path: '/backoffice/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/backoffice/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/* eslint-disable no-lonely-if */
router.beforeEach(async (to, from, next) => {
  if (to.path === '/backoffice/register') {
    next();
  } else {
    if (!store.getters['User/isLoggedIn']) {
      if (to.path === '/backoffice/knowledge/article') {
        await store.dispatch('User/setLogin', to.query);
        next();
      }
      if (to.path === '/backoffice/login') {
        next();
      } else {
        next({ name: 'Login' });
      }
    } else {
      if (to.path === '/backoffice/login') {
        next({ name: 'Dashboard' });
      }
      next();
    }
  }
});

export default router;
