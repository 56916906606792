<template>
  <div>
    <b-row>
      <b-col sm="6">
        <p class="titleTable">มองรู้ดูออก</p>
      </b-col>
      <b-col sm="6">
        <b-button variant="success" class="btnForm1Detail"
          @click="gotoViewDetail('create')">
          สร้างใหม่
        </b-button>
      </b-col>
    </b-row>

    <v-client-table :name="name" :ref="name" :columns="role == 'admin' ? columnAdmin : columnUser"
    :options="options" :data="listKnowledge">
      <div slot="beforeLimit" style="" class="right" v-if="role == 'admin'">
        <div style="margin-right: 10px;">
          <label :label-cols="2" :horizontal="true">ผู้เขียน:</label>
          <b-form-select @input="setSelectedUser" :value="selectedUser"
          :options="[...listUser]">
          </b-form-select>
        </div>
      </div>

      <div slot="beforeLimit" style="" class="right">
        <div style="margin-right: 10px;">
          <label :label-cols="2" :horizontal="true">หมวดหมู่:</label>
          <b-form-select @input="setSelectedCategory" :value="selectedCategory"
          :options="[...listCategoryName]">
          </b-form-select>
        </div>
      </div>

      <div slot="beforeLimit" style="" class="right">
        <div style="margin-right: 10px;">
          <label :label-cols="2" :horizontal="true">ประเภท:</label>
          <b-form-select @input="setSelectedMediaType" :value="selectedMediaType"
          :options="[...listMediaType]">
          </b-form-select>
        </div>
      </div>

      <div slot="beforeLimit" style="" class="right">
        <div style="margin-right: 10px;">
          <label :label-cols="2" :horizontal="true">สถานะเผยแพร่:</label>
          <b-form-select @input="setSelectedPublic" :value="selectedPublic"
          :options="[...listPublicType]">
          </b-form-select>
        </div>
      </div>
      <div slot="create_at" slot-scope="props" target="_blank" style="text-align:center;">
        {{formateDate(props.row.create_at)}}
      </div>
      <div slot="update_at" slot-scope="props" target="_blank" style="text-align:center;">
        {{formateDate(props.row.update_at)}}
      </div>
      <div slot="id" slot-scope="props" target="_blank" style="text-align:center;">
        <a href="#" @click="gotoViewDetail(props.row.id)" style="text-decoration: none;">Details</a>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import moment from 'moment';
import {
  mapGetters,
  mapActions,
} from 'vuex';

export default {
  watch: {
    selectedPublic() {
      this.setMyFilter();
    },
    selectedUser() {
      this.setMyFilter();
    },
    selectedCategory() {
      this.setMyFilter();
    },
    selectedMediaType() {
      this.setMyFilter();
    },
  },
  async created() {
    await this.$store.dispatch('Knowledge/clearState');
    await this.$store.dispatch('Knowledge/getData');
  },
  computed: {
    ...mapGetters('Knowledge', ['listKnowledge', 'selectedPublic', 'listPublicType', 'selectedUser', 'listUser',
      'listCategoryName', 'selectedCategory', 'listMediaType', 'selectedMediaType']),
    ...mapGetters('User', ['role']),
  },
  methods: {
    ...mapActions('Knowledge', ['setSelectedPublic', 'setSelectedUser', 'setSelectedCategory', 'setSelectedMediaType']),
    setMyFilter() {
      const {
        selectedPublic,
        selectedUser,
        selectedCategory,
        selectedMediaType,
      } = this;
      const valueFilter = {
        selectedPublic,
        selectedUser,
        selectedCategory,
        selectedMediaType,
      };
      this.$store.commit('Knowledge/SET_CUSTOM_FILTER', {
        filter: 'smartFilter',
        value: valueFilter,
      });
    },
    formateDate(date) {
      return date ? moment(date).format('DD/MM/yyyy, h:mm:ss a') : '-';
    },
    gotoViewDetail(id) {
      this.$router.push(`detail/${id}`);
    },
  },
  data() {
    return {
      columnAdmin: [
        'name',
        'user_id',
        'category_id',
        'type_id',
        'public',
        'view',
        'create_at',
        'update_at',
        'id',
      ],
      columnUser: [
        'name',
        'category_id',
        'type_id',
        'public',
        'view',
        'create_at',
        'update_at',
        'id',
      ],
      tableData: [],
      name: 'Knowledge',
      options: {
        texts: {
          filterPlaceholder: 'Search',
        },
        preserveState: true,
        headings: {
          name: 'ชื่อมองรู้ดูออก',
          user_id: 'ชื่อผู้เขียน',
          category_id: 'หมวดหมู่มองรู้ดูออก',
          type_id: 'ประเภทมองรู้ดูออก',
          public: 'สถานะ',
          view: 'ยอดการเข้าชม',
          create_at: 'เวลาที่สร้าง',
          update_at: 'เวลาที่อัปเดตล่าสุด',
          id: 'รายละเอียด',
        },
        customFilters: [{
          name: 'smartFilter',
          callback(row, query) {
            const {
              selectedPublic,
              selectedUser,
              selectedCategory,
              selectedMediaType,
            } = query;
            let statusMatch = true;
            if (selectedPublic !== 'All') {
              statusMatch = row.public === selectedPublic;
            }
            let statusUser = true;
            if (selectedUser !== 'All') {
              statusUser = row.user_id === selectedUser;
            }
            let statusCategory = true;
            if (selectedCategory !== 'All') {
              statusCategory = row.category_id === selectedCategory;
            }
            let statusMedia = true;
            if (selectedMediaType !== 'All') {
              statusMedia = row.type_id === selectedMediaType;
            }
            return statusMatch && statusUser && statusCategory && statusMedia;
          },
        }],
        sortIcon: {
          is: 'fa-sort',
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
        },
      },
    };
  },
};
</script>

<style scoped>
  .titleTable {
    padding-left: 20px;
    font-size: 20px;
    margin-bottom: 0;
    color: #173B6B;
  }

  .custom-select {
    border-radius: 20px;
  }

  .btnForm1Detail {
    float: right;
    margin: 0 20px 20px 0;
  }
</style>
