<template>
  <div>
    <b-button variant="success" @click="exportCsv">
      สร้างรายงาน
    </b-button>
    <br>
    <br>
    <br>
  </div>
</template>

<script>
import reportAPI from '@/api/report';

export default {
  props: ['report'],
  methods: {
    async getViewStat() {
      const report = await reportAPI.getReportViewStat();
      return report;
    },
    async getUsageStat() {
      const report = await reportAPI.getReportUsageStat();
      return report;
    },
    async getSearchStat() {
      const report = await reportAPI.getReportSearchStat();
      return report;
    },
    async exportCsv() {
      this.$emit('loading', true);
      const fileTitle = `${this.report} report`;
      let headers;
      let itemsNotFormatted;
      const itemsFormatted = [];
      if (this.report === 'view') {
        headers = {
          name: 'Name',
          count: 'Count',
        };
        itemsNotFormatted = await this.getViewStat();
        itemsNotFormatted.forEach((item) => {
          itemsFormatted.push({
            name: item.name,
            count: item.count,
          });
        });
      } else if (this.report === 'usage') {
        headers = {
          service: 'Service',
          byte: 'Byte',
        };
        itemsNotFormatted = await this.getUsageStat();
        itemsNotFormatted.forEach((item) => {
          itemsFormatted.push({
            service: item.service,
            byte: item.byte,
          });
        });
      } else {
        headers = {
          search: 'Search',
          count: 'Count',
        };
        itemsNotFormatted = await this.getSearchStat();
        itemsNotFormatted.forEach((item) => {
          itemsFormatted.push({
            search: item.search == null || item.search === '' ? ' ' : item.search,
            count: item.count,
          });
        });
      }

      this.exportCSVFile(headers, itemsFormatted, fileTitle);
    },
    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
      let str = '';

      for (let i = 0; i < array.length; i += 1) {
        let line = '';
        // eslint-disable-next-line
        for (const index in array[i]) {
          if (line !== '') line += ',';
          line += array[i][index];
        }
        str += `${line}\r\n`;
      }

      return str;
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      const jsonObject = JSON.stringify(items);

      const csv = this.convertToCSV(jsonObject);

      const exportedFilename = `${fileTitle}.csv` || 'export.csv';

      const blob = new Blob([`\uFEFF${csv}`], { type: 'text/csv;charset=UTF-8;' });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilename);
        this.$emit('loading', false);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', exportedFilename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        this.$emit('loading', false);
      }
    },
  },
};
</script>
