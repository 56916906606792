/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  latestArticle(params) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/knowledge',
        params,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getAllUser() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/users',
        params: {
          $limit: 500000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getKnowledge() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/knowledge',
        params: {
          $limit: 500000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritage() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage',
        params: {
          $limit: 500000,
          public: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getConceptProduct() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/concept-product',
        params: {
          $limit: 500000,
          public: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getProduct() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/product',
        params: {
          $limit: 500000,
          public: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
