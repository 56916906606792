/* eslint-disable */
<template>
  <div class="sidebar" style="background: white;">
    <SidebarHeader/>
    <SidebarForm/>
    <nav class="sidebar-nav" >
      <div slot="header"></div>
      <ul class="nav border-sidenav">
        <template v-for="(item, index) in navItems">
          <template v-if="item.title">
            <SidebarNavTitle :name="item.name" :key="index"
            :classes="item.class" :wrapper="item.wrapper"/>
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider :classes="item.class" :key="index"/>
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown :name="item.name" :url="item.url" :icon="item.icon" :key="index">
                <template v-for="(childL1, index) in item.children">
                  <template v-if="childL1.children">
                    <!-- Second level dropdown -->
                    <SidebarNavDropdown :name="childL1.name" :key="index"
                    :url="childL1.url" :icon="childL1.icon">
                      <li class="nav-item" v-for="(childL2, index) in childL1.children"
                      :key="index">
                        <SidebarNavLink :name="childL2.name" :url="childL2.url" :icon="childL2.icon"
                        :badge="childL2.badge" :variant="item.variant"/>
                      </li>
                    </SidebarNavDropdown>
                  </template>
                  <template v-else>
                    <SidebarNavItem :classes="item.class" :key="index">
                      <SidebarNavLink :name="childL1.name" :url="childL1.url" :icon="childL1.icon"
                      :badge="childL1.badge" :variant="item.variant"/>
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem :classes="item.class" :key="index">
                <SidebarNavLink :name="item.name" :url="item.url" :icon="item.icon"
                :badge="item.badge" :variant="item.variant"/>
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot></slot>
    </nav>
    <SidebarFooter/>
    <!-- <SidebarMinimizer/> -->
  </div>
</template>
<script>
import SidebarFooter from './SidebarFooter.vue';
import SidebarForm from './SidebarForm.vue';
import SidebarHeader from './SidebarHeader.vue';
// import SidebarMinimizer from './SidebarMinimizer.vue';
import SidebarNavDivider from './SidebarNavDivider.vue';
import SidebarNavDropdown from './SidebarNavDropdown.vue';
import SidebarNavLink from './SidebarNavLink.vue';
import SidebarNavTitle from './SidebarNavTitle.vue';
import SidebarNavItem from './SidebarNavItem.vue';

export default {
  name: 'sidebar',
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    SidebarFooter,
    SidebarForm,
    SidebarHeader,
    // SidebarMinimizer,
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      e.target.parentElement.classList.toggle('open');
    },
  },
};
</script>
<style lang="css" scoped>
 .border-sidenav {
    border-right: 0.5px solid rgb(164, 183, 193) !important;
  }
  .nav-link {
    cursor:pointer;
  }
  .sidebar {
    background-color: white;
    color: #f6a122;
    border-right: 1px solid #c2cfd6;
  }
  .sidebar .sidebar-nav {
    background-color: #231f20;
  }
  .sidebar .nav-item {
    background-color: white;
  }
</style>
