<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label-for="concept_product_name" label-cols-sm="5"
        class="nameHeightConcept" :style="checkNameTh ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อผลิตภัณฑ์ <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="concept_product_name" :value="conceptProductDetail.name"
            :autofocus="createMode" @change="updateData({name: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="แนวคิดจากชุมชน" label-for="concept" label-cols-sm="5">
          <quill-editor v-model="concept" :options="editorOption" class="editorHeight"
          @change="updateData({concept:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ติดต่อประสานงาน" label-for="contact" label-cols-sm="5">
         <quill-editor v-model="contact" :options="editorOption" class="editorHeight"
          @change="updateData({contact:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="แนวความคิด" label-for="detail_concept" label-cols-sm="5">
          <quill-editor v-model="detail_concept" :options="editorOption" class="editorHeight"
          @change="updateData({detail_concept:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การออกแบบ" label-for="detail_design" label-cols-sm="5">
          <quill-editor v-model="detail_design" :options="editorOption" class="editorHeight"
          @change="updateData({detail_design:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})" ></quill-editor>
        </b-form-group>
        <br><br><br>

         <b-form-group label="วัสดุ" label-for="detail_material" label-cols-sm="5">
          <quill-editor v-model="detail_material" :options="editorOption" class="editorHeight"
          @change="updateData({detail_material:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('ConceptProduct', ['conceptProductDetail', 'editorOption', 'createMode', 'checkNameTh']),
    ConceptProductData() {
      return this.conceptProductDetail;
    },
  },
  watch: {
    ConceptProductData() {
      this.concept = this.conceptProductDetail.concept;
      this.contact = this.conceptProductDetail.contact;
      this.detail_concept = this.conceptProductDetail.detail_concept;
      this.detail_design = this.conceptProductDetail.detail_design;
      this.detail_material = this.conceptProductDetail.detail_material;
      console.log(this.editorOption);
    },
  },
  mounted() {
    console.log(this.editorOption);
  },
  data() {
    return {
      concept: '',
      contact: '',
      detail_concept: '',
      detail_design: '',
      detail_material: '',
    };
  },
  methods: {
    ...mapActions('ConceptProduct', ['updateField']),
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedform1Detail', this.conceptProductDetail);
    },
  },
};
</script>

<style>
  .editorHeight {
    height: 200px;
  }
  .nameHeightConcept {
    height: 60px;
  }
</style>
