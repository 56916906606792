<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label-for="product_name" label-cols-sm="5"
        class = "nameLabelProduct" :style="checkNameTh ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อผลิตภัณฑ์ <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="product_name" :value="productDetail.name"
            @change="updateData({name: $event})" :autofocus="createMode">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="แนวคิดจากชุมชน" label-for="product_concept" label-cols-sm="5">
          <quill-editor v-model="concept" :options="editorOption" class="editorHeight"
          @change="updateData({concept:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ติดต่อประสานงาน" label-for="contact" label-cols-sm="5">
          <quill-editor v-model="contact" :options="editorOption" class="editorHeight"
          @change="updateData({contact:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ทรัพย์สินทางปัญญา" label-for="product_ip" label-cols-sm="5">
          <quill-editor v-model="product_ip" :options="editorOption" class="editorHeight"
          @change="updateData({product_ip:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="แนวความคิด" label-for="detail_concept" label-cols-sm="5">
          <quill-editor v-model="detail_concept" :options="editorOption" class="editorHeight"
          @change="updateData({detail_concept:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="รูปแบบการแสดง" label-for="detail_show" label-cols-sm="5">
          <quill-editor v-model="detail_show" :options="editorOption" class="editorHeight"
          @change="updateData({detail_show:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

         <b-form-group label="จำนวนนักแสดง" label-for="detail_number_people" label-cols-sm="5">
          <quill-editor v-model="detail_number_people" :options="editorOption" class="editorHeight"
          @change="updateData({detail_number_people:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ความยาวของการแสดง" label-for="detail_length" label-cols-sm="5">
          <quill-editor v-model="detail_length" :options="editorOption" class="editorHeight"
          @change="updateData({detail_length:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('Product', ['productDetail', 'editorOption', 'createMode', 'checkNameTh']),
    ProductData() {
      return this.productDetail;
    },
  },
  watch: {
    ProductData() {
      this.concept = this.productDetail.concept;
      this.contact = this.productDetail.contact;
      this.product_ip = this.productDetail.product_ip;
      this.detail_concept = this.productDetail.detail_concept;
      this.detail_show = this.productDetail.detail_show;
      this.detail_number_people = this.productDetail.detail_number_people;
      this.detail_length = this.productDetail.detail_length;
    },
  },
  data() {
    return {
      concept: '',
      contact: '',
      product_ip: '',
      detail_concept: '',
      detail_show: '',
      detail_number_people: '',
      detail_length: '',
    };
  },
  methods: {
    ...mapActions('Product', ['updateField']),
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedProductDetail', this.productDetail);
    },
  },
};
</script>

<style>
  .editorHeight {
    height: 200px;
  }

  .nameLabelProduct {
    height: 60px;
  }
</style>
