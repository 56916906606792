import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import {
  ServerTable,
  ClientTable,
} from 'ima8-vue-tables';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueLoaders from 'vue-loaders';
import VueQuillEditor from 'vue-quill-editor';
import Quill from 'quill';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-loaders/dist/vue-loaders.css';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import VideoBlot from './helper/quillvdo';

VideoBlot.blotName = 'simpleVideo';
VideoBlot.tagName = 'video';
Quill.register(VideoBlot);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(ClientTable, {}, true, 'bootstrap3', 'default');
Vue.use(ServerTable);
Vue.use(VueAxios, axios);
Vue.use(VueLoaders);
Vue.use(VueQuillEditor);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
