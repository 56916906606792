<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label-for="heritage_name"
        label-cols-sm="5" class="nameHeigth" :style="checkNameTh ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อมรดกภูมิปัญญาทางวัฒนธรรม <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="heritage_name" :value="heritageDetail.name"
            @change="updateData({name: $event})" :autofocus="createMode">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="ชื่อเรียกในท้องถิ่น" label-for="heritage_name_local" label-cols-sm="5"
        class="nameHeigth">
          <b-input-group>
            <b-form-input type="text" name="heritage_name_local"
            :value="heritageDetail.name_local"
              @change="updateData({name_local: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="ตัวแทนผู้สืบทอด" label-for="inheritor" label-cols-sm="5">
          <quill-editor v-model="inheritor" :options="editorOption" class="editorHeight"
          @change="updateData({inheritor:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ข้อมูลทางภูมิศาสตร์" label-for="geography" label-cols-sm="5">
          <quill-editor v-model="geography" :options="editorOption" class="editorHeight"
          @change="updateData({geography:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ติดต่อประสานงาน" label-for="contact" label-cols-sm="5">
          <quill-editor v-model="contact" :options="editorOption" class="editorHeight"
          @change="updateData({contact:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="สาระสำคัญของมรดกภูมิปัญญาทางวัฒนธรรม" label-for="descriptor_detail"
        label-cols-sm="5">
          <quill-editor v-model="descriptor_detail" :options="editorOption" class="editorHeight"
          @change="updateData({descriptor_detail:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การสนับสนุนเพื่อรับรองมรดกภูมิปัญญาทางวัฒนธรรมจะได้รับรู้
        ตระหนักและกล่าวถึงอย่างต่อเนื่อง"
        label-for="support_detail" label-cols-sm="5">
          <quill-editor v-model="support_detail" :options="editorOption" class="editorHeight"
          @change="updateData({support_detail:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การขึ้นทะเบียนมรดกภูมิปัญญาทางวัฒนธรรม" label-for="support_register"
        label-cols-sm="5">
          <quill-editor v-model="support_register" :options="editorOption" class="editorHeight"
          @change="updateData({support_register:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

         <b-form-group label="มาตรการในการป้องกันมรดกภูมิปัญญาทางวัฒนธรรม"
         label-for="protection_detail" label-cols-sm="5">
          <quill-editor v-model="protection_detail" :options="editorOption" class="editorHeight"
          @change="updateData({protection_detail:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ความต้องการมาตรการในการปกป้อง" label-for="protection_need"
        label-cols-sm="5">
          <quill-editor v-model="protection_need" :options="editorOption" class="editorHeight"
          @change="updateData({protection_need:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ชุมชน กลุ่มคน/บุคคล" label-for="protection_people" label-cols-sm="5">
          <quill-editor v-model="protection_people" :options="editorOption" class="editorHeight"
          @change="updateData({protection_people:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ภาครัฐ หน่วยงานภาคสังคม" label-for="protection_government"
        label-cols-sm="5">
          <quill-editor v-model="protection_government" :options="editorOption" class="editorHeight"
          @change="updateData({protection_government:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ที่มีส่วนเกี่ยวข้อง" label-for="protection_other" label-cols-sm="5">
          <quill-editor v-model="protection_other" :options="editorOption" class="editorHeight"
          @change="updateData({protection_other:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การมีส่วนร่วมของชุมชนที่มีต่อมรดกภูมิปัญญาทางวัฒนธรรม"
        label-for="participation_heritage" label-cols-sm="5">
          <quill-editor v-model="participation_heritage" :options="editorOption"
          class="editorHeight"
          @change="updateData({participation_heritage:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="หน่วยงานในระดับชุมชน" label-for="participation_contact"
        label-cols-sm="5">
          <quill-editor v-model="participation_contact" :options="editorOption" class="editorHeight"
          @change="updateData({participation_contact:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="มาตรการในการส่งเสริมและการรักษามรดกภูมิปัญญาทางวัฒนธรรม"
        label-for="treatment_detail" label-cols-sm="5">
          <quill-editor v-model="treatment_detail" :options="editorOption" class="editorHeight"
          @change="updateData({treatment_detail:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การสืบสานและถ่ายทอด (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา
        และงบประมาณ)"
        label-for="treatment_relay" label-cols-sm="5">
          <quill-editor v-model="treatment_relay" :options="editorOption" class="editorHeight"
          @change="updateData({treatment_relay:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="หมายเหตุ" label-for="note" label-cols-sm="5">
          <quill-editor v-model="note" :options="editorOption" class="editorHeight"
          @change="updateData({note:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ไฟล์ .pdf" label-for="file_pdf" label-cols-sm="5"
        class="uploadLabel">
          <b-button variant="warning" @click="openFile(heritageDetail.file_pdf)"
          v-if="heritageDetail.file_pdf" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadPdf" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadPdf" type="file" ref="pdfFile" accept=".pdf"
          @change="uploadPdfFile()"/>
        </b-form-group>

        <b-form-group label="ไฟล์ .docx" label-for="file_word" label-cols-sm="5">
          <b-button variant="warning" @click="openFile(heritageDetail.file_word)"
          v-if="heritageDetail.file_word" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadWord" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadWord" type="file" ref="wordFile" accept=".docx, .doc"
          @change="uploadWordFile()"/>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Heritage', ['heritageDetail', 'editorOption', 'createMode', 'checkNameTh']),
    HeritageData() {
      return this.heritageDetail;
    },
  },
  watch: {
    HeritageData() {
      this.inheritor = this.heritageDetail.inheritor;
      this.geography = this.heritageDetail.geography;
      this.contact = this.heritageDetail.contact;
      this.descriptor_detail = this.heritageDetail.descriptor_detail;
      this.support_detail = this.heritageDetail.support_detail;
      this.support_register = this.heritageDetail.support_register;
      this.protection_detail = this.heritageDetail.protection_detail;
      this.protection_need = this.heritageDetail.protection_need;
      this.protection_people = this.heritageDetail.protection_people;
      this.protection_government = this.heritageDetail.protection_government;
      this.protection_other = this.heritageDetail.protection_other;
      this.participation_heritage = this.heritageDetail.participation_heritage;
      this.participation_contact = this.heritageDetail.participation_contact;
      this.treatment_detail = this.heritageDetail.treatment_detail;
      this.treatment_relay = this.heritageDetail.treatment_relay;
      this.note = this.heritageDetail.note;
    },
  },
  data() {
    return {
      inheritor: '',
      geography: '',
      contact: '',
      descriptor_detail: '',
      support_detail: '',
      support_register: '',
      protection_detail: '',
      protection_need: '',
      protection_people: '',
      protection_government: '',
      protection_other: '',
      participation_heritage: '',
      participation_contact: '',
      treatment_detail: '',
      treatment_relay: '',
      note: '',
      pdf: null,
      word: null,
    };
  },
  methods: {
    ...mapActions('Heritage', ['updateField', 'setLoading']),
    openFile(data) {
      window.open(data, '_blank');
    },
    async uploadPdfFile() {
      this.setLoading(true);
      if (this.$refs.pdfFile.files[0]) {
        const file = this.$refs.pdfFile.files[0];
        const { name, type: contentType, size } = file;
        console.log('File', name, contentType, size);
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          console.log(uploadRes.data.url);
          this.updateData({ file_pdf: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadWordFile() {
      this.setLoading(true);
      if (this.$refs.wordFile.files[0]) {
        const file = this.$refs.wordFile.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_word: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    updateData(data) {
      this.updateField(data);
    },
  },
};
</script>

<style scope>
  .editorHeight {
    height: 200px;
  }
  input[type="file"] {
    display: none;
  }
  .labelBtnOpenFile {
    margin-right: 20px;
  }
  .labelBtnUploadFile {
    color: #FFF200!important;
    background-color: #532ce0!important;
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #cccccc;
    color: #666666;
  }
  .nameHeigth {
    height: 65px;
  }
  .uploadLabel {
    height: 60px;
  }
</style>
