/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import heritageAPI from '@/api/heritage';
import _ from 'lodash';

const state = {
  tableData: [],
  listHeritage: [],
  selectedPublic: 'All',
  listPublicType: ['All', 'แบบร่าง', 'เผยแพร่'],
  showLoading: false,
  heritageDetail: {},
  listArea: [],
  listCategory: [],
  listAreaHeritageCategory: [],
  createMode: false,
  selectedCategory: 'All',
  listCategoryFilter: [],
  checkNameTh: false,
  checkNameEng: false,
  editorOption: {
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', { align: ['', 'center', 'right'] }, 'blockquote', 'link',
            { list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    },
  },
};

const getters = {
  listHeritage: (state) => state.listHeritage,
  selectedPublic: (state) => state.selectedPublic,
  listPublicType: (state) => state.listPublicType,
  showLoading: (state) => state.showLoading,
  heritageDetail: (state) => state.heritageDetail,
  listArea: (state) => state.listArea,
  listCategory: (state) => state.listCategory,
  listAreaHeritageCategory: (state) => state.listAreaHeritageCategory,
  createMode: (state) => state.createMode,
  displayProfile: (state) => state.displayProfile,
  displayBanner: (state) => state.displayBanner,
  editorOption: (state) => state.editorOption,
  selectedCategory: (state) => state.selectedCategory,
  listCategoryFilter: (state) => state.listCategoryFilter,
  checkNameTh: (state) => state.checkNameTh,
  checkNameEng: (state) => state.checkNameEng,
};

const actions = {
  async getData({
    commit,
  }) {
    commit('SET_SHOW_LOADING', true);
    commit('SET_LIST_HERITAGE', []);
    const listHeritage = [];

    const allHeritage = await heritageAPI.getHeritage();
    const allArea = await heritageAPI.getArea();
    const allCategory = await heritageAPI.getHeritageCategory();
    const allAreaHeritageCategory = await heritageAPI.getAreaHerirageCategory();

    const heritages = allHeritage.data;
    const areas = allArea.data;
    const categories = allCategory.data;
    const areaHeritageCategories = allAreaHeritageCategory.data;

    for (let i = 0; i < heritages.length; i += 1) {
      let area = '-';
      for (let j = 0; j < areas.length; j += 1) {
        if (heritages[i].area_id === areas[j].id) {
          area = areas[j].name;
          break;
        }
      }

      let category = '-';
      for (let j = 0; j < categories.length; j += 1) {
        if (heritages[i].category_id === categories[j].id) {
          category = categories[j].name;
          break;
        }
      }

      let areaHeritageCategory = '-';
      for (let j = 0; j < areaHeritageCategories.length; j += 1) {
        if (heritages[i].area_heritage_category_id === areaHeritageCategories[j].id) {
          areaHeritageCategory = areaHeritageCategories[j].name;
          break;
        }
      }

      let publicText;
      if (heritages[i].public === 1) {
        publicText = 'เผยแพร่';
      } else {
        publicText = 'แบบร่าง';
      }

      const combineHeritage = {
        name: heritages[i].name,
        category_id: category,
        area_id: area,
        area_heritage_category_id: areaHeritageCategory,
        public: publicText,
        view: heritages[i].view,
        create_at: heritages[i].create_at,
        update_at: heritages[i].update_at,
        id: heritages[i].id,
      };

      listHeritage.push(combineHeritage);
    }

    const listCategoryFilter = [];
    listCategoryFilter.push('All');
    for (let i = 0; i < categories.length; i += 1) {
      listCategoryFilter.push(categories[i].name);
    }

    commit('SET_LIST_CATEGORY_FILTER', listCategoryFilter);
    await commit('SET_LIST_HERITAGE', listHeritage);
    await commit('SET_DATA', listHeritage);
    await commit('UPDATE_TABLE', listHeritage);
    commit('SET_SHOW_LOADING', false);
  },
  async getHeritageDetail({
    commit,
    dispatch,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    let heritageDetail;
    if (id === 'create') {
      heritageDetail = {
        name: '',
        name_eng: '',
        profile_img: null,
        banner_img: null,
        file_pdf: null,
        file_word: null,
        file_pdf_eng: null,
        file_word_eng: null,
        area_id: 1,
        category_id: 1,
        area_heritage_category_id: 1,
        public: 0,
        heritageVideo: [],
      };
      commit('SET_CREATE_MODE', true);
    } else {
      heritageDetail = await heritageAPI.getHeritageById(id);
      const heritageVdo = await heritageAPI.getHeritageVdo(id);
      const allheritageSub = await heritageAPI.getHeritageSub(id);

      const vdoTh = [];
      const vdoEng = [];
      const subTh = [];
      const subEng = [];
      for (let i = 0; i < heritageVdo.data.length; i += 1) {
        if (heritageVdo.data[i].name) {
          vdoTh.push(heritageVdo.data[i]);
        } else {
          vdoEng.push(heritageVdo.data[i]);
        }
      }

      for (let i = 0; i < allheritageSub.data.length; i += 1) {
        if (allheritageSub.data[i].name) {
          subTh.push(allheritageSub.data[i]);
        } else {
          subEng.push(allheritageSub.data[i]);
        }
      }

      _.extend(heritageDetail, { heritageVdo: vdoTh },
        { heritageVdoEng: vdoEng });

      const heritageSub = subTh;
      let subTitle1;
      let subTitle2;

      switch (subTh.length) {
        case 2:
          if (heritageSub[0].heritage_media_id === vdoTh[0].id) {
            // sub 1 == video 1
            subTitle1 = heritageSub[0]; // eslint-disable-line
            subTitle2 = heritageSub[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = heritageSub[1]; // eslint-disable-line
            subTitle2 = heritageSub[0]; // eslint-disable-line
          }
          _.extend(heritageDetail, { sub1: subTitle1 }, { sub2: subTitle2 });
          break;
        case 1:
          if (vdoTh.length > 1) {
            if (heritageSub[0].heritage_media_id === vdoTh[0].id) {
              // sub 1 == video 1
              subTitle1 = heritageSub[0]; // eslint-disable-line
              _.extend(heritageDetail, { sub1: subTitle1 });
            } else {
              // sub 2 == vdo 1
              subTitle2 = heritageSub[0]; // eslint-disable-line
              _.extend(heritageDetail, { sub2: subTitle2 });
            }
          } else {
            subTitle1 = heritageSub[0]; // eslint-disable-line
            _.extend(heritageDetail, { sub1: subTitle1 });
          }
          break;
        default:
          break;
      }

      console.log('heritage', subEng);
      const heritageSubEng = subEng;
      let subTitleEng1;
      let subTitleEng2;

      switch (subEng.length) {
        case 2:
          if (heritageSubEng[0].heritage_media_id === vdoEng[0].id) {
            // sub 1 == video 1
            subTitleEng1 = heritageSubEng[0]; // eslint-disable-line
            subTitleEng2 = heritageSubEng[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitleEng1 = heritageSubEng[1]; // eslint-disable-line
            subTitleEng2 = heritageSubEng[0]; // eslint-disable-line
          }
          _.extend(heritageDetail, { subEng1: subTitleEng1 }, { subEng2: subTitleEng2 });
          break;
        case 1:
          if (vdoEng.length > 1) {
            if (heritageSubEng[0].heritage_media_id === vdoEng[0].id) {
              // sub 1 == video 1
              subTitleEng1 = heritageSubEng[0]; // eslint-disable-line
              _.extend(heritageDetail, { subEng1: subTitleEng1 });
            } else {
              // sub 2 == vdo 1
              subTitleEng2 = heritageSubEng[0]; // eslint-disable-line
              _.extend(heritageDetail, { subEng2: subTitleEng2 });
            }
          } else {
            subTitleEng1 = heritageSubEng[0]; // eslint-disable-line
            _.extend(heritageDetail, { subEng1: subTitleEng1 });
          }
          break;
        default:
          break;
      }

      const allHeritagePicture = await heritageAPI.getHeritagePicture(id);
      _.extend(heritageDetail, { heritageMultiFilesOld: allHeritagePicture.data });

      commit('SET_CREATE_MODE', false);
    }
    commit('SET_HERITAGE_DETAIL', heritageDetail);
    await dispatch('getOptions');
    commit('SET_SHOW_LOADING', false);
  },
  async getOptions({
    commit,
  }) {
    const allArea = await heritageAPI.getArea();
    const allCategory = await heritageAPI.getHeritageCategory();
    const allAreaHeritageCategory = await heritageAPI.getAreaHerirageCategory();

    await commit('SET_LIST_AREA', allArea.data);
    await commit('SET_LIST_CATEGORY', allCategory.data);
    await commit('SET_LIST_AREA_HERITAGE_CATEGORY', allAreaHeritageCategory.data);
  },
  async updateHeritageDetail({
    commit,
    state,
  }) {
    commit('SET_SHOW_LOADING', true);
    const updateDetail = state.heritageDetail;
    const timeNow = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const updateHeritage = {
      name: updateDetail.name,
      name_local: updateDetail.name_local,
      area_id: updateDetail.area_id,
      inheritor: updateDetail.inheritor,
      geography: updateDetail.geography,
      contact: updateDetail.contact,
      category_id: updateDetail.category_id,
      descriptor_detail: updateDetail.descriptor_detail,
      support_detail: updateDetail.support_detail,
      support_register: updateDetail.support_register,
      protection_detail: updateDetail.protection_detail,
      protection_need: updateDetail.protection_need,
      protection_people: updateDetail.protection_people,
      protection_government: updateDetail.protection_government,
      protection_other: updateDetail.protection_other,
      participation_heritage: updateDetail.participation_heritage,
      participation_contact: updateDetail.participation_contact,
      treatment_detail: updateDetail.treatment_detail,
      treatment_relay: updateDetail.treatment_relay,
      note: updateDetail.note,
      file_pdf: updateDetail.file_pdf,
      file_word: updateDetail.file_word,
      profile_img: updateDetail.profile_img,
      banner_img: updateDetail.banner_img,
      lat: updateDetail.lat,
      lng: updateDetail.lng,
      area_heritage_category_id: updateDetail.area_heritage_category_id,
      public: updateDetail.public,
      keyword: updateDetail.keyword,
      name_eng: updateDetail.name_eng,
      name_local_eng: updateDetail.name_local_eng,
      inheritor_eng: updateDetail.inheritor_eng,
      geography_eng: updateDetail.geography_eng,
      contact_eng: updateDetail.contact_eng,
      descriptor_detail_eng: updateDetail.descriptor_detail_eng,
      support_detail_eng: updateDetail.support_detail_eng,
      support_register_eng: updateDetail.support_register_eng,
      protection_detail_eng: updateDetail.protection_detail_eng,
      protection_need_eng: updateDetail.protection_need_eng,
      protection_people_eng: updateDetail.protection_people_eng,
      protection_government_eng: updateDetail.protection_government_eng,
      protection_other_eng: updateDetail.protection_other_eng,
      participation_heritage_eng: updateDetail.participation_heritage_eng,
      participation_contact_eng: updateDetail.participation_contact_eng,
      treatment_detail_eng: updateDetail.treatment_detail_eng,
      treatment_relay_eng: updateDetail.treatment_relay_eng,
      note_eng: updateDetail.note_eng,
      file_pdf_eng: updateDetail.file_pdf_eng,
      file_word_eng: updateDetail.file_word_eng,
      update_at: timeNow,
    };

    let updateRes;
    if (state.createMode) {
      _.extend(updateHeritage, { create_at: timeNow, view: 0 });
      updateRes = await heritageAPI.createHeritage(updateHeritage);

      if (updateDetail.vdo1) {
        const updateVdo1 = {
          heritage_id: updateRes.id,
          name: updateDetail.vdo1.name,
          file_path: updateDetail.vdo1.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          heritage_media_id: null,
          name_eng: null,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdo1 = await heritageAPI.createHeritageMedia(updateVdo1);

        if (updateDetail.sub1) {
          // create sub 1
          const updateSub1 = {
            heritage_id: updateRes.id,
            name: updateDetail.sub1.name,
            file_path: updateDetail.sub1.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            heritage_media_id: vdo1.id,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await heritageAPI.createHeritageMedia(updateSub1);
        }
      }

      if (updateDetail.vdo2) {
        const updateVdo2 = {
          heritage_id: updateRes.id,
          name: updateDetail.vdo2.name,
          file_path: updateDetail.vdo2.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          heritage_media_id: null,
          name_eng: null,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdo2 = await heritageAPI.createHeritageMedia(updateVdo2);

        if (updateDetail.sub2) {
          // create sub 2
          const updateSub2 = {
            heritage_id: updateRes.id,
            name: updateDetail.sub2.name,
            file_path: updateDetail.sub2.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            heritage_media_id: vdo2.id,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await heritageAPI.createHeritageMedia(updateSub2);
        }
      }

      if (updateDetail.vdoEng1) {
        const updateVdoEng1 = {
          heritage_id: updateRes.id,
          name: null,
          file_path: updateDetail.vdoEng1.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          heritage_media_id: null,
          name_eng: updateDetail.vdoEng1.name_eng,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdoEng1 = await heritageAPI.createHeritageMedia(updateVdoEng1);

        if (updateDetail.subEng1) {
          // create sub 1
          const updateSubEng1 = {
            heritage_id: updateRes.id,
            name: null,
            file_path: updateDetail.subEng1.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            heritage_media_id: vdoEng1.id,
            name_eng: updateDetail.subEng1.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await heritageAPI.createHeritageMedia(updateSubEng1);
        }
      }

      if (updateDetail.vdoEng2) {
        const updateVdoEng2 = {
          heritage_id: updateRes.id,
          name: null,
          file_path: updateDetail.vdoEng2.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          heritage_media_id: null,
          name_eng: updateDetail.vdoEng2.name_eng,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdoEng2 = await heritageAPI.createHeritageMedia(updateVdoEng2);

        if (updateDetail.subEng2) {
          // create sub 2
          const updateSubEng2 = {
            heritage_id: updateRes.id,
            name: null,
            file_path: updateDetail.subEng2.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            heritage_media_id: vdoEng2.id,
            name_eng: updateDetail.subEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await heritageAPI.createHeritageMedia(updateSubEng2);
        }
      }

      if (updateDetail.heritageMultiFiles) {
        const newMultiFile = [];
        for (let i = 0; i < updateDetail.heritageMultiFiles.length; i += 1) {
          const multiFile = {
            heritage_id: updateRes.id,
            name: updateDetail.heritageMultiFiles[i].key,
            file_path: updateDetail.heritageMultiFiles[i].location,
            is_picture: true,
            is_vdo: false,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: updateDetail.heritageMultiFiles[i].key,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          newMultiFile.push(multiFile);
        }

        const promises = newMultiFile.map((val) => heritageAPI.createHeritageMedia(val)
          .then((results) => {
            console.log(results);
            return val;
          }));
        Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
    } else {
      const idNum = state.heritageDetail.id;
      updateRes = await heritageAPI.updateHeritage(idNum, updateHeritage);

      console.log('start vdo');
      const allHeritageVdo = await heritageAPI.getHeritageVdo(idNum);
      const allheritageSub = await heritageAPI.getHeritageSub(idNum);
      const heritageVdo = allHeritageVdo.data;
      const heritageSub = allheritageSub.data;

      const vdoTh = [];
      const vdoEng = [];
      const subTh = [];
      const subEng = [];
      for (let i = 0; i < heritageVdo.length; i += 1) {
        if (heritageVdo[i].name) {
          vdoTh.push(heritageVdo[i]);
        } else {
          vdoEng.push(heritageVdo[i]);
        }
      }

      for (let i = 0; i < heritageSub.length; i += 1) {
        if (heritageSub[i].name) {
          subTh.push(heritageSub[i]);
        } else {
          subEng.push(heritageSub[i]);
        }
      }

      // create vdo sub th
      if (vdoTh.length === 2) {
        // Heritage has 2 vdo
        console.log('2 vdo');

        if (updateDetail.vdo1) {
          // update vdo 1
          const updateVdo1 = {
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
          };
          await heritageAPI.patchHeritageMedia(vdoTh[0].id, updateVdo1);
        }

        if (updateDetail.vdo2) {
          // update vdo 2
          const updateVdo2 = {
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
          };
          await heritageAPI.patchHeritageMedia(vdoTh[1].id, updateVdo2);
        }

        let subTitle1;
        let subTitle2;
        if (subTh.length === 2) {
          // have 2 subtitle
          if (subTh[0].heritage_media_id === vdoTh[0].id) {
            // sub 1 == video 1
            subTitle1 = subTh[0]; // eslint-disable-line
            subTitle2 = subTh[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = subTh[1]; // eslint-disable-line
            subTitle2 = subTh[0]; // eslint-disable-line
          }

          if (updateDetail.sub1) {
            // update sub 1
            const updateSub1 = {
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
            };
            await heritageAPI.patchHeritageMedia(subTitle1.id, updateSub1);
          }

          if (updateDetail.sub2) {
            // update sub 2
            const updateSub2 = {
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
            };
            await heritageAPI.patchHeritageMedia(subTitle2.id, updateSub2);
          }
        }
        if (subTh.length === 1) {
          // heritage has 1 subtitle
          console.log('1 sub');
          if (subTh[0].heritage_media_id === vdoTh[0].id) {
            // has subtitle in vdo 1
            subTitle1 = subTh[0]; // eslint-disable-line
            if (updateDetail.sub1) {
              console.log('up sub 1');

              // update subtitle 1
              const updateSub1 = {
                name: updateDetail.sub1.name,
                file_path: updateDetail.sub1.file_path,
              };
              await heritageAPI.patchHeritageMedia(subTitle1.id, updateSub1);
            }

            if (updateDetail.sub2) {
              // create subtitle 2
              console.log('create sub 2');

              const createSub2 = {
                heritage_id: idNum,
                name: updateDetail.sub2.name,
                file_path: updateDetail.sub2.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                heritage_media_id: vdoTh[1].id,
                name_eng: null,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await heritageAPI.createHeritageMedia(createSub2);
            }
          } else {
            // has subtitle in vdo 2
            subTitle2 = subTh[0]; // eslint-disable-line

            if (updateDetail.sub2) {
              // update subtitle 2
              console.log('up sub 2');

              const updateSub2 = {
                name: updateDetail.sub2.name,
                file_path: updateDetail.sub2.file_path,
              };
              await heritageAPI.patchHeritageMedia(subTitle2.id, updateSub2);
            }

            if (updateDetail.sub1) {
              // create subtitle 1
              console.log('create sub 1');

              const createSub1 = {
                heritage_id: idNum,
                name: updateDetail.sub1.name,
                file_path: updateDetail.sub1.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                heritage_media_id: vdoTh[0].id,
                name_eng: null,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await heritageAPI.createHeritageMedia(createSub1);
            }
          }
        } else if (subTh.length === 0) {
          // heritage has no subtitle
          if (updateDetail.sub1) {
            // create subtitle 1
            const createSub1 = {
              heritage_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdoTh[0].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(createSub1);
          }

          if (updateDetail.sub2) {
            // create subtitle 2
            const createSub2 = {
              heritage_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdoTh[1].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(createSub2);
          }
        }
      } else if (vdoTh.length === 1) {
        // heritage has 1 video
        if (updateDetail.vdo1) {
          // update vdo 1
          const updateVdo1 = {
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
          };
          await heritageAPI.patchHeritageMedia(vdoTh[0].id, updateVdo1);
        }

        let subTitle1;
        if (subTh.length === 1) {
          // heritage has 1 subtitle
          subTitle1 = subTh[0] // eslint-disable-line
          if (updateDetail.sub1) {
            // update subtitle 1
            const updateSub1 = {
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
            };
            await heritageAPI.patchHeritageMedia(subTitle1.id, updateSub1);
          }
        } else {
          if (updateDetail.sub1) { // eslint-disable-line
            const updateSub1 = {
              heritage_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdoTh[0].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub1);
          }
        }

        if (updateDetail.vdo2) {
          // crate vdo 2
          const updateVdo2 = {
            heritage_id: idNum,
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await heritageAPI.createHeritageMedia(updateVdo2);

          if (updateDetail.sub2) {
            // create sub 2
            const updateSub2 = {
              heritage_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdo2.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub2);
          }
        }
      } else {
        // heritage has no vdo
        if (updateDetail.vdo1) {
          // create vdo 1
          const updateVdo1 = {
            heritage_id: idNum,
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo1 = await heritageAPI.createHeritageMedia(updateVdo1);

          if (updateDetail.sub1) {
            // create sub 2
            const updateSub1 = {
              heritage_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdo1.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub1);
          }
        }

        if (updateDetail.vdo2) {
          // create vdo 2
          const updateVdo2 = {
            heritage_id: idNum,
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await heritageAPI.createHeritageMedia(updateVdo2);

          if (updateDetail.sub2) {
            // create sub 2
            const updateSub2 = {
              heritage_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdo2.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub2);
          }
        }
      }

      // create vdo sub eng
      if (vdoEng.length === 2) {
        // Heritage has 2 vdo
        if (updateDetail.vdoEng1) {
          // update vdo 1
          const updateVdo1 = {
            name_eng: updateDetail.vdoEng1.name_eng,
            file_path: updateDetail.vdoEng1.file_path,
          };
          await heritageAPI.patchHeritageMedia(vdoEng[0].id, updateVdo1);
        }

        if (updateDetail.vdoEng2) {
          // update vdo 2
          const updateVdo2 = {
            name_eng: updateDetail.vdoEng2.name_eng,
            file_path: updateDetail.vdoEng2.file_path,
          };
          await heritageAPI.patchHeritageMedia(vdoEng[1].id, updateVdo2);
        }

        let subTitle1;
        let subTitle2;
        if (subEng.length === 2) {
          // have 2 subtitle
          if (subEng[0].heritage_media_id === vdoEng[0].id) {
            // sub 1 == video 1
            subTitle1 = subEng[0]; // eslint-disable-line
            subTitle2 = subEng[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = subEng[1]; // eslint-disable-line
            subTitle2 = subEng[0]; // eslint-disable-line
          }

          if (updateDetail.subEng1) {
            // update sub 1
            const updateSub1 = {
              name_eng: updateDetail.subEng1.name_eng,
              file_path: updateDetail.subEng1.file_path,
            };
            await heritageAPI.patchHeritageMedia(subTitle1.id, updateSub1);
          }

          if (updateDetail.subEng2) {
            // update sub 2
            const updateSub2 = {
              name_eng: updateDetail.subEng2.name_eng,
              file_path: updateDetail.subEng2.file_path,
            };
            await heritageAPI.patchHeritageMedia(subTitle2.id, updateSub2);
          }
        }
        if (subEng.length === 1) {
          // heritage has 1 subtitle
          if (subEng[0].heritage_media_id === vdoEng[0].id) {
            // has subtitle in vdo 1
            subTitle1 = subEng[0]; // eslint-disable-line

            if (updateDetail.subEng1) {
              // update subtitle 1
              const updateSub1 = {
                name_eng: updateDetail.subEng1.name_eng,
                file_path: updateDetail.subEng1.file_path,
              };
              await heritageAPI.patchHeritageMedia(subTitle1.id, updateSub1);
            }

            if (updateDetail.subEng2) {
              // create subtitle 2
              const createSub2 = {
                heritage_id: idNum,
                name: null,
                file_path: updateDetail.subEng2.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                heritage_media_id: vdoEng[1].id,
                name_eng: updateDetail.subEng2.name_eng,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await heritageAPI.createHeritageMedia(createSub2);
            }
          } else {
            // has subtitle in vdo 2
            subTitle2 = subEng[0]; // eslint-disable-line

            if (updateDetail.subEng2) {
              // update subtitle 2
              const updateSub2 = {
                name_eng: updateDetail.subEng2.name_eng,
                file_path: updateDetail.subEng2.file_path,
              };
              await heritageAPI.patchHeritageMedia(subTitle2.id, updateSub2);
            }

            if (updateDetail.subEng1) {
              // create subtitle 1
              const createSub1 = {
                heritage_id: idNum,
                name: null,
                file_path: updateDetail.subEng1.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                heritage_media_id: vdoEng[0].id,
                name_eng: updateDetail.subEng1.name_eng,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await heritageAPI.createHeritageMedia(createSub1);
            }
          }
        } else if (subEng.length === 0) {
          // heritage has no subtitle
          if (updateDetail.subEng1) {
            // create subtitle 1
            const createSub1 = {
              heritage_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdoEng[0].id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(createSub1);
          }

          if (updateDetail.subEng2) {
            // create subtitle 2
            const createSub2 = {
              heritage_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdoEng[1].id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(createSub2);
          }
        }
      } else if (vdoEng.length === 1) {
        console.log('updateDetail Eng vdo 1', updateDetail.subEng1);
        // heritage has 1 video
        if (updateDetail.vdoEng1) {
          console.log('updateDetail Eng if 1', updateDetail.subEng1);
          // update vdo 1
          const updateVdo1 = {
            name_eng: updateDetail.vdoEng1.name_eng,
            file_path: updateDetail.vdoEng1.file_path,
          };
          await heritageAPI.patchHeritageMedia(vdoEng[0].id, updateVdo1);
        }

        let subTitle1;
        if (subEng.length === 1) {
          // heritage has 1 subtitle
          subTitle1 = subEng[0] // eslint-disable-line
          if (updateDetail.subEng1) {
            console.log('updateDetail Eng else  1', updateDetail.subEng1);
            // update subtitle 1
            const updateSub1 = {
              name_eng: updateDetail.subEng1.name_eng,
              file_path: updateDetail.subEng1.file_path,
            };
            await heritageAPI.patchHeritageMedia(subTitle1.id, updateSub1);
          }
        } else {
          console.log('updateDetail Eng 1', updateDetail.subEng1);

          if (updateDetail.subEng1) { // eslint-disable-line
            const updateSub1 = {
              heritage_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdoEng[0].id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub1);
          }
        }

        if (updateDetail.vdoEng2) {
          // crate vdo 2

          console.log('create vdo 2');
          const updateVdo2 = {
            heritage_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: updateDetail.vdoEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await heritageAPI.createHeritageMedia(updateVdo2);

          if (updateDetail.subEng2) {
            // create sub 2
            console.log('create sub 2');

            const updateSub2 = {
              heritage_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdo2.id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub2);
          }
        }
      } else {
        // heritage has no vdo
        if (updateDetail.vdoEng1) {
          // create vdo 1
          const updateVdo1 = {
            heritage_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng1.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: updateDetail.vdoEng1.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo1 = await heritageAPI.createHeritageMedia(updateVdo1);

          if (updateDetail.subEng1) {
            // create sub 2
            const updateSub1 = {
              heritage_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdo1.id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub1);
          }
        }

        if (updateDetail.vdoEng2) {
          // create vdo 2
          const updateVdo2 = {
            heritage_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: updateDetail.vdoEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await heritageAPI.createHeritageMedia(updateVdo2);

          if (updateDetail.subEng2) {
            // create sub 2
            const updateSub2 = {
              heritage_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              heritage_media_id: vdo2.id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await heritageAPI.createHeritageMedia(updateSub2);
          }
        }
      }

      if (updateDetail.heritageMultiFiles) {
        const newMultiFile = [];
        for (let i = 0; i < updateDetail.heritageMultiFiles.length; i += 1) {
          const multiFile = {
            heritage_id: updateRes.id,
            name: updateDetail.heritageMultiFiles[i].key,
            file_path: updateDetail.heritageMultiFiles[i].location,
            is_picture: true,
            is_vdo: false,
            is_subtitle: false,
            heritage_media_id: null,
            name_eng: updateDetail.heritageMultiFiles[i].key,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          newMultiFile.push(multiFile);
        }

        const promises = newMultiFile.map((val) => heritageAPI.createHeritageMedia(val)
          .then((results) => {
            console.log(results);
            return val;
          }));
        Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }

      if (updateDetail.deleteImageFiles) {
        const promises = updateDetail.deleteImageFiles.map((val) => heritageAPI.deleteHeritageMedia(val) // eslint-disable-line
          .then((results) => {
            console.log(results);
            return results;
          }));
        await Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
    }

    if (updateRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  async deleteHeritage({
    commit,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    const delRes = await heritageAPI.deleteHeritage(id);
    if (delRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  updateField({
    commit,
  }, data) {
    Object.keys(data).forEach((key) => {
      commit('UPDATE_FIELD', {
        [key]: data[key],
      });
    });
  },
  setLoading({
    commit,
  }, data) {
    commit('SET_SHOW_LOADING', data);
  },
  clearState({
    commit,
  }) {
    commit('CLEAR_STATE');
  },
  setSelectedPublic({
    commit,
  }, data) {
    commit('SET_SELECTED_PUBLIC', data);
  },
  setSelectedCategory({
    commit,
  }, data) {
    commit('SET_SELECTED_CATEGORY', data);
  },
  filterCustom({
    commit,
  }, payload) {
    commit('SET_CUSTOM_FILTER', payload);
  },
  setCheckNameTh({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_TH', data);
  },
  setCheckNameEng({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_ENG', data);
  },
};

const mutations = {
  SET_DATA(state, data) {
    state.tableData = data;
  },
  UPDATE_TABLE(state, data) {
    state.data = data;
  },
  SET_LIST_HERITAGE(state, data) {
    state.listHeritage = data;
  },
  SET_SELECTED_PUBLIC(state, data) {
    state.selectedPublic = data;
  },
  SET_SHOW_LOADING(state, data) {
    state.showLoading = data;
  },
  CLEAR_STATE(state) {
    state.showLoading = false;
    state.selectedPublic = 'All';
    state.selectedCategory = 'All';
    state.heritageDetail = {};
    state.createMode = false;
    state.checkNameTh = false;
    state.checkNameEng = false;
  },
  SET_HERITAGE_DETAIL(state, data) {
    state.heritageDetail = data;
  },
  SET_LIST_AREA(state, data) {
    state.listArea = data;
  },
  SET_LIST_CATEGORY(state, data) {
    state.listCategory = data;
  },
  SET_LIST_AREA_HERITAGE_CATEGORY(state, data) {
    state.listAreaHeritageCategory = data;
  },
  SET_CREATE_MODE(state, data) {
    state.createMode = data;
  },
  SET_SELECTED_CATEGORY(state, data) {
    state.selectedCategory = data;
  },
  SET_LIST_CATEGORY_FILTER(state, data) {
    state.listCategoryFilter = data;
  },
  SET_CHECK_NAME_TH(state, data) {
    state.checkNameTh = data;
  },
  SET_CHECK_NAME_ENG(state, data) {
    state.checkNameEng = data;
  },
  UPDATE_FIELD(state, data) {
    Object.assign(state.heritageDetail, data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
