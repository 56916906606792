<template>
  <div>
    <b-row>
      <b-col sm="6">
        <p class="titleTable">รายการบัญชีผู้ใช้</p>
      </b-col>
      <b-col sm="6">
      </b-col>
    </b-row>

    <v-client-table :name="name" :ref="name" :columns="columns" :options="options"
    :data="tableData">
      <div slot="beforeLimit" style="" class="right">
        <div style="margin-right: 10px;">
          <label :label-cols="2" :horizontal="true">สิทธิ์:</label>
          <b-form-select @input="setSelectedUserType" :value="selectedUserType"
          :options="[...listUserType]">
          </b-form-select>
        </div>
      </div>
      <div slot="id" slot-scope="props" target="_blank" style="text-align:center;">
        <a href="#" @click="gotoViewDetail(props.row.id)" style="text-decoration: none;">Details</a>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
} from 'vuex';

export default {
  watch: {
    selectedUserType() {
      this.setMyFilter();
    },
  },
  async created() {
    await this.$store.dispatch('Users/clearState');
    await this.$store.dispatch('Users/getData');
  },
  computed: {
    ...mapGetters('Users', ['listUsers', 'selectedUserType', 'listUserType']),
  },
  methods: {
    ...mapActions('Users', ['setSelectedUserType']),
    setMyFilter() {
      const {
        selectedUserType,
      } = this;
      const valueFilter = {
        selectedUserType,
      };
      this.$store.commit('Users/SET_CUSTOM_FILTER', {
        filter: 'smartFilter',
        value: valueFilter,
      });
    },
    gotoViewDetail(id) {
      this.$router.push(`detail/${id}`);
    },
  },
  data() {
    return {
      columns: [
        'name',
        'email',
        'role',
        'id',
      ],
      tableData: [],
      name: 'Users',
      options: {
        texts: {
          filterPlaceholder: 'Search',
        },
        preserveState: true,
        headings: {
          name: 'ชื่อผู้ใช้',
          email: 'อีเมล',
          role: 'สิทธิ์การใช้งาน',
          id: 'รายละเอียด',
        },
        customFilters: [{
          name: 'smartFilter',
          callback(row, query) {
            const {
              selectedUserType,
            } = query;
            let statusMatch = true;
            if (selectedUserType !== 'All') {
              console.log(row.role);
              statusMatch = row.role === selectedUserType;
            }
            return statusMatch;
          },
        }],
        sortIcon: {
          is: 'fa-sort',
          base: 'fa',
          up: 'fa-sort-up',
          down: 'fa-sort-down',
        },
      },
    };
  },
};
</script>

<style scoped>
  .titleTable {
    padding-left: 20px;
    font-size: 20px;
    margin-bottom: 0;
    color: #173B6B;
  }

  .custom-select {
    border-radius: 20px;
  }

  .btnForm1Detail {
    float: right;
    margin: 0 20px 20px 0;
  }
</style>
