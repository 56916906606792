/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  uploadFile(file, name) {
    const data = new FormData();
    data.append('file', file);
    console.log('uploadFile', data);
    let boundary = '--------------------------';
    for (let i = 0; i < 24; i += 1) {
      boundary += Math.floor(Math.random() * 10).toString(16);
    }
    return new Promise((resolve, reject) => {
      API.uploadSingleFile({
        data,
        name,
        boundary,
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  uploadFiles(data) {
    console.log('uploadFiles', data);
    let boundary = '--------------------------';
    for (let i = 0; i < 24; i += 1) {
      boundary += Math.floor(Math.random() * 10).toString(16);
    }
    return new Promise((resolve, reject) => {
      API.uploadMultipleFile({
        data,
        boundary,
      }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
};
