/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import userAPI from '@/api/user';
import store from '@/store';

const state = {
  isLoggedIn: false,
  statusLogin: '',
  token: '',
  userData: {
    id: '',
    name: '',
    email: '',
    role: '',
  },
  tokenForCheckStatus: '',
};

const getters = {
  isLoggedIn: (state) => state.isLoggedIn,
  statusLogin: (state) => state.statusLogin,
  token: (state) => state.token,
  userData: (state) => state.userData,
  id: (state) => state.userData.id,
  name: (state) => state.userData.name,
  email: (state) => state.userData.email,
  role: (state) => state.userData.role,
  tokenForCheckStatus: (state) => state.tokenForCheckStatus,
};

const actions = {
  async login({
    commit,
  }, { email, password }) {
    console.log(email, password);
    const member = await userAPI.login(email, password);
    console.log('member', member);
    if (!member) {
      commit('LOGIN_FAIL');
      return 'fail';
    }
    localStorage.setItem('token', member.accessToken);
    member.user.timestamp = member.authentication.payload.exp;
    commit('TOKEN', member.accessToken);
    commit('SET_USERDATA', member.user);
    commit('LOGIN_SUCCESS');
    return 'success';
  },
  setTokenForCheckStatus({
    commit,
  }, token) {
    commit('SET_TOKEN_CHECK_STATUS', token);
  },
  async setLogin({
    commit,
  }, query) {
    localStorage.setItem('token', query.token);
    await commit('TOKEN', query.token);
    const member = await userAPI.getUserDetailFromFrontend(query.user_id);
    await commit('SET_USERDATA', member);
    await commit('LOGIN_SUCCESS');
  },
  async logout({
    commit,
  }) {
    await store.dispatch['Dashboard/clearState'];
    await store.dispatch['Sme/clearState'];
    await store.dispatch['Form1/clearState'];
    await store.dispatch['Form2/clearState'];
    await store.dispatch['SummaryMoney/clearState'];
    await store.dispatch['CheckForm1/clearState'];
    await store.dispatch['CheckForm2/clearState'];
    await commit('LOGOUT');
  },
};

const mutations = {
  LOGIN_SUCCESS(state) {
    console.log('LOGIN_SUCCESS');
    state.isLoggedIn = true;
    state.statusLogin = 'success';
    console.log('LOGIN_SUCCESS');
  },
  LOGIN_FAIL(state) {
    state.statusLogin = 'fail';
  },
  TOKEN(state, token) {
    state.token = token;
  },
  SET_USERDATA(state, data) {
    console.log('SET_USERDATA', data);
    state.userData = data;
  },
  SET_TOKEN_CHECK_STATUS(state, token) {
    state.tokenForCheckStatus = token;
  },
  LOGOUT(state) {
    localStorage.removeItem('token');
    state.isLoggedIn = false;
    state.token = '';
    state.userData = {};
    state.statusLogin = '';
    state.tokenForCheckStatus = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
