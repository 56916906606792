import Heritage from '@/views/Heritage.vue';
import HeritageDetail from '@/views/HeritageDetail.vue';

export default {
  path: 'backoffice/heritage',
  name: 'All Heritage',
  redirect: 'backoffice/heritage/all',
  component: {
    render(h) {
      return h('router-view');
    },
  },
  children: [
    {
      path: 'all',
      name: '   ',
      component: Heritage,
    },
    {
      path: 'detail/:id',
      name: 'Heritage Details',
      component: HeritageDetail,
    },
  ],
};
