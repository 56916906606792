/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  getProduct() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/product',
        params: {
          $limit: 5000,
          $skip: 0,
          '$sort[update_at]': -1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getProductCategory() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/product-category',
        params: {
          $limit: 5000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getArea() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/area',
        params: {
          $limit: 5000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritage() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage',
        params: {
          $limit: 5000,
          $skip: 0,
          public: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getProductById(id) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/product/${id}`,
        params: {
          backoffice: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  updateProduct(id, product) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/product/${id}`,
        data: {
          ...product,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  deleteProduct(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/product/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  createProduct(product) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/product',
        data: {
          ...product,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  createProductMedia(media) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/product-media',
        data: {
          ...media,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  patchProductMedia(id, media) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/product-media/${id}`,
        data: {
          ...media,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  deleteProductMedia(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/product-media/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getProductVdo(productId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/product-media',
        params: {
          product_id: productId,
          is_vdo: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getProductSub(productId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/product-media',
        params: {
          product_id: productId,
          is_subtitle: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getProductPicture(productId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/product-media',
        params: {
          product_id: productId,
          is_picture: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
