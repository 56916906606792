/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  getConceptProduct() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/concept-product',
        params: {
          $limit: 5000,
          $skip: 0,
          '$sort[update_at]': -1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getConceptProductCategory() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/concept-product-category',
        params: {
          $limit: 5000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getArea() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/area',
        params: {
          $limit: 5000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritage() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage',
        params: {
          $limit: 5000,
          $skip: 0,
          public: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getConceptProductById(idNum) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/concept-product/${idNum}`,
        params: {
          backoffice: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  updateConceptProduct(idNum, conceptProduct) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/concept-product/${idNum}`,
        data: {
          ...conceptProduct,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  createConceptProduct(conceptProduct) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/concept-product',
        data: {
          ...conceptProduct,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  delConceptProduct(idNum) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/concept-product/${idNum}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  createConceptMedia(media) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/concept-product-media',
        data: {
          ...media,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  patchConceptMedia(id, media) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/concept-product-media/${id}`,
        data: {
          ...media,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  deleteConceptMedia(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/concept-product-media/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getConceptVdo(conceptId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/concept-product-media',
        params: {
          concept_product_id: conceptId,
          is_vdo: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getConceptSub(conceptId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/concept-product-media',
        params: {
          concept_product_id: conceptId,
          is_subtitle: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getConceptPicture(conceptId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/concept-product-media',
        params: {
          concept_product_id: conceptId,
          is_picture: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
