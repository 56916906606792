/* eslint-disable import/no-cycle */
import Vue from 'vue';
import store from '@/store';
import { baseURL } from '../../config';

function axiosRequest(params, method) {
  const authToken = store.getters['User/token'];
  const headers = {
    'Content-Type': 'application/json',
  };
  return Vue.axios.request({
    baseURL,
    url: params.url,
    method,
    headers: Object.assign(headers, {
      Authorization: `Bearer ${authToken}`,
    }),
    withCredentials: false,
    data: { ...params.data },
    params: { ...params.params },
  });
}

export const post = async (params) => {
  const method = 'post';
  try {
    const res = await axiosRequest(params, method);
    return {
      axiosData: res.data,
    };
  } catch (err) {
    if (err.response.status === 401) {
      store.dispatch('User/logout');
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};

export const get = async (params) => {
  const method = 'get';
  try {
    const res = await axiosRequest(params, method);
    return {
      axiosData: res.data,
    };
  } catch (err) {
    if (err.response.status === 401) {
      store.dispatch('User/logout');
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};

export const put = async (params) => {
  const method = 'put';
  try {
    const res = await axiosRequest(params, method);
    return {
      axiosData: res.data,
    };
  } catch (err) {
    if (err.response.status === 401) {
      store.dispatch('User/logout');
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};

export const patch = async (params) => {
  const method = 'patch';
  try {
    const res = await axiosRequest(params, method);
    return {
      axiosData: res.data,
    };
  } catch (err) {
    if (err.response.status === 401) {
      store.dispatch('User/logout');
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};

export const del = async (params) => {
  const method = 'delete';
  try {
    const res = await axiosRequest(params, method);
    return {
      axiosData: res.data,
    };
  } catch (err) {
    if (err.response.status === 401) {
      store.dispatch('User/logout');
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};

export const uploadSingleFile = ({ data, name, boundary }) => {
  try {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`${baseURL}/upload_file/upload`,
        data,
        {
          params: {
            name,
          },
        },
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${boundary}`,
          },
          timeout: 30000,
        }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  } catch (err) {
    if (err.response.status === 401) {
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};

export const uploadMultipleFile = ({ data, name, boundary }) => {
  try {
    return new Promise((resolve, reject) => {
      Vue.axios.post(`${baseURL}/upload_file/uploads`,
        data,
        {
          params: {
            name,
          },
        },
        {
          headers: {
            'Content-Type': `multipart/form-data; boundary=${boundary}`,
          },
          timeout: 30000,
        }).then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  } catch (err) {
    if (err.response.status === 401) {
      console.log(err);
      window.location.reload();
    }
    return false;
  }
};
