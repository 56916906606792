<template>
  <div>
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>

    <p class="titleDetailHeritage">ข้อมูลมรดกภูมิปัญญาทางวัฒนธรรม</p>
    <b-row id="formDetailColumn">
      <b-col md="6">
        <HeritageDetailThai></HeritageDetailThai>
      </b-col>

      <b-col md="6">
        <HeritageDetailEng></HeritageDetailEng>
      </b-col>

      <b-col md="12">
        <br>
        <HeritageDetailOverall></HeritageDetailOverall>
      </b-col>

    </b-row>
    <p :class="resultTextClass">{{resultText}}</p>
    <b-button v-if="!createMode" variant="danger" class="btnForm1Detail"
    @click="confirmDeleteModal=true">
      ลบ
    </b-button>
    <b-button v-if="!createMode" variant="success" class="btnForm1Detail"
    :href="previewUrl" target="_blank">
      ดูตัวอย่าง
    </b-button>
    <b-button variant="success" class="btnForm1Detail"
    @click="checkName">
      บันทึกข้อมูล
    </b-button>
    <b-modal :title="titleUpdateHeritageModal"
    id="confirmUpdateHeritageModal" centered class="modal-primary"
    v-model="confirmUpdateHeritageModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmUpdateHeritageModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="updateHeritage">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal :title="titleDeleteHeritageModal" centered class="modal-primary"
    v-model="confirmDeleteModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmDeleteModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success"
            @click="delHeritage">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hostURL } from '../../../config';
import HeritageDetailThai from './HeritageDetailThai.vue';
import HeritageDetailEng from './HeritageDetailEng.vue';
import HeritageDetailOverall from './HeritageDetailOverall.vue';

export default {
  components: {
    HeritageDetailThai,
    HeritageDetailEng,
    HeritageDetailOverall,
  },
  computed: {
    ...mapGetters('Heritage', ['heritageDetail', 'showLoading', 'createMode']),
    HeritageDataName() {
      return this.heritageDetail.name;
    },
  },
  watch: {
    HeritageDataName() {
      if (this.createMode) {
        this.titleUpdateHeritageModal = `คุณแน่ใจใช่ไหมที่สร้าง
        ${this.heritageDetail.name} ?`;
      } else {
        this.titleUpdateHeritageModal = `คุณแน่ใจใช่ไหมที่แก้ไขข้อมูล
        ${this.heritageDetail.name} ?`;
      }
      this.titleDeleteHeritageModal = `คุณแน่ใจใช่ไหมที่จะลบ ${this.heritageDetail.name} ?`;
      this.previewUrl = `${hostURL}heritage/${this.heritageDetail.id}`;
    },
  },
  data() {
    return {
      confirmUpdateHeritageModal: false,
      titleUpdateHeritageModal: '',
      resultText: '',
      resultTextClass: '',
      confirmDeleteModal: false,
      titleDeleteHeritageModal: '',
      previewUrl: '',
    };
  },
  methods: {
    ...mapActions('Heritage', ['updateHeritageDetail', 'updateField', 'deleteHeritage',
      'setCheckNameTh', 'setCheckNameEng']),

    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedHeritage', this.heritageDetail);
    },
    async updateHeritage() {
      this.confirmUpdateHeritageModal = false;
      const result = await this.updateHeritageDetail();
      console.log('updateHeritage', this.heritageDetail);
      this.resultText = result ? 'อัปเดตข้อมูลสำเร็จ' : 'อัปเดตข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
      this.resultTextClass = result ? 'resultSuccess' : 'resultFail';
      console.log('result', result, this.createMode);

      if (result && this.createMode) {
        this.$router.push('/backoffice/heritage/all');
      }
    },
    async delHeritage() {
      console.log('delete');
      this.confirmDeleteModal = false;
      const result = await this.deleteHeritage(this.heritageDetail.id);
      console.log('deleteHeritage', this.heritageDetail);
      if (result) {
        this.$router.push('/backoffice/heritage/all');
      } else {
        this.resultText = 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
        this.resultTextClass = 'resultFail';
      }
    },
    checkName() {
      let checkTh = false;
      let checkEng = false;
      let resText = '';
      this.resultText = '';
      if (this.heritageDetail.name !== null && this.heritageDetail.name !== '') {
        const nameTh = this.heritageDetail.name.trim();
        if (nameTh !== '') {
          checkTh = true;
          this.setCheckNameTh(false);
        } else {
          resText = 'กรุณากรอกชื่อภาษาไทย';
          this.setCheckNameTh(true);
        }
      } else {
        resText = 'กรุณากรอกชื่อภาษาไทย';
        this.setCheckNameTh(true);
      }

      if (this.heritageDetail.name_eng !== null && this.heritageDetail.name_eng !== '') {
        const nameEng = this.heritageDetail.name_eng.trim();
        if (nameEng !== '') {
          checkEng = true;
          this.setCheckNameEng(false);
        } else {
          resText = `${resText} กรุณากรอกชื่อภาษาอังกฤษ`;
          this.setCheckNameEng(true);
        }
      } else {
        resText = `${resText} กรุณากรอกชื่อภาษาอังกฤษ`;
        this.setCheckNameEng(true);
      }

      if (checkTh && checkEng) {
        this.confirmUpdateHeritageModal = true;
      } else {
        this.resultText = resText;
        this.resultTextClass = 'resultFail';
      }
    },
  },
};
</script>

<style>
.titleDetailHeritage {
  font-size: 20px;
  color: #173b6b;
}
#formDetailColumn {
  padding: 0 20px 0 0;
}
.btnForm1Detail {
  float: right;
  margin: 0 20px 20px 0;
}
.resultSuccess {
  color: #28a745;
  font-size: 20px;
}
.resultFail {
  color: red;
  font-size: 20px;
}
.overlay {
  background-color: #efefef;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  opacity: 0.3;
}
.alertCheck {
  color: red;
  text-align: right;
  margin-right: 20px;
}
</style>
