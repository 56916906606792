<template>
  <div>
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>

    <p class="titleDetailProduct">ข้อมูลผลิตภัณฑ์ต้นแบบ</p>
    <b-row id="formDetailColumn">
      <b-col md="6">
        <ProductDetailThai></ProductDetailThai>
      </b-col>

      <b-col md="6">
        <ProductDetailEng></ProductDetailEng>
      </b-col>

      <b-col md="12">
        <br>
        <ProductDetailOverall></ProductDetailOverall>
      </b-col>

    </b-row>
    <p :class="resultTextClass">{{resultText}}</p>
    <b-button v-if="!createMode" variant="danger" class="btnForm1Detail"
    @click="confirmDeleteModal=true">
      ลบ
    </b-button>
    <b-button v-if="!createMode" variant="success" class="btnForm1Detail"
    :href="previewUrl" target="_blank">
      ดูตัวอย่าง
    </b-button>
    <b-button variant="success" class="btnForm1Detail"
    @click="checkName">
      บันทึกข้อมูล
    </b-button>
    <b-modal :title="titleUpdateProductModal"
    id="confirmUpdateProductModal" centered class="modal-primary"
    v-model="confirmUpdateProductModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmUpdateProductModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="updateProduct">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal :title="titleDeleteProductModal"
    id="confirmDeleteModal" centered class="modal-primary" v-model="confirmDeleteModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmDeleteModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success"
            @click="deleProduct">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hostURL } from '../../../config';
import ProductDetailThai from './ProductDetailThai.vue';
import ProductDetailEng from './ProductDetailEng.vue';
import ProductDetailOverall from './ProductDetailOverall.vue';

export default {
  components: {
    ProductDetailThai,
    ProductDetailEng,
    ProductDetailOverall,
  },
  computed: {
    ...mapGetters('Product', ['productDetail', 'showLoading', 'createMode']),
    ProductDataName() {
      return this.productDetail.name;
    },
  },
  watch: {
    ProductDataName() {
      if (this.createMode) {
        this.titleUpdateProductModal = `คุณแน่ใจใช่ไหมที่สร้าง
        ${this.productDetail.name} ?`;
      } else {
        this.titleUpdateProductModal = `คุณแน่ใจใช่ไหมที่แก้ไขข้อมูล
        ${this.productDetail.name} ?`;
      }
      this.titleDeleteProductModal = `คุณแน่ใจใช่ไหมที่จะลบ ${this.productDetail.name} ?`;
      this.previewUrl = `${hostURL}product/${this.productDetail.id}`;
    },
  },
  data() {
    return {
      confirmUpdateProductModal: false,
      titleUpdateProductModal: '',
      resultText: '',
      resultTextClass: '',
      confirmDeleteModal: false,
      titleDeleteProductModal: '',
      previewUrl: '',
    };
  },
  methods: {
    ...mapActions('Product', ['updateProductDetail', 'updateField', 'deleteProduct',
      'setCheckNameTh', 'setCheckNameEng']),
    async updateProduct() {
      this.confirmUpdateProductModal = false;
      const result = await this.updateProductDetail();
      console.log('updateProduct', this.ProductDetail);
      this.resultText = result ? 'อัปเดตข้อมูลสำเร็จ' : 'อัปเดตข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
      this.resultTextClass = result ? 'resultSuccess' : 'resultFail';
      if (result && this.createMode) {
        this.$router.push('/backoffice/product/all');
      }
    },
    async deleProduct() {
      this.confirmDeleteModal = false;
      const result = await this.deleteProduct(this.productDetail.id);
      console.log('deleteProduct', this.productDetail);
      if (result) {
        this.$router.push('/backoffice/product/all');
      } else {
        this.resultText = 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
        this.resultTextClass = 'resultFail';
      }
    },
    checkName() {
      let checkTh = false;
      let checkEng = false;
      let resText = '';
      this.resultText = '';
      if (this.productDetail.name !== null && this.productDetail.name !== '') {
        const nameTh = this.productDetail.name.trim();
        if (nameTh !== '') {
          checkTh = true;
          this.setCheckNameTh(false);
        } else {
          resText = 'กรุณากรอกชื่อภาษาไทย';
          this.setCheckNameTh(true);
        }
      } else {
        resText = 'กรุณากรอกชื่อภาษาไทย';
        this.setCheckNameTh(true);
      }

      if (this.productDetail.name_eng !== null && this.productDetail.name_eng !== '') {
        const nameEng = this.productDetail.name_eng.trim();
        if (nameEng !== '') {
          checkEng = true;
          this.setCheckNameEng(false);
        } else {
          resText = `${resText} กรุณากรอกชื่อภาษาอังกฤษ`;
          this.setCheckNameEng(true);
        }
      } else {
        resText = `${resText} กรุณากรอกชื่อภาษาอังกฤษ`;
        this.setCheckNameEng(true);
      }

      if (checkTh && checkEng) {
        this.confirmUpdateProductModal = true;
      } else {
        this.resultText = resText;
        this.resultTextClass = 'resultFail';
      }
    },
  },
};
</script>

<style>
  .titleDetailProduct {
    font-size: 20px;
    color: #173B6B;
  }
  #formDetailColumn {
    padding: 0 20px 0 0;
  }
  .btnForm1Detail {
    float: right;
    margin: 0 20px 20px 0;
  }
  .resultSuccess {
    color: #28a745;
    font-size: 20px;
  }
  .resultFail {
    color: red;
    font-size: 20px;
  }
  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }
  .alertCheck {
    color: red;
    text-align: right;
    margin-right: 20px;
  }
</style>
