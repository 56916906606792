<template>
  <div>
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <p class="titleDetailProperty">1. หน้าแรก</p>
    <b-row id="formDetailColumn">
      <b-col md="3">
        <b-form-group label="banner ใหญ่" label-for="banner_landing">
          <b-img center :src="bannerMedia[0].url" class="gridImage"
          v-if="bannerMedia[0].url" alt="banner landing"></b-img>
          <label for="uploadBannerLanding" class="labelBtnUploadImage">
            อัปโหลดรูป
          </label>
          <input id="uploadBannerLanding" type="file" ref="banner_landing"
          accept=".jpg, .png, .bmp" @change="uploadBanner(0)"/>
          <p v-if="verify_banner_landing">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <p class="subTitleDetailProperty">1.1. ประเภทมรดก</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner วัฒนธรรมมุขปาฐะและการแสดงออก ด้วยภาษา"
        label-for="banner_heritage_culture" class="gridLabel">
          <b-img center :src="bannerMedia[1].url" class="gridImage"
          v-if="bannerMedia[1].url" alt="banner_heritage_culture"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritageCulture" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerHeritageCulture" type="file" ref="banner_heritage_culture"
        accept=".jpg, .png, .bmp" @change="uploadBanner(1)"/>
        <p v-if="verify_banner_heritage_culture">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner ศิลปะการแสดง" label-for="banner_heritage_perform"
        class="gridLabel">
          <b-img center :src="bannerMedia[2].url" class="gridImage"
          v-if="bannerMedia[2].url" alt="banner_heritage_perform"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritagePerform" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerHeritagePerform" type="file" ref="banner_heritage_perform"
        accept=".jpg, .png, .bmp" @change="uploadBanner(2)"/>
        <p v-if="verify_banner_heritage_perform">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner วัตรปฏิบัติทางสังคมพิธีกรรม และประเพณี"
        label-for="banner_heritage_social" class="gridLabel">
          <b-img center :src="bannerMedia[3].url" class="gridImage"
          v-if="bannerMedia[3].url" alt="banner_heritage_social"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritageSocial" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerHeritageSocial" type="file" ref="banner_heritage_social"
        accept=".jpg, .png, .bmp" @change="uploadBanner(3)"/>
        <p v-if="verify_banner_heritage_social">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner ความรู้และแนวปฏิบัติที่เกี่ยวข้องกับธรรมชาติ และจักรวาล"
        label-for="banner_heritage_knowledge" class="gridLabel">
          <b-img center :src="bannerMedia[4].url" class="gridImage"
          v-if="bannerMedia[4].url" alt="banner_heritage_knowledge"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritageKnowledge" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerHeritageKnowledge" type="file" ref="banner_heritage_knowledge"
        accept=".jpg, .png, .bmp" @change="uploadBanner(4)"/>
        <p v-if="verify_banner_heritage_knowledge">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner หัตถกรรมท้องถิ่น" label-for="banner_heritage_local"
         class="gridLabel">
          <b-img center :src="bannerMedia[5].url" class="gridImage"
          v-if="bannerMedia[4].url" alt="banner_heritage_local"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritageLocal" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerHeritageLocal" type="file" ref="banner_heritage_local"
        accept=".jpg, .png, .bmp" @change="uploadBanner(5)"/>
        <p v-if="verify_banner_heritage_local">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner มรดกภูมิปัญญาอื่นๆ" label-for="banner_heritage_other"
         class="gridLabel">
          <b-img center :src="bannerMedia[6].url" class="gridImage"
          v-if="bannerMedia[6].url" alt="banner_heritage_other"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritageOther" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerHeritageOther" type="file" ref="banner_heritage_other"
        accept=".jpg, .png, .bmp" @change="uploadBanner(6)"/>
        <p v-if="verify_banner_heritage_other">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <p class="subTitleDetailProperty">1.2. พื้นที่มรดก</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner เมืองเก่าเชียงแสน"
        label-for="banner_area_chiangsaen" class="gridLabel">
          <b-img center :src="bannerMedia[7].url" class="gridImage"
          v-if="bannerMedia[7].url" alt="banner_area_chiangsaen"></b-img>
        </b-form-group>

          <label for="uploadBannerAreaChiangsaen" class="labelBtnUploadImage">
            อัปโหลดรูป
          </label><br><br>
          <input id="uploadBannerAreaChiangsaen" type="file" ref="banner_area_chiangsaen"
          accept=".jpg, .png, .bmp" @change="uploadBanner(7)"/>
          <p v-if="verify_banner_area_chiangsaen">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner เมืองเก่าน่าน" label-for="banner_area_nan"
        class="gridLabel">
          <b-img center :src="bannerMedia[8].url" class="gridImage"
          v-if="bannerMedia[8].url" alt="banner_area_nan"></b-img>
        </b-form-group>

        <label for="uploadBannerAreaNan" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerAreaNan" type="file" ref="banner_area_nan"
        accept=".jpg, .png, .bmp" @change="uploadBanner(8)"/>
        <p v-if="verify_banner_area_nan">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <p class="subTitleDetailProperty">1.3. ดาวน์โหลดแอป</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ดาวน์โหลดแอป"
        label-for="banner_app" class="gridLabel">
          <b-img center :src="bannerMedia[9].url" class="gridImage"
          v-if="bannerMedia[9].url" alt="banner_app"></b-img>
        </b-form-group>

        <label for="uploadBannerApp" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerApp" type="file" ref="banner_app"
        accept=".jpg, .png, .bmp" @change="uploadBanner(9)"/>
        <p v-if="verify_banner_app">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <br>
    <p class="titleDetailProperty">2.หน้าเกี่ยวกับโครงการ</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ที่มาและความสำคัญของโครงการ"
        label-for="banner_origin" class="gridLabel">
          <b-img center :src="bannerMedia[10].url" class="gridImage"
          v-if="bannerMedia[10].url" alt="banner_origin"></b-img>
        </b-form-group>
        <label for="uploadBannerOrigin" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerOrigin" type="file" ref="banner_origin"
        accept=".jpg, .png, .bmp" @change="uploadBanner(10)"/>
        <p v-if="verify_banner_origin">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner gstc" label-for="banner_gstc"
        class="gridLabel">
          <b-img center :src="bannerMedia[11].url" class="gridImage"
          v-if="bannerMedia[11].url" alt="banner_gstc"></b-img>
        </b-form-group>
        <label for="uploadBannerGSTC" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerGSTC" type="file" ref="banner_gstc"
        accept=".jpg, .png, .bmp" @change="uploadBanner(11)"/>
        <p v-if="verify_banner_gstc">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner ttci"
        label-for="banner_ttci" class="gridLabel">
          <b-img center :src="bannerMedia[12].url" class="gridImage"
          v-if="bannerMedia[12].url" alt="banner_ttci"></b-img>
        </b-form-group>
        <label for="uploadBannerTTCI" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label><br><br>
        <input id="uploadBannerTTCI" type="file" ref="banner_ttci"
        accept=".jpg, .png, .bmp" @change="uploadBanner(12)"/>
        <p v-if="verify_banner_ttci">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col md="3">
        <b-form-group label="banner มรดกภูมิปัญญาทางวัฒนธรรม"
        label-for="banner_culture" class="gridLabel">
          <b-img center :src="bannerMedia[13].url" class="gridImage"
          v-if="bannerMedia[13].url" alt="banner_culture"></b-img>
        </b-form-group>
        <label for="uploadBannerBannerCulture" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerBannerCulture" type="file" ref="banner_culture"
        accept=".jpg, .png, .bmp" @change="uploadBanner(13)"/>
        <p v-if="verify_banner_culture">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <br>
    <p class="titleDetailProperty">3. หน้ามรดก</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ใหญ่"
        label-for="banner_heritage" class="gridLabel">
          <b-img center :src="bannerMedia[14].url" class="gridImage"
          v-if="bannerMedia[14].url" alt="banner_heritage"></b-img>
        </b-form-group>
        <label for="uploadBannerHeritage" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerHeritage" type="file" ref="banner_heritage"
        accept=".jpg, .png, .bmp" @change="uploadBanner(14)"/>
        <p v-if="verify_banner_heritage">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <br>
    <p class="titleDetailProperty">4. หน้าแนวคิดผลิตภัณฑ์</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ใหญ่"
        label-for="banner_concept_product" class="gridLabel">
          <b-img center :src="bannerMedia[15].url" class="gridImage"
          v-if="bannerMedia[15].url" alt="banner_concept_product"></b-img>
        </b-form-group>
        <label for="uploadBannerConceptProduct" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerConceptProduct" type="file" ref="banner_concept_product"
        accept=".jpg, .png, .bmp" @change="uploadBanner(15)"/>
        <p v-if="verify_banner_concept_product">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <br>
    <p class="titleDetailProperty">5. ผลิตภัณฑ์ต้นแบบ</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ใหญ่"
        label-for="banner_product" class="gridLabel">
          <b-img center :src="bannerMedia[16].url" class="gridImage"
          v-if="bannerMedia[16].url" alt="banner_product"></b-img>
        </b-form-group>
        <label for="uploadBannerProduct" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerProduct" type="file" ref="banner_product"
        accept=".jpg, .png, .bmp" @change="uploadBanner(16)"/>
        <p v-if="verify_banner_product">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <br>
    <p class="titleDetailProperty">6. หน้ามองรู้ดูออก</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ใหญ่"
        label-for="banner_knowledge" class="gridLabel">
          <b-img center :src="bannerMedia[17].url" class="gridImage"
          v-if="bannerMedia[17].url" alt="banner_knowledge"></b-img>
        </b-form-group>
        <label for="uploadBannerKnowledge" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerKnowledge" type="file" ref="banner_knowledge"
        accept=".jpg, .png, .bmp" @change="uploadBanner(17)"/>
        <p v-if="verify_banner_knowledge">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
    <br>
    <br>
    <p class="titleDetailProperty">7. หน้าค้นหา</p>
    <b-row>
      <b-col md="3">
        <b-form-group label="banner ใหญ่"
        label-for="banner_search" class="gridLabel">
          <b-img center :src="bannerMedia[18].url" class="gridImage"
          v-if="bannerMedia[18].url" alt="banner_search"></b-img>
        </b-form-group>
        <label for="uploadBannerSearch" class="labelBtnUploadImage">
          อัปโหลดรูป
        </label>
        <input id="uploadBannerSearch" type="file" ref="banner_search"
        accept=".jpg, .png, .bmp" @change="uploadBanner(18)"/>
        <p v-if="verify_banner_search">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  mapGetters,
  mapActions,
} from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  async created() {
    await this.$store.dispatch('Detail/clearState');
    await this.$store.dispatch('Detail/getAllDetail');
  },
  computed: {
    ...mapGetters('Detail', ['bannerMedia', 'showLoading']),
  },
  methods: {
    ...mapActions('Detail', ['updateField', 'setShowLoading']),
    async uploadBanner(arrPointer) {
      this.setShowLoading(true);
      let file;
      let size;
      let name;
      let checkFileSize = false;
      switch (arrPointer) {
        case 0:
          if (this.$refs.banner_landing.files[0]) {
            file = this.$refs.banner_landing.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_landing = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_landing = false;
          }
          break;

        case 1:
          if (this.$refs.banner_heritage_culture.files[0]) {
            file = this.$refs.banner_heritage_culture.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage_culture = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage_culture = false;
          }
          break;

        case 2:
          if (this.$refs.banner_heritage_perform.files[0]) {
            file = this.$refs.banner_heritage_perform.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage_perform = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage_perform = false;
          }
          break;

        case 3:
          if (this.$refs.banner_heritage_social.files[0]) {
            file = this.$refs.banner_heritage_social.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage_social = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage_social = false;
          }
          break;

        case 4:
          if (this.$refs.banner_heritage_knowledge.files[0]) {
            file = this.$refs.banner_heritage_knowledge.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage_knowledge = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage_knowledge = false;
          }
          break;

        case 5:
          if (this.$refs.banner_heritage_local.files[0]) {
            file = this.$refs.banner_heritage_local.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage_local = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage_local = false;
          }
          break;

        case 6:
          if (this.$refs.banner_heritage_other.files[0]) {
            file = this.$refs.banner_heritage_other.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage_other = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage_other = false;
          }
          break;

        case 7:
          if (this.$refs.banner_area_chiangsaen.files[0]) {
            file = this.$refs.banner_area_chiangsaen.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_area_chiangsaen = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_area_chiangsaen = false;
          }
          break;

        case 8:
          if (this.$refs.banner_area_nan.files[0]) {
            file = this.$refs.banner_area_nan.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_area_nan = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_area_nan = false;
          }
          break;

        case 9:
          if (this.$refs.banner_app.files[0]) {
            file = this.$refs.banner_app.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_app = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_app = false;
          }
          break;

        case 10:
          if (this.$refs.banner_origin.files[0]) {
            file = this.$refs.banner_origin.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_origin = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_origin = false;
          }
          break;

        case 11:
          if (this.$refs.banner_gstc.files[0]) {
            file = this.$refs.banner_gstc.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_gstc = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_gstc = false;
          }
          break;

        case 12:
          if (this.$refs.banner_ttci.files[0]) {
            file = this.$refs.banner_ttci.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_ttci = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_ttci = false;
          }
          break;

        case 13:
          if (this.$refs.banner_culture.files[0]) {
            file = this.$refs.banner_culture.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_culture = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_culture = false;
          }
          break;

        case 14:
          if (this.$refs.banner_heritage.files[0]) {
            file = this.$refs.banner_heritage.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_heritage = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_heritage = false;
          }
          break;

        case 15:
          if (this.$refs.banner_concept_product.files[0]) {
            file = this.$refs.banner_concept_product.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_concept_product = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_concept_product = false;
          }
          break;

        case 16:
          if (this.$refs.banner_product.files[0]) {
            file = this.$refs.banner_product.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_product = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_product = false;
          }
          break;

        case 17:
          if (this.$refs.banner_knowledge.files[0]) {
            file = this.$refs.banner_knowledge.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_knowledge = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_knowledge = false;
          }
          break;

        case 18:
          if (this.$refs.banner_search.files[0]) {
            file = this.$refs.banner_search.files[0]; // eslint-disable-line
            size = file.size;
            name = file.name;
            console.log('file size', size);
            if (size > 3000000) {
              this.verify_banner_search = true;
              break;
            }
            checkFileSize = true;
            this.verify_banner_search = false;
          }
          break;

        default:
          break;
      }
      if (checkFileSize) {
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          const uploadData = {
            id: this.bannerMedia[arrPointer].id,
            name: this.bannerMedia[arrPointer].name,
            page: this.bannerMedia[arrPointer].page,
            url: uploadRes.data.url,
          };
          this.updateField(uploadData);
          console.log('bannerMedia in arrPointer', this.bannerMedia[arrPointer]);
        }
      }
      this.setShowLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
  },
  data() {
    return {
      verify_banner_landing: '',
      verify_banner_heritage_culture: '',
      verify_banner_heritage_perform: '',
      verify_banner_heritage_social: '',
      verify_banner_heritage_knowledge: '',
      verify_banner_heritage_local: '',
      verify_banner_heritage_other: '',
      verify_banner_area_chiangsaen: '',
      verify_banner_area_nan: '',
      verify_banner_app: '',
      verify_banner_origin: '',
      verify_banner_gstc: '',
      verify_banner_ttci: '',
      verify_banner_culture: '',
      verify_banner_heritage: '',
      verify_banner_concept_product: '',
      verify_banner_product: '',
      verify_banner_knowledge: '',
      verify_banner_search: '',
    };
  },
};
</script>

<style scope>

  #formDetailColumn {
    padding: 0 20px 0 0;
  }

  .btnForm1Detail {
    float: right;
    margin: 0 20px 20px 0;
  }

  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }

  .titleDetailProperty {
    font-size: 20px;
    color: #173B6B;
    font-weight: 600;
  }

  .subTitleDetailProperty {
    font-size: 18px;
    color: #173B6B;
  }

  .gridImage {
    height: 175px;
    width: auto;
  }

  @media (max-width: 1024px) {
    .gridImage {
      height: 100px;
      width: auto;
    }
  }

  .gridLabel label {
    height: 35px;
  }

</style>
