<template>
  <div>
    <p class="titleDetailKnowledge">ข้อมูลมองรู้ดูออก</p>
    <b-row id="formDetailColumn">
      <b-col md="6">
        <b-form-group label="ชื่อผู้เขียน" label-for="user_name" label-cols-sm="5"
        v-if="role == 'admin' && !createMode">
          <b-input-group>
            <b-form-input type="text" name="user_name" :value="userName" disabled>
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="ประเภทมองรู้ดูออก" label-for="knowledge_type" label-cols-sm="5">
          <b-input-group v-if="!createMode">
            <b-form-input type="text" name="knowledge_type" disabled
            :value="knowledgeDetail.is_article ? 'บทความ' : knowledgeDetail.is_media ? 'สื่อมีเดีย'
             : 'สิ่งพิมพ์'">
            </b-form-input>
          </b-input-group>
          <b-form-radio-group v-else id="radio-group-1" v-model="selected" name="knowledge_type"
            :options="role == 'admin' ? adminOptions : userOptions" @change="changeKnowledgeType"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <KnowledgeDetailThai></KnowledgeDetailThai>
      </b-col>
      <b-col md="6">
        <KnowledgeDetailEng></KnowledgeDetailEng>
      </b-col>
      <b-col md="12">
        <br>
        <br>
        <KnowledgeDetailOverall></KnowledgeDetailOverall>
      </b-col>
    </b-row>

    <p :class="resultTextClass">{{resultText}}</p>
    <b-button v-if="!createMode" variant="danger" class="btnForm1Detail"
    @click="confirmDeleteModal=true">
      ลบ
    </b-button>
    <b-button v-if="!createMode" variant="success" class="btnForm1Detail"
    :href="previewUrl" target="_blank">
      ดูตัวอย่าง
    </b-button>
    <b-button variant="success" class="btnForm1Detail"
    @click="checkName">
      บันทึกข้อมูล
    </b-button>
    <b-modal :title="titleUpdateKnowledgeModal"
    id="confirmUpdateKnowledgeModal" centered class="modal-primary"
    v-model="confirmUpdateKnowledgeModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmUpdateKnowledgeModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="updateKnowledge">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal :title="titleDeleteKnowledgeModal" centered class="modal-primary"
    v-model="confirmDeleteModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmDeleteModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success"
            @click="delKnowledge">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { hostURL } from '../../../config';
import KnowledgeDetailThai from './KnowledgeDetailThai.vue';
import KnowledgeDetailEng from './KnowledgeDetailEng.vue';
import KnowledgeDetailOverall from './KnowledgeDetailOverall.vue';

export default {
  computed: {
    ...mapGetters('Knowledge', ['knowledgeDetail', 'showLoading', 'createMode', 'userName']),
    ...mapGetters('User', ['role']),
    KnowledgeData() {
      return this.knowledgeDetail.name;
    },
  },
  components: {
    KnowledgeDetailThai,
    KnowledgeDetailEng,
    KnowledgeDetailOverall,
  },
  watch: {
    KnowledgeData() {
      if (this.createMode) {
        this.titleUpdateKnowledgeModal = `คุณแน่ใจใช่ไหมที่สร้าง
        ${this.knowledgeDetail.name} ?`;
      } else {
        this.titleUpdateKnowledgeModal = `คุณแน่ใจใช่ไหมที่แก้ไขข้อมูล
        ${this.knowledgeDetail.name} ?`;
      }
      this.titleDeleteKnowledgeModal = `คุณแน่ใจใช่ไหมที่จะลบ ${this.knowledgeDetail.name} ?`;
      this.previewUrl = `${hostURL}knowledge/${this.knowledgeDetail.id}`;
    },
  },
  data() {
    return {
      confirmUpdateKnowledgeModal: false,
      titleUpdateKnowledgeModal: '',
      resultText: '',
      resultTextClass: '',
      confirmDeleteModal: false,
      titleDeleteKnowledgeModal: '',
      previewUrl: '',
      adminOptions: [
        { text: 'บทความ', value: 'article' },
        { text: 'สื่อมีเดีย', value: 'media' },
        { text: 'สิ่งพิมพ์', value: 'publication' },
      ],
      userOptions: [
        { text: 'บทความ', value: 'article' },
      ],
      selected: 'article',
    };
  },
  methods: {
    ...mapActions('Knowledge', ['updateKnowledgeDetail', 'deleteKnowledge', 'updateField',
      'setCheckNameTh', 'setCheckNameEng']),

    async updateKnowledge() {
      this.confirmUpdateKnowledgeModal = false;
      const result = await this.updateKnowledgeDetail();
      console.log('updateKnowledge', this.knowledgeDetail);
      this.resultText = result ? 'อัปเดตข้อมูลสำเร็จ' : 'อัปเดตข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
      this.resultTextClass = result ? 'resultSuccess' : 'resultFail';
      console.log('result', result, this.createMode);

      if (result && this.createMode) {
        this.$router.push('/backoffice/knowledge/all');
      }
    },
    async delKnowledge() {
      console.log('delete');
      this.confirmDeleteModal = false;
      const result = await this.deleteKnowledge(this.knowledgeDetail.id);
      console.log('deleteKnowledge', this.knowledgeDetail);
      if (result) {
        this.$router.push('/backoffice/knowledge/all');
      } else {
        this.resultText = 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
        this.resultTextClass = 'resultFail';
      }
    },

    changeKnowledgeType() {
      switch (this.selected) {
        case 'article':
          this.updateField({ is_article: true });
          this.updateField({ is_media: false });
          this.updateField({ is_publication: false });
          break;
        case 'media':
          this.updateField({ is_article: false });
          this.updateField({ is_media: true });
          this.updateField({ is_publication: false });
          break;
        case 'publication':
          this.updateField({ is_article: false });
          this.updateField({ is_media: false });
          this.updateField({ is_publication: true });
          break;
        default:
          break;
      }
    },
    checkName() {
      let checkTh = false;
      let checkEng = false;
      let resText = '';
      this.resultText = '';
      if (this.knowledgeDetail.name !== null && this.knowledgeDetail.name !== '') {
        const nameTh = this.knowledgeDetail.name.trim();
        if (nameTh !== '') {
          checkTh = true;
          this.setCheckNameTh(false);
        } else {
          resText = 'กรุณากรอกชื่อภาษาไทย';
          this.setCheckNameTh(true);
        }
      } else {
        resText = 'กรุณากรอกชื่อภาษาไทย';
        this.setCheckNameTh(true);
      }

      if (this.knowledgeDetail.name_eng !== null && this.knowledgeDetail.name_eng !== '') {
        const nameEng = this.knowledgeDetail.name_eng.trim();
        if (nameEng !== '') {
          checkEng = true;
          this.setCheckNameEng(false);
        } else {
          resText = `${resText} กรุณากรอกชื่อภาษาอังกฤษ`;
          this.setCheckNameEng(true);
        }
      } else {
        resText = `${resText} กรุณากรอกชื่อภาษาอังกฤษ`;
        this.setCheckNameEng(true);
      }

      if (checkTh && checkEng) {
        this.confirmUpdateKnowledgeModal = true;
      } else {
        this.resultText = resText;
        this.resultTextClass = 'resultFail';
      }
    },
  },
};

</script>

<style>
.titleDetailKnowledge {
  font-size: 20px;
  color: #173b6b;
}

</style>
