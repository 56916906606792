<template>
  <div id="allDetail">
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <p class="titleReport">รายงาน</p>
    <p>รายงานสถิติการเข้าชมเว็บไซต์</p>
    <ReportData :report='"view"' @loading="setLoading"></ReportData>
    <p>รายงานสถิติการโหลดข้อมูลผ่านเครือข่าย</p>
    <ReportData :report='"usage"' @loading="setLoading"></ReportData>
    <p>รายงานสถิติคำค้นหาจากคอนเทนต์</p>
    <ReportData :report='"search"' @loading="setLoading"></ReportData>
    <p>รายงานสถิติการดาวน์โหลดแอปพลิเคชันได้ สามารถเข้าไปดูที่หน้า Dashboard</p>
    <b-button variant="success" href="https://appstoreconnect.apple.com" target="blank" class="btnDashboardApp">
      ไปที่ Dashboard IOS
    </b-button>
    <b-button variant="success" href="https://play.google.com/console" target="blank" class="btnDashboardApp">
      ไปที่ Dashboard Android
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import userAPI from '@/api/user';
import ReportData from '../components/Report/reportData.vue';

export default {
  components: {
    ReportData,
  },
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    ...mapGetters('User', ['userData']),
  },
  async created() {
    if (this.userData.role === 'admin') {
      const checkToken = await userAPI.getUser(this.userData);
      console.log('checkToken', checkToken);
    }
  },
  methods: {
    setLoading(data) {
      this.showLoading = data;
    },
  },
};

</script>

<style scope>
  .titleReport {
    font-size: 30px;
    color: #173B6B;
  }
  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }
  .btnDashboardApp {
    margin-right: 30px;
    margin-bottom: 30px;
    color: #FFF200!important;
    background-color: #532ce0!important;
  }
</style>
