<template>
  <div id="productDetail">
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <span class="titleProduct">{{productTitleName}}</span>
    <!-- <span :class="form1Detail.latest_update=='ตรวจแล้ว'? 'adminLatestUpdate':'smeLatestUpdate'"
    v-if="form1Detail.latest_update">
      ({{form1Detail.latest_update}})
    </span> -->
    <ProductDetail></ProductDetail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductDetail from '../components/Product/ProductDetail.vue';

export default {
  components: {
    ProductDetail,
  },
  async created() {
    const { id } = this.$route.params;
    await this.$store.dispatch('Product/clearState');
    await this.$store.dispatch('Product/getProductDetail', id);
  },
  computed: {
    ...mapGetters('Product', ['productDetail', 'showLoading']),
    ProductName() {
      return this.productDetail.name;
    },
  },
  watch: {
    ProductName() {
      if (this.productDetail.name !== null && this.productDetail.name !== ''
        && this.productDetail.name !== undefined) {
        const trimTitle = this.productDetail.name.trim();
        if (trimTitle.length !== 0) {
          this.productTitleName = this.productDetail.name;
        } else {
          this.productTitleName = 'ชื่อผลิตภัณฑ์ต้นแบบ';
        }
      } else {
        this.productTitleName = 'ชื่อผลิตภัณฑ์ต้นแบบ';
      }
    },
  },
  data() {
    return {
      productTitleName: 'ชื่อผลิตภัณฑ์ต้นแบบ',
    };
  },
};
</script>

<style>
  .titleProduct {
    font-size: 30px;
    color: #173B6B;
  }
  .adminLatestUpdate {
    margin-left: 30px;
    color: #28a745;
  }
  .smeLatestUpdate {
    margin-left: 30px;
    color: red;
  }
  #productDetail {
    padding-left: 20px;
  }
  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }
</style>
