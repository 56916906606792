/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import moment from 'moment';
import store from '@/store';
import dashboardAPI from '@/api/dashboard';

const state = {
  latestArticle: [],
  sme: [],
  company: [],
  totalHeritage: 0,
  totalConceptProduct: 0,
  totalProduct: 0,
  totalKnowledge: 0,
  totalArticlePublic: 0,
  totalArticlePrivate: 0,
  totalMedia: 0,
  totalPublication: 0,

  reserveSme: 0,
  remainSme: 0,
  totalSmeMonthTransfer: 0,
  totalFirstform: 0,
  totalSecondform: 0,
  dataFirstformGraph: [],
  dataSecondformGraph: [],
  dataSummaryMoney: [],
  dataSummaryMoneySme: [],
  form1Complete: 0,
  form2Complete: 0,
  showLoading: false,
};

const getters = {
  latestArticle: (state) => state.latestArticle,
  totalHeritage: (state) => state.totalHeritage,
  totalConceptProduct: (state) => state.totalConceptProduct,
  totalProduct: (state) => state.totalProduct,
  totalKnowledge: (state) => state.totalKnowledge,
  totalArticlePublic: (state) => state.totalArticlePublic,
  totalArticlePrivate: (state) => state.totalArticlePrivate,
  totalMedia: (state) => state.totalMedia,
  totalPublication: (state) => state.totalPublication,

  sme: (state) => state.sme,
  company: (state) => state.company,
  reserveSme: (state) => state.reserveSme,
  remainSme: (state) => state.remainSme,
  totalSmeMonthTransfer: (state) => state.totalSmeMonthTransfer,
  totalFirstform: (state) => state.totalFirstform,
  totalSecondform: (state) => state.totalSecondform,
  dataFirstformGraph: (state) => state.dataFirstformGraph,
  dataSecondformGraph: (state) => state.dataSecondformGraph,
  dataSummaryMoney: (state) => state.dataSummaryMoney,
  dataSummaryMoneySme: (state) => state.dataSummaryMoneySme,
  showLoading: (state) => state.showLoading,
  form1Complete: (state) => state.form1Complete,
  form2Complete: (state) => state.form2Complete,
};

const actions = {
  async getDashboardData({
    commit,
  }) {
    commit('SET_LOADING', true);
    const latestArticle = [];
    let totalHeritage = 0;
    let totalConceptProduct = 0;
    let totalProduct = 0;
    let totalKnowledge = 0;
    let totalArticlePublic = 0;
    let totalArticlePrivate = 0;
    let totalMedia = 0;
    let totalPublication = 0;
    const userData = store.getters['User/userData'];
    let params;
    if (userData.role === 'admin') {
      params = {
        is_article: 1,
        '$sort[update_at]': -1,
      };
    } else {
      params = {
        is_article: 1,
        user_id: userData.id,
        '$sort[update_at]': -1,
      };
    }
    const article = await dashboardAPI.latestArticle(params);
    console.log(article);

    let allUser;
    if (userData.role === 'admin') {
      allUser = await dashboardAPI.getAllUser();
      console.log(allUser);
      const heritage = await dashboardAPI.getHeritage();
      const conceptProduct = await dashboardAPI.getConceptProduct();
      const product = await dashboardAPI.getProduct();
      const knowledge = await dashboardAPI.getKnowledge();
      const allKnowledge = knowledge.data;

      for (let i = 0; i < allKnowledge.length; i += 1) {
        if (allKnowledge[i].is_media === 1 && allKnowledge[i].public === 1) {
          totalMedia += 1;
        } else if (allKnowledge[i].is_publication === 1 && allKnowledge[i].public === 1) {
          totalPublication += 1;
        }
      }

      totalHeritage = heritage.total;
      totalConceptProduct = conceptProduct.total;
      totalProduct = product.total;
    }

    const newArticle = article.data;
    for (let i = 0; i < newArticle.length; i += 1) {
      if (userData.role === 'admin') {
        const user = allUser.data;
        for (let j = 0; j < user.length; j += 1) {
          if (newArticle[i].user_id === user[j].id) {
            newArticle[i].owner = user[j].name;
            break;
          }
        }
      }
      if (newArticle[i].public === 1) {
        totalArticlePublic += 1;
      } else {
        totalArticlePrivate += 1;
      }
      const combineArticle = {
        name: newArticle[i].name,
        name_eng: newArticle[i].name_eng,
        owner: newArticle[i].owner,
        public: newArticle[i].public === 1 ? 'เผยแพร่' : 'แบบร่าง',
        create_at: moment(newArticle[i].create_at).format('DD/MM/yyyy, h:mm:ss a'),
        update_at: moment(newArticle[i].update_at).format('DD/MM/yyyy, h:mm:ss a'),
      };
      latestArticle.push(combineArticle);
      totalKnowledge = totalArticlePublic + totalMedia + totalPublication;
    }

    console.log(latestArticle);

    await commit('SET_LATEST_ARTICLE', latestArticle);
    await commit('SET_TOTAL_HERITAGE', totalHeritage);
    await commit('SET_TOTAL_KNOWLEDGE', totalKnowledge);
    await commit('SET_TOTAL_CONCEPT_PRODUCT', totalConceptProduct);
    await commit('SET_TOTAL_PRODUCT', totalProduct);
    await commit('SET_TOTAL_ARTICAL_PUBLIC', totalArticlePublic);
    await commit('SET_TOTAL_ARTICAL_PRIVATE', totalArticlePrivate);
    await commit('SET_TOTAL_MEDIA', totalMedia);
    await commit('SET_TOTAL_PUBLICATION', totalPublication);

    // await commit('SET_RESERVE_SME', totalReserveWithoutCancel);
    // await commit('SET_REMAIN_SME', totalReserveWithoutCancel);
    // await commit('SET_COMPANY', company);
    // await commit('SET_SME', sme.data);
    // await commit('SET_TOTAL_SME_MONTH_TRANSFER', totalSmeMonthTransfer);
    // await commit('SET_TOTAL_FIRSTFORM', totalForm1);
    // await commit('SET_TOTAL_SECONDFORM', totalForm2);
    // await commit('SET_DATA_FIRSTFORM_GRAPH', dataFirstForm);
    // await commit('SET_DATA_SECONDFORM_GRAPH', dataSecondForm);
    // await commit('SET_DATA_SUMMARY_GRAPH', dataSummaryMoney);
    // await commit('SET_DATA_SUMMARY_SME_GRAPH', dataSummaryMoneySme);
    // await commit('SET_FORM1_COMPLETE', form1Complete);
    // await commit('SET_FORM2_COMPLETE', form2Complete);
    commit('SET_LOADING', false);
  },
  clearState({ commit }) {
    commit('CLEAR_STATE');
  },
};

const mutations = {
  SET_LATEST_ARTICLE(state, data) {
    state.latestArticle = data;
  },
  SET_TOTAL_HERITAGE(state, data) {
    state.totalHeritage = data;
  },
  SET_TOTAL_CONCEPT_PRODUCT(state, data) {
    state.totalConceptProduct = data;
  },
  SET_TOTAL_PRODUCT(state, data) {
    state.totalProduct = data;
  },
  SET_TOTAL_KNOWLEDGE(state, data) {
    state.totalKnowledge = data;
  },
  SET_TOTAL_ARTICAL_PUBLIC(state, data) {
    state.totalArticlePublic = data;
  },
  SET_TOTAL_ARTICAL_PRIVATE(state, data) {
    state.totalArticlePrivate = data;
  },
  SET_TOTAL_MEDIA(state, data) {
    state.totalMedia = data;
  },
  SET_TOTAL_PUBLICATION(state, data) {
    state.totalPublication = data;
  },

  SET_COMPANY(state, data) {
    state.company = data;
  },
  SET_SME(state, data) {
    state.sme = data;
  },
  SET_RESERVE_SME(state, data) {
    state.reserveSme = data;
  },
  SET_REMAIN_SME(state, data) {
    state.remainSme = 1000 - data;
  },
  SET_TOTAL_SME_MONTH_TRANSFER(state, data) {
    state.totalSmeMonthTransfer = data;
  },
  SET_TOTAL_FIRSTFORM(state, data) {
    state.totalFirstform = data;
  },
  SET_TOTAL_SECONDFORM(state, data) {
    state.totalSecondform = data;
  },
  SET_DATA_FIRSTFORM_GRAPH(state, data) {
    state.dataFirstformGraph = data;
  },
  SET_DATA_SECONDFORM_GRAPH(state, data) {
    state.dataSecondformGraph = data;
  },
  SET_DATA_SUMMARY_GRAPH(state, data) {
    state.dataSummaryMoney = data;
  },
  SET_DATA_SUMMARY_SME_GRAPH(state, data) {
    state.dataSummaryMoneySme = data;
  },
  SET_LOADING(state, data) {
    state.showLoading = data;
  },
  SET_FORM1_COMPLETE(state, data) {
    state.form1Complete = data;
  },
  SET_FORM2_COMPLETE(state, data) {
    state.form2Complete = data;
  },
  CLEAR_STATE(state) {
    state.latestArticle = [];
    state.totalHeritage = 0;
    state.totalConceptProduct = 0;
    state.totalProduct = 0;
    state.totalKnowledge = 0;
    state.totalArticlePublic = 0;
    state.totalArticlePrivate = 0;
    state.totalMedia = 0;
    state.totalPublication = 0;

    state.sme = [];
    state.company = [];
    state.reserveSme = 0;
    state.remainSme = 0;
    state.totalSmeMonthTransfer = 0;
    state.totalFirstform = 0;
    state.totalSecondform = 0;
    state.dataFirstformGraph = [];
    state.dataSecondformGraph = [];
    state.dataSummaryMoney = [];
    state.dataSummaryMoneySme = [];
    state.showLoading = false;
    state.form1Complete = 0;
    state.form2Complete = 0;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
