var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"nameLabelProduct",style:(_vm.checkNameTh ? 'color:red' : 'color:black'),attrs:{"label-for":"product_name","label-cols-sm":"5"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" ชื่อผลิตภัณฑ์ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"product_name","value":_vm.productDetail.name,"autofocus":_vm.createMode},on:{"change":function($event){return _vm.updateData({name: $event})}}})],1)],1),_c('b-form-group',{attrs:{"label":"แนวคิดจากชุมชน","label-for":"product_concept","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({concept:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.concept),callback:function ($$v) {_vm.concept=$$v},expression:"concept"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ผู้ติดต่อประสานงาน","label-for":"contact","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({contact:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ทรัพย์สินทางปัญญา","label-for":"product_ip","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({product_ip:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.product_ip),callback:function ($$v) {_vm.product_ip=$$v},expression:"product_ip"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"แนวความคิด","label-for":"detail_concept","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_concept:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_concept),callback:function ($$v) {_vm.detail_concept=$$v},expression:"detail_concept"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"รูปแบบการแสดง","label-for":"detail_show","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_show:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_show),callback:function ($$v) {_vm.detail_show=$$v},expression:"detail_show"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"จำนวนนักแสดง","label-for":"detail_number_people","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_number_people:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_number_people),callback:function ($$v) {_vm.detail_number_people=$$v},expression:"detail_number_people"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ความยาวของการแสดง","label-for":"detail_length","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_length:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_length),callback:function ($$v) {_vm.detail_length=$$v},expression:"detail_length"}})],1),_c('br'),_c('br'),_c('br')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }