import AllUsers from '@/views/Users.vue';
import UserDetail from '@/views/UsersDetail.vue';

export default {
  path: 'backoffice/users',
  name: 'All Users',
  redirect: 'backoffice/users/all',
  component: {
    render(h) {
      return h('router-view');
    },
  },
  children: [
    {
      path: 'all',
      name: '     ',
      component: AllUsers,
    },
    {
      path: 'detail/:id',
      name: 'User Details',
      component: UserDetail,
    },
  ],
};
