export default {
  items: [
    {
      id: 1,
      name: 'หน้าหลัก',
      url: '/backoffice/dashboard',
    },
    {
      id: 2,
      name: 'มรดกภูมิปัญญาทางวัฒนธรรม',
      url: '/backoffice/heritage/all',
    },
    {
      id: 3,
      name: 'แนวคิดผลิตภัณฑ์',
      url: '/backoffice/conceptproduct/all',
    },
    {
      id: 4,
      name: 'ผลิตภัณฑ์ต้นแบบ',
      url: '/backoffice/product/all',
    },
    {
      id: 5,
      name: 'มองรู้ดูออก',
      url: '/backoffice/knowledge/all',
    },
    {
      id: 6,
      name: 'รูปภาพและข้อมูลประกอบ',
      url: '/backoffice/detail',
    },
    {
      id: 7,
      name: 'บัญชีผู้ใช้งาน',
      url: '/backoffice/users/all',
    },
    {
      id: 8,
      name: 'รายงาน',
      url: '/backoffice/report',
    },
  ],
};
