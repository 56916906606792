<template>
  <div class="sidebarHeader">
    <img src="@/assets/ichLogo.png" alt="">
  </div>
</template>
<script>
export default {
  name: 'sidebar-header',
};
</script>

<style scoped>
  .sidebarHeader {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .sidebarHeader img {
    width: 150px;
  }
</style>
