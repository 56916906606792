<template>
  <div>
    <b-nav-item-dropdown right no-caret class="headerDropdown">
      <template slot="button-content">
        <img class="img-avatar" :src="userData.profile_img != null ? userData.profile_img
        : require(`@/assets/avatar.png`)" />
      </template>
      <div class="dropdownMenu">
        <b-dropdown-header tag="div" class="text-center">
          <strong>Account</strong>
        </b-dropdown-header>
        <b-dropdown-item @click="isChangePassword = true">Change Password</b-dropdown-item>
        <b-dropdown-item @click="logoutUser" class="btnLogout">Logout</b-dropdown-item>
      </div>

    </b-nav-item-dropdown>

    <b-modal title="Change Password" class="modalChangePassword"
    centered v-model="isChangePassword">
      <b-form-group
        label="รหัสผ่านใหม่"
        label-for="horizPass"
        :label-cols="4"
        :horizontal="true">
        <b-form-input id="horizNewPass" v-model="newPassword" type="password"
        placeholder="รหัสผ่านใหม่"></b-form-input>
      </b-form-group>
      <b-form-group
        label="ยืนยันรหัสผ่านใหม่"
        label-for="horizNewPass2"
        :label-cols="4"
        :horizontal="true">
        <b-form-input id="horizNewPass2" v-model="newPassword2" type="password"
        placeholder="ยืนยันรหัสผ่านใหม่"></b-form-input>
      </b-form-group>
      <p class="errText">{{errorText}}</p>
      <div slot="modal-footer" class="w-100">
        <b-col cols="12" sm="4" offset-sm="4">
          <b-button size="md" class="btnChange" variant="primary" @click="changePassword">
            Change
          </b-button>
        </b-col>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userAPI from '@/api/user';

export default {
  name: 'header-dropdown-accnt',
  data() {
    return {
      errorText: '',
      isChangePassword: false,
      newPassword: '',
      newPassword2: '',
    };
  },
  computed: {
    ...mapGetters('User', ['userData']),
  },
  methods: {
    ...mapActions('User', ['logout']),
    async logoutUser() {
      await this.logout();
      this.$router.push('/backoffice/login');
    },
    async changePassword() {
      if (this.newPassword.trim().length === 0 && this.newPassword2.trim().length === 0) {
        this.newPassword = '';
        this.newPassword2 = '';
        this.errorText = 'กรุณาพิมพ์รหัสผ่าน';
      } else {
        if (this.newPassword !== this.newPassword2) {
          this.newPassword = '';
          this.newPassword2 = '';
          this.errorText = 'รหัสไม่ตรงกัน';
          return;
        }
        const isChangePasswordSuccess = await userAPI.changePassword(this.userData.id,
          this.newPassword);
        if (isChangePasswordSuccess === '' && isChangePasswordSuccess !== undefined) {
          this.logoutUser();
        } else {
          this.newPassword = '';
          this.newPassword2 = '';
          this.isChangePassword = false;
        }
      }
    },
  },
};
</script>

<style>
  .errText {
    color: red;
    text-align: center;
  }
  .btnChange {
    margin-right: 30px;
    color: #FFF200!important;
    background-color: #532ce0!important;
  }
</style>
