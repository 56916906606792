<template>
  <div>
    <b-row>
      <b-col md = "6">
        <b-form-group label="seo og title" label-for="systemOGTitle" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemOGTitle" :value="systemDetail[0].detail"
            @change="updateData(0, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="seo og description" label-for="systemOGDescription" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemOGDescription"
            :value="systemDetail[1].detail" @change="updateData(1, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="seo og image" label-for="systemOGImage" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemOGImage" :value="systemDetail[2].detail"
              @change="updateData(2, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="social url facebook" label-for="systemSocialFB" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemSocialFB" :value="systemDetail[3].detail"
              @change="updateData(3, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="social url instagram" label-for="systemSocialIG" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemSocialIG" :value="systemDetail[4].detail"
              @change="updateData(4, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="social url youtube" label-for="systemSocialYT" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemSocialYT" :value="systemDetail[5].detail"
              @change="updateData(5, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="social url twitter" label-for="systemSocialTW" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="systemSocialTW" :value="systemDetail[6].detail"
              @change="updateData(6, $event)">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('Detail', ['systemDetail']),
  },
  methods: {
    ...mapActions('Detail', ['updateSystemField']),
    updateData(arrPointer, data) {
      console.log(data);
      const updateData = {
        id: this.systemDetail[arrPointer].id,
        name: this.systemDetail[arrPointer].name,
        detail: data,
      };
      this.updateSystemField(updateData);
    },
  },
};
</script>
