/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  getBannerMedia(params) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/banner-media',
        params,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getSystemDetail() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/system-detail',
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  updateBannerMedia(id, bannerUrl) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/banner-media/${id}`,
        data: {
          url: bannerUrl,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  updateSystemDetail(id, systemDetail) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/system-detail/${id}`,
        data: {
          detail: systemDetail,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
