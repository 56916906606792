<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label-for="heritage_name"
        label-cols-sm="5" class="nameHeigth" :style="checkNameEng ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อมรดกภูมิปัญญาทางวัฒนธรรมภาษาอังกฤษ <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="heritage_name_eng" :value="heritageDetail.name_eng"
            @change="updateData({name_eng: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="ชื่อเรียกในท้องถิ่นภาษาอังกฤษ" label-for="heritage_name_local_eng"
         label-cols-sm="5" class="nameHeigth">
          <b-input-group>
            <b-form-input type="text" name="heritage_name_local_eng"
            :value="heritageDetail.name_local_eng"
              @change="updateData({name_local_eng: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="ตัวแทนผู้สืบทอดภาษาอังกฤษ" label-for="inheritor_eng" label-cols-sm="5">
          <quill-editor v-model="inheritor_eng" :options="editorOption" class="editorHeight"
          @change="updateData({inheritor_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ข้อมูลทางภูมิศาสตร์ภาษาอังกฤษ" label-for="geography_eng"
        label-cols-sm="5">
          <quill-editor v-model="geography_eng" :options="editorOption" class="editorHeight"
          @change="updateData({geography_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ติดต่อประสานงานภาษาอังกฤษ" label-for="contact_eng"
        label-cols-sm="5">
          <quill-editor v-model="contact_eng" :options="editorOption" class="editorHeight"
          @change="updateData({contact_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="สาระสำคัญของมรดกภูมิปัญญาทางวัฒนธรรมภาษาอังกฤษ"
        label-for="descriptor_detail_eng" label-cols-sm="5">
          <quill-editor v-model="descriptor_detail_eng" :options="editorOption" class="editorHeight"
          @change="updateData({descriptor_detail_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การสนับสนุนเพื่อรับรองมรดกภูมิปัญญาทางวัฒนธรรมจะได้รับรู้
        ตระหนักและกล่าวถึงอย่างต่อเนื่องภาษาอังกฤษ" label-for="support_detail_eng"
        label-cols-sm="5">
          <quill-editor v-model="support_detail_eng" :options="editorOption" class="editorHeight"
          @change="updateData({support_detail_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การขึ้นทะเบียนมรดกภูมิปัญญาทางวัฒนธรรมภาษาอังกฤษ"
        label-for="support_register_eng" label-cols-sm="5">
          <quill-editor v-model="support_register_eng" :options="editorOption" class="editorHeight"
          @change="updateData({support_register_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

         <b-form-group label="มาตรการในการป้องกันมรดกภูมิปัญญาทางวัฒนธรรมภาษาอังกฤษ"
         label-for="protection_detail_eng" label-cols-sm="5">
          <quill-editor v-model="protection_detail_eng" :options="editorOption" class="editorHeight"
          @change="updateData({protection_detail_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ความต้องการมาตรการในการปกป้องภาษาอังกฤษ"
        label-for="protection_need_eng" label-cols-sm="5">
          <quill-editor v-model="protection_need_eng" :options="editorOption" class="editorHeight"
          @change="updateData({protection_need_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ชุมชน กลุ่มคน/บุคคลภาษาอังกฤษ" label-for="protection_people_eng"
        label-cols-sm="5">
          <quill-editor v-model="protection_people_eng" :options="editorOption" class="editorHeight"
          @change="updateData({protection_people_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ภาครัฐ หน่วยงานภาคสังคมภาษาอังกฤษ"
        label-for="protection_government_eng" label-cols-sm="5">
          <quill-editor v-model="protection_government_eng" :options="editorOption"
          class="editorHeight" @change="updateData({protection_government_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})">
          </quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ผู้ที่มีส่วนเกี่ยวข้องภาษาอังกฤษ" label-for="protection_other_eng"
        label-cols-sm="5">
          <quill-editor v-model="protection_other_eng" :options="editorOption" class="editorHeight"
          @change="updateData({protection_other_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การมีส่วนร่วมของชุมชนที่มีต่อมรดกภูมิปัญญาทางวัฒนธรรมภาษาอังกฤษ"
        label-for="participation_heritage_eng" label-cols-sm="5">
          <quill-editor v-model="participation_heritage_eng" :options="editorOption"
          class="editorHeight"
          @change="updateData({participation_heritage_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="หน่วยงานในระดับชุมชนภาษาอังกฤษ"
        label-for="participation_contact_eng" label-cols-sm="5">
          <quill-editor v-model="participation_contact_eng" :options="editorOption"
          class="editorHeight" @change="updateData({participation_contact_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})">
          </quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="มาตรการในการส่งเสริมและการรักษามรดกภูมิปัญญาทางวัฒนธรรมภาษาอังกฤษ"
        label-for="treatment_detail_eng" label-cols-sm="5">
          <quill-editor v-model="treatment_detail_eng" :options="editorOption" class="editorHeight"
          @change="updateData({treatment_detail_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="การสืบสานและถ่ายทอด (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา
        และงบประมาณ) ภาษาอังกฤษ"
        label-for="treatment_relay_eng" label-cols-sm="5">
          <quill-editor v-model="treatment_relay_eng" :options="editorOption" class="editorHeight"
          @change="updateData({treatment_relay_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="หมายเหตุภาษาอังกฤษ" label-for="note_eng" label-cols-sm="5">
          <quill-editor v-model="note_eng" :options="editorOption" class="editorHeight"
          @change="updateData({note_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
        </b-form-group>
        <br><br><br>

        <b-form-group label="ไฟล์ .pdf ภาษาอังกฤษ" label-for="file_pdf_eng" label-cols-sm="5"
        class="uploadLabel">
          <b-button variant="warning" @click="openFile(heritageDetail.file_pdf_eng)"
          v-if="heritageDetail.file_pdf_eng" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadPdfEng" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadPdfEng" type="file" ref="pdfFile_eng" accept=".pdf"
          @change="uploadPdfFileEng()"/>
        </b-form-group>

        <b-form-group label="ไฟล์ .docx ภาษาอังกฤษ" label-for="file_word" label-cols-sm="5">
          <b-button variant="warning" @click="openFile(heritageDetail.file_word_eng)"
          v-if="heritageDetail.file_word_eng" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadWordEng" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadWordEng" type="file" ref="wordFile_eng" accept=".docx, .doc"
          @change="uploadWordFileEng()"/>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Heritage', ['heritageDetail', 'editorOption', 'checkNameEng']),
    HeritageData() {
      return this.heritageDetail;
    },
  },
  watch: {
    HeritageData() {
      this.inheritor_eng = this.heritageDetail.inheritor_eng;
      this.geography_eng = this.heritageDetail.geography_eng;
      this.contact_eng = this.heritageDetail.contact_eng;
      this.descriptor_detail_eng = this.heritageDetail.descriptor_detail_eng;
      this.support_detail_eng = this.heritageDetail.support_detail_eng;
      this.support_register_eng = this.heritageDetail.support_register_eng;
      this.protection_detail_eng = this.heritageDetail.protection_detail_eng;
      this.protection_need_eng = this.heritageDetail.protection_need_eng;
      this.protection_people_eng = this.heritageDetail.protection_people_eng;
      this.protection_government_eng = this.heritageDetail.protection_government_eng;
      this.protection_other_eng = this.heritageDetail.protection_other_eng;
      this.participation_heritage_eng = this.heritageDetail.participation_heritage_eng;
      this.participation_contact_eng = this.heritageDetail.participation_contact_eng;
      this.treatment_detail_eng = this.heritageDetail.treatment_detail_eng;
      this.treatment_relay_eng = this.heritageDetail.treatment_relay_eng;
      this.note_eng = this.heritageDetail.note_eng;
    },
  },
  data() {
    return {
      inheritor_eng: '',
      geography_eng: '',
      contact_eng: '',
      descriptor_detail_eng: '',
      support_detail_eng: '',
      support_register_eng: '',
      protection_detail_eng: '',
      protection_need_eng: '',
      protection_people_eng: '',
      protection_government_eng: '',
      protection_other_eng: '',
      participation_heritage_eng: '',
      participation_contact_eng: '',
      treatment_detail_eng: '',
      treatment_relay_eng: '',
      note_eng: '',
      pdfFile_eng: null,
      wordFile_eng: null,
    };
  },
  methods: {
    ...mapActions('Heritage', ['updateField', 'setLoading']),
    openFile(data) {
      window.open(data, '_blank');
    },
    async uploadPdfFileEng() {
      this.setLoading(true);
      if (this.$refs.pdfFile_eng.files[0]) {
        const file = this.$refs.pdfFile_eng.files[0];
        const { name, type: contentType, size } = file;
        console.log('File', name, contentType, size);
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_pdf_eng: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadWordFileEng() {
      this.setLoading(true);
      if (this.$refs.wordFile_eng.files[0]) {
        const file = this.$refs.wordFile_eng.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_word_eng: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedHeritageDetail', this.heritageDetail);
    },
  },
};
</script>

<style scope>
  .editorHeight {
    height: 200px;
  }
  .nameHeigth {
    height: 65px;
  }
  input[type="file"] {
    display: none;
  }
  .labelBtnOpenFile {
    margin-right: 20px;
  }
  .labelBtnUploadFile {
    color: #FFF200!important;
    background-color: #532ce0!important;
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #cccccc;
    color: #666666;
  }
  .uploadLabel {
    height: 60px;
  }
</style>
