/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  getHeritage() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage',
        params: {
          $limit: 50000,
          $skip: 0,
          '$sort[update_at]': -1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritageCategory() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage-category',
        params: {
          $limit: 5000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getArea() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/area',
        params: {
          $limit: 5000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getAreaHerirageCategory() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/area-heritage-category',
        params: {
          $limit: 50000,
          $skip: 0,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritageById(id) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/heritage/${id}`,
        params: {
          backoffice: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  createHeritage(heritage) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/heritage',
        data: {
          ...heritage,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  updateHeritage(id, heritage) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/heritage/${id}`,
        data: {
          ...heritage,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  deleteHeritage(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/heritage/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  createHeritageMedia(media) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/heritage-media',
        data: {
          ...media,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  patchHeritageMedia(id, media) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/heritage-media/${id}`,
        data: {
          ...media,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  deleteHeritageMedia(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/heritage-media/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritageVdo(heritageId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage-media',
        params: {
          heritage_id: heritageId,
          is_vdo: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritageSub(heritageId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage-media',
        params: {
          heritage_id: heritageId,
          is_subtitle: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getHeritagePicture(heritageId) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/heritage-media',
        params: {
          heritage_id: heritageId,
          is_picture: 1,
          $limit: 50000,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
