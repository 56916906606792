/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  login(email, password) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/authentication',
        data: {
          strategy: 'local',
          email,
          password,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  registerAccount(email, name, password) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/users',
        data: {
          email,
          name,
          password,
          profile_img: null,
          role: 'admin',
          policy: false,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getUser(data) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/users/${data.id}`,
        params: {
          id: data.id,
          timestamp: data.timestamp,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getAllUser(params) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/users',
        params,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getUserDetail(id) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/users/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getUserDetailFromFrontend(id) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/users/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  updateUserDetail(id, user) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/users/${id}`,
        data: {
          ...user,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  deleteUser(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/users/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  changePassword(id, password) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/users/${id}`,
        data: {
          password,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
