<template>
  <ol class="breadcrumb">
    <li class="breadcrumb-item" v-for="(item, index) in list" :key="index">
      <span class="active" v-if="isLast(index)">{{ showName(item) }}</span>
      <router-link :to="item" v-else>{{ showName(item) }}</router-link>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    isLast(index) {
      return index === this.list.length - 1;
    },
    showName(item) {
      if (item.meta && item.meta.label) {
        // eslint-disable-next-line no-param-reassign
        item = item.meta && item.meta.label;
      }
      if (item.name) {
        // eslint-disable-next-line no-param-reassign
        item = item.name;
      }
      return item;
    },
  },
};
</script>
<style scoped>
  a {
    color: #532ce0 !important;
  }
  .breadcrumb {
    background-color: #f2f2f2;
    border-radius: 0;
  }
</style>
