/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import detailAPI from '@/api/detail';
import _ from 'lodash';

const state = {
  bannerMedia: [],
  systemDetail: [],
  showLoading: false,
  newBannerMedia: [],
  newSystemDetail: [],
  resultTextBanner: '',
  resultTextBannerClass: false,
  resultTextSystem: '',
  resultTextSystemClass: false,
};

const getters = {
  bannerMedia: (state) => state.bannerMedia,
  systemDetail: (state) => state.systemDetail,
  showLoading: (state) => state.showLoading,
  newBannerMedia: (state) => state.newBannerMedia,
  resultTextBanner: (state) => state.resultTextBanner,
  resultTextBannerClass: (state) => state.resultTextBannerClass,
  resultTextSystem: (state) => state.resultTextSystem,
  resultTextSystemClass: (state) => state.resultTextSystemClass,
};

const actions = {
  async getAllDetail({
    commit,
  }) {
    commit('SET_SHOW_LOADING', true);
    const params = {
      $limit: 5000,
    };
    const allBannerMedia = await detailAPI.getBannerMedia(params);
    const allSystemDetail = await detailAPI.getSystemDetail();
    await commit('SET_BANNER_MEDIA', allBannerMedia.data);
    await commit('SET_SYSTEM_DETAIL', allSystemDetail.data);
    commit('SET_SHOW_LOADING', false);
  },
  async updateSystemDetailData({
    commit,
    state,
  }) {
    commit('SET_SHOW_LOADING', true);
    commit('SET_RESULT_TEXT_BANNER', false);
    commit('SET_RESULT_TEXT_BANNER_CLASS', false);
    commit('SET_RESULT_TEXT_SYSTEM', false);
    commit('SET_RESULT_TEXT_SYSTEM_CLASS', false);
    const updateBanner = _.cloneDeep(state.newBannerMedia);
    const updateSystemDetail = _.cloneDeep(state.newSystemDetail);

    let resultTextBanner = '';

    if (updateBanner.length > 0) {
      const newMultiFile = [];
      for (let i = 0; i < updateBanner.length; i += 1) {
        const multiFile = {
          id: updateBanner[i].id,
          url: updateBanner[i].url,
        };
        newMultiFile.push(multiFile);
      }

      const promises = newMultiFile.map((val) => detailAPI.updateBannerMedia(val.id, val.url)
        .then((results) => {
          console.log(results);
          return results;
        }));
      Promise.all(promises).then(async (results) => {
        console.log('result', results);
        if (results.includes(undefined)) {
          resultTextBanner = 'อัปเดต Banner ไม่สำเร็จ';
          commit('SET_RESULT_TEXT_BANNER', resultTextBanner);
          commit('SET_RESULT_TEXT_BANNER_CLASS', false);
        } else {
          resultTextBanner = 'อัปเดต Banner สำเร็จ';
          commit('SET_RESULT_TEXT_BANNER', resultTextBanner);
          commit('SET_RESULT_TEXT_BANNER_CLASS', true);
        }
      });
    } else {
      resultTextBanner = 'อัปเดต Banner สำเร็จ';
      commit('SET_RESULT_TEXT_BANNER', resultTextBanner);
      commit('SET_RESULT_TEXT_BANNER_CLASS', true);
    }

    let resultTextSystem = '';
    if (updateSystemDetail.length > 0) {
      const newMultiFile = [];
      for (let i = 0; i < updateSystemDetail.length; i += 1) {
        const multiFile = {
          id: updateSystemDetail[i].id,
          detail: updateSystemDetail[i].detail,
        };
        newMultiFile.push(multiFile);
      }

      const promises = newMultiFile.map((val) => detailAPI.updateSystemDetail(val.id, val.detail)
        .then((results) => {
          console.log(results);
          return results;
        }));
      Promise.all(promises).then(async (results) => {
        console.log('result', results);
        if (results.includes(undefined)) {
          resultTextSystem = 'อัปเดตข้อมูลประกอบไม่สำเร็จ';
          commit('SET_RESULT_TEXT_SYSTEM', resultTextSystem);
          commit('SET_RESULT_TEXT_SYSTEM_CLASS', false);
        } else {
          resultTextSystem = 'อัปเดตข้อมูลประกอบสำเร็จ';
          commit('SET_RESULT_TEXT_SYSTEM', resultTextSystem);
          commit('SET_RESULT_TEXT_SYSTEM_CLASS', true);
        }
      });
    } else {
      resultTextSystem = 'อัปเดตข้อมูลประกอบสำเร็จ';
      commit('SET_RESULT_TEXT_SYSTEM', resultTextSystem);
      commit('SET_RESULT_TEXT_SYSTEM_CLASS', true);
    }

    commit('SET_NEW_BANNER_MEDIA', []);
    commit('SET_NEW_SYSTEM_DETAIL', []);
    commit('SET_SHOW_LOADING', false);
    console.log('banner');
  },
  updateField({
    commit,
    state,
  }, data) {
    const fullBannerMedia = _.cloneDeep(state.bannerMedia);
    console.log('update field');

    for (let i = 0; i < fullBannerMedia.length; i += 1) {
      if (fullBannerMedia[i].id === data.id) {
        fullBannerMedia[i].url = data.url;
        break;
      }
    }

    const newBanner = _.cloneDeep(state.newBannerMedia);
    let duplicate = false;
    for (let i = 0; i < newBanner.length; i += 1) {
      if (newBanner[i].id === data.id) {
        newBanner[i].url = data.url;
        duplicate = true;
        break;
      }
    }
    if (!duplicate) {
      newBanner.push(data);
    }
    console.log('newBanner', newBanner);
    console.log('banner', fullBannerMedia);
    commit('SET_BANNER_MEDIA', fullBannerMedia);
    commit('SET_NEW_BANNER_MEDIA', newBanner);
  },
  updateSystemField({
    commit,
    state,
  }, data) {
    const fullSystemDetail = _.cloneDeep(state.systemDetail);
    console.log('update system field');

    for (let i = 0; i < fullSystemDetail.length; i += 1) {
      if (fullSystemDetail[i].id === data.id) {
        fullSystemDetail[i].detail = data.detail;
        break;
      }
    }

    const newDetail = _.cloneDeep(state.newSystemDetail);
    let duplicate = false;
    for (let i = 0; i < newDetail.length; i += 1) {
      if (newDetail[i].id === data.id) {
        newDetail[i].detail = data.detail;
        duplicate = true;
        break;
      }
    }
    if (!duplicate) {
      newDetail.push(data);
    }
    console.log('newDetail', newDetail);
    console.log('systemDetail', fullSystemDetail);
    commit('SET_SYSTEM_DETAIL', fullSystemDetail);
    commit('SET_NEW_SYSTEM_DETAIL', newDetail);
  },
  setShowLoading({
    commit,
  }, data) {
    commit('SET_SHOW_LOADING', data);
  },
  clearState({
    commit,
  }) {
    commit('CLEAR_STATE');
  },
};

const mutations = {
  SET_BANNER_MEDIA(state, data) {
    state.bannerMedia = data;
  },
  SET_SYSTEM_DETAIL(state, data) {
    state.systemDetail = data;
  },
  SET_SHOW_LOADING(state, data) {
    state.showLoading = data;
  },
  CLEAR_STATE(state) {
    state.showLoading = false;
    state.newBannerMedia = [];
    state.newSystemDetail = [];
    state.resultTextBanner = '';
    state.resultTextBannerClass = false;
    state.resultTextSystem = '';
    state.resultTextSystemClass = false;
  },
  SET_NEW_BANNER_MEDIA(state, data) {
    state.newBannerMedia = data;
  },
  SET_NEW_SYSTEM_DETAIL(state, data) {
    state.newSystemDetail = data;
  },
  SET_RESULT_TEXT_BANNER(state, data) {
    state.resultTextBanner = data;
  },
  SET_RESULT_TEXT_BANNER_CLASS(state, data) {
    state.resultTextBannerClass = data;
  },
  SET_RESULT_TEXT_SYSTEM(state, data) {
    state.resultTextSystem = data;
  },
  SET_RESULT_TEXT_SYSTEM_CLASS(state, data) {
    state.resultTextSystemClass = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
