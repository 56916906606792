<template>
  <div id="allDetail">
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <p class="titleDetailProp">รูปภาพ Banner</p>
    <BannerMedia></BannerMedia>
    <br>
    <br>
    <p class="titleDetailProp">ข้อมูลประกอบ</p>
    <SystemDetail></SystemDetail>

    <p :class="resultTextBannerClass ? 'resultSuccess' : 'resultFail'" v-if="resultTextBanner">
      {{resultTextBanner}}
    </p>
    <p :class="resultTextSystemClass ? 'resultSuccess' : 'resultFail'" v-if="resultTextSystem">
      {{resultTextSystem}}
    </p>
    <b-button variant="success" class="btnForm1Detail"
    @click="confirmUpdateSystemDetailModal=true">
      บันทึกข้อมูล
    </b-button>
    <b-modal :title="titleUpdateSystemDetailModal"
    id="confirmUpdateSystemDetailModal" centered class="modal-primary"
    v-model="confirmUpdateSystemDetailModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmUpdateSystemDetailModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="updateSystemDetail">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import userAPI from '@/api/user';
import BannerMedia from '../components/Detail/BannerMedia.vue';
import SystemDetail from '../components/Detail/SystemDetail.vue';

export default {
  components: {
    BannerMedia,
    SystemDetail,
  },
  computed: {
    ...mapGetters('Detail', ['systemDetail', 'showLoading', 'resultTextBanner', 'resultTextBannerClass',
      'resultTextSystem', 'resultTextSystemClass']),
    ...mapGetters('User', ['userData']),
    SystemDetailData() {
      return this.systemDetail;
    },
  },
  async created() {
    if (this.userData.role === 'admin') {
      const checkToken = await userAPI.getUser(this.userData);
      console.log('checkToken', checkToken);
    }
  },

  watch: {
    SystemDetailData() {
      this.titleUpdateSystemDetailModal = 'คุณแน่ใจใช่ไหมที่แก้ไขข้อมูล';
    },
  },

  data() {
    return {
      confirmUpdateSystemDetailModal: false,
      titleUpdateSystemDetailModal: '',
      resultText: '',
      resultTextClass: '',
    };
  },

  methods: {
    ...mapActions('Detail', ['updateSystemDetailData']),
    async updateSystemDetail() {
      this.confirmUpdateSystemDetailModal = false;
      await this.updateSystemDetailData();
      console.log('updateRes');
    },
  },
};

</script>

<style scope>
  .titleDetailProp {
    font-size: 30px;
    color: #173B6B;
  }

  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }

  #allDetail {
    padding-left: 20px;
    padding-right: 20px;
  }

  .btnForm1Detail {
    float: right;
    margin: 0 20px 20px 0;
  }

  .resultSuccess {
    color: #28a745;
    font-size: 20px;
  }
  .resultFail {
    color: red;
    font-size: 20px;
  }
</style>
