/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import knowledgeAPI from '@/api/knowledge';
import store from '@/store';
import _ from 'lodash';

const state = {
  tableData: [],
  listKnowledge: [],
  selectedPublic: 'All',
  listPublicType: ['All', 'แบบร่าง', 'เผยแพร่'],
  selectedUser: 'All',
  listUser: [],
  selectedCategory: 'All',
  listCategoryName: [],
  selectedMediaType: 'All',
  listMediaType: ['All', 'บทความ', 'สื่อมีเดีย', 'สิ่งพิมพ์'],
  showLoading: false,
  knowledgeDetail: {},
  createMode: false,
  listCategory: [],
  userName: '',
  checkNameTh: false,
  checkNameEng: false,
};

const getters = {
  listKnowledge: (state) => state.listKnowledge,
  selectedPublic: (state) => state.selectedPublic,
  listPublicType: (state) => state.listPublicType,
  selectedUser: (state) => state.selectedUser,
  listUser: (state) => state.listUser,
  selectedCategory: (state) => state.selectedCategory,
  listCategoryName: (state) => state.listCategoryName,
  selectedMediaType: (state) => state.selectedMediaType,
  listMediaType: (state) => state.listMediaType,
  knowledgeDetail: (state) => state.knowledgeDetail,
  showLoading: (state) => state.showLoading,
  createMode: (state) => state.createMode,
  listCategory: (state) => state.listCategory,
  userName: (state) => state.userName,
  checkNameTh: (state) => state.checkNameTh,
  checkNameEng: (state) => state.checkNameEng,
};

const actions = {
  async getData({
    commit,
  }) {
    commit('SET_SHOW_LOADING', true);
    commit('SET_LIST_KNOWLEDGE', []);
    commit('SET_LIST_USER', []);
    let params;
    const userData = store.getters['User/userData'];
    let adminRole;
    let allUser;
    let users;
    if (userData.role === 'admin') {
      params = {
        $limit: 50000,
        $skip: 0,
        '$sort[update_at]': -1,
      };
      adminRole = true;
      allUser = await knowledgeAPI.getUser();
      users = allUser.data;
      const listUser = [];
      listUser.push('All');
      for (let i = 0; i < users.length; i += 1) {
        listUser.push(users[i].name);
      }
      await commit('SET_LIST_USER', listUser);
    } else {
      params = {
        $limit: 50000,
        $skip: 0,
        '$sort[update_at]': -1,
        user_id: userData.id,
      };
      adminRole = false;
    }
    const allKnowledge = await knowledgeAPI.getKnowledge(params);
    const allKnowledgeCategory = await knowledgeAPI.getKnowledgeCategory();

    const listKnowledge = [];

    const knowledges = allKnowledge.data;
    const knowledgeCategories = allKnowledgeCategory.data;

    for (let i = 0; i < knowledges.length; i += 1) {
      let category = '-';
      for (let j = 0; j < knowledgeCategories.length; j += 1) {
        if (knowledges[i].category_id === knowledgeCategories[j].id) {
          category = knowledgeCategories[j].name;
          break;
        }
      }

      let type = '-';
      if (knowledges[i].is_article) {
        type = 'บทความ';
      } else if (knowledges[i].is_media) {
        type = 'สื่อมีเดีย';
      } else if (knowledges[i].is_publication) {
        type = 'สิ่งพิมพ์';
      }

      let publicText;
      if (knowledges[i].public === 0) {
        publicText = 'แบบร่าง';
      } else {
        publicText = 'เผยแพร่';
      }

      const combineKnowledge = {
        name: knowledges[i].name,
        category_id: category,
        type_id: type,
        public: publicText,
        view: knowledges[i].view,
        create_at: knowledges[i].create_at,
        update_at: knowledges[i].update_at,
        id: knowledges[i].id,
      };

      if (adminRole) {
        let userName = '-';
        for (let j = 0; j < users.length; j += 1) {
          if (knowledges[i].user_id === users[j].id) {
            userName = users[j].name;
            break;
          }
        }
        _.extend(combineKnowledge, { user_id: userName });
      }

      listKnowledge.push(combineKnowledge);
    }

    const listCategoryName = [];
    listCategoryName.push('All');
    for (let i = 0; i < knowledgeCategories.length; i += 1) {
      listCategoryName.push(knowledgeCategories[i].name);
    }
    await commit('SET_LIST_CATEGORY_NAME', listCategoryName);
    await commit('SET_LIST_KNOWLEDGE', listKnowledge);
    await commit('SET_DATA', listKnowledge);
    await commit('UPDATE_TABLE', listKnowledge);
    commit('SET_SHOW_LOADING', false);
  },
  async getKnowledgeDetail({
    commit,
    dispatch,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    let knowledgeDetail;
    if (id === 'create') {
      knowledgeDetail = {
        name: '',
        name_eng: '',
        is_article: true,
        is_media: false,
        is_publication: false,
        profile_img: null,
        banner_img: null,
        file_pdf: null,
        file_word: null,
        file_pdf_eng: null,
        file_word_eng: null,
        sub_path: null,
        sub_eng_path: null,
        public: 0,
        category_id: 1,
      };

      commit('SET_CREATE_MODE', true);
    } else {
      knowledgeDetail = await knowledgeAPI.getKnowledgeById(id);
      const userData = store.getters['User/userData'];
      if (userData.role === 'admin') {
        const user = await knowledgeAPI.getUserById(knowledgeDetail.user_id);
        await commit('SET_USER_NAME', user.name);
      }
      commit('SET_CREATE_MODE', false);
    }
    await commit('SET_KNONWLEDGE_DETAIL', knowledgeDetail);
    await dispatch('getAllOptions');
    commit('SET_SHOW_LOADING', false);
  },
  async getAllOptions({
    commit,
  }) {
    const allCategory = await knowledgeAPI.getKnowledgeCategory();

    await commit('SET_LIST_CATEGORY', allCategory.data);
  },
  async updateKnowledgeDetail({
    commit,
    state,
  }) {
    commit('SET_SHOW_LOADING', true);
    const updateDetail = state.knowledgeDetail;
    const timeNow = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const updateKnowledge = {
      name: updateDetail.name,
      tag: updateDetail.tag,
      category_id: updateDetail.category_id,
      is_article: updateDetail.is_article,
      is_media: updateDetail.is_media,
      is_publication: updateDetail.is_publication,
      detail: updateDetail.detail,
      profile_img: updateDetail.profile_img,
      banner_img: updateDetail.banner_img,
      public: updateDetail.public,
      keyword: updateDetail.keyword,
      name_eng: updateDetail.name_eng,
      tag_eng: updateDetail.tag_eng,
      detail_eng: updateDetail.detail_eng,
      update_at: timeNow,
    };
    if (updateDetail.is_article) {
      const article = {
        file_pdf: null,
        file_word: null,
        sub_path: null,
        file_pdf_eng: null,
        file_word_eng: null,
        sub_eng_path: null,
      };
      _.extend(updateKnowledge, article);
    } else if (updateDetail.is_media) {
      const media = {
        file_pdf: null,
        file_word: null,
        sub_path: updateDetail.sub_path,
        file_pdf_eng: null,
        file_word_eng: null,
        sub_eng_path: updateDetail.sub_eng_path,
      };
      _.extend(updateKnowledge, media);
    } else if (updateDetail.is_publication) {
      const publication = {
        file_pdf: updateDetail.file_pdf,
        file_word: updateDetail.file_word,
        sub_path: null,
        file_pdf_eng: updateDetail.file_pdf_eng,
        file_word_eng: updateDetail.file_word_eng,
        sub_eng_path: null,
      };
      _.extend(updateKnowledge, publication);
    }

    let updateRes;
    if (state.createMode) {
      const userId = store.getters['User/userData'].id;
      _.extend(updateKnowledge, { user_id: userId }, { create_at: timeNow }, { view: 0 });
      console.log('updateKnowledge', updateKnowledge);

      updateRes = await knowledgeAPI.createKnowledge(updateKnowledge);
    } else {
      const knowledgeId = state.knowledgeDetail.id;
      console.log('updateKnowledge', updateKnowledge);

      updateRes = await knowledgeAPI.updateKnowledge(knowledgeId, updateKnowledge);
    }

    if (updateRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  async deleteKnowledge({
    commit,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    const delRes = await knowledgeAPI.deleteKnowledge(id);

    if (delRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  setLoading({
    commit,
  }, data) {
    commit('SET_SHOW_LOADING', data);
  },
  setSelectedUser({
    commit,
  }, data) {
    commit('SET_SELECTED_USER', data);
  },
  setSelectedPublic({
    commit,
  }, data) {
    commit('SET_SELECTED_PUBLIC', data);
  },
  setSelectedCategory({
    commit,
  }, data) {
    commit('SET_SELECTED_CATEGORY', data);
  },
  setSelectedMediaType({
    commit,
  }, data) {
    commit('SET_SELECTED_MEDIA_TYPE', data);
  },
  filterCustom({
    commit,
  }, payload) {
    commit('SET_CUSTOM_FILTER', payload);
  },
  setCheckNameTh({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_TH', data);
  },
  setCheckNameEng({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_ENG', data);
  },
  updateField({
    commit,
  }, data) {
    Object.keys(data).forEach((key) => {
      commit('UPDATE_FIELD', { [key]: data[key] });
    });
  },
  clearState({
    commit,
  }) {
    commit('CLEAR_STATE');
  },
};

const mutations = {
  UPDATE_TABLE(state, data) {
    state.data = data;
  },
  SET_DATA(state, data) {
    state.tableData = data;
  },
  SET_LIST_KNOWLEDGE(state, data) {
    state.listKnowledge = data;
  },
  SET_SELECTED_PUBLIC(state, data) {
    state.selectedPublic = data;
  },
  SET_SHOW_LOADING(state, data) {
    state.showLoading = data;
  },
  SET_SELECTED_USER(state, data) {
    state.selectedUser = data;
  },
  SET_LIST_USER(state, data) {
    state.listUser = data;
  },
  SET_SELECTED_CATEGORY(state, data) {
    state.selectedCategory = data;
  },
  SET_LIST_CATEGORY_NAME(state, data) {
    state.listCategoryName = data;
  },
  SET_SELECTED_MEDIA_TYPE(state, data) {
    state.selectedMediaType = data;
  },
  SET_KNONWLEDGE_DETAIL(state, data) {
    state.knowledgeDetail = data;
  },
  SET_CREATE_MODE(state, data) {
    state.createMode = data;
  },
  UPDATE_FIELD(state, data) {
    Object.assign(state.knowledgeDetail, data);
  },
  SET_LIST_CATEGORY(state, data) {
    state.listCategory = data;
  },
  SET_USER_NAME(state, data) {
    state.userName = data;
  },
  SET_CHECK_NAME_TH(state, data) {
    state.checkNameTh = data;
  },
  SET_CHECK_NAME_ENG(state, data) {
    state.checkNameEng = data;
  },
  CLEAR_STATE(state) {
    state.selectedPublic = 'All';
    state.selectedUser = 'All';
    state.selectedCategory = 'All';
    state.selectedMediaType = 'All';
    state.showLoading = false;
    state.knowledgeDetail = {};
    state.createMode = false;
    state.userName = '';
    state.checkNameTh = false;
    state.checkNameEng = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
