var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"nameHeigth",style:(_vm.checkNameTh ? 'color:red' : 'color:black'),attrs:{"label-for":"heritage_name","label-cols-sm":"5"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" ชื่อมรดกภูมิปัญญาทางวัฒนธรรม "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"heritage_name","value":_vm.heritageDetail.name,"autofocus":_vm.createMode},on:{"change":function($event){return _vm.updateData({name: $event})}}})],1)],1),_c('b-form-group',{staticClass:"nameHeigth",attrs:{"label":"ชื่อเรียกในท้องถิ่น","label-for":"heritage_name_local","label-cols-sm":"5"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"heritage_name_local","value":_vm.heritageDetail.name_local},on:{"change":function($event){return _vm.updateData({name_local: $event})}}})],1)],1),_c('b-form-group',{attrs:{"label":"ตัวแทนผู้สืบทอด","label-for":"inheritor","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({inheritor:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.inheritor),callback:function ($$v) {_vm.inheritor=$$v},expression:"inheritor"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ข้อมูลทางภูมิศาสตร์","label-for":"geography","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({geography:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.geography),callback:function ($$v) {_vm.geography=$$v},expression:"geography"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ผู้ติดต่อประสานงาน","label-for":"contact","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({contact:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"สาระสำคัญของมรดกภูมิปัญญาทางวัฒนธรรม","label-for":"descriptor_detail","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({descriptor_detail:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.descriptor_detail),callback:function ($$v) {_vm.descriptor_detail=$$v},expression:"descriptor_detail"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"การสนับสนุนเพื่อรับรองมรดกภูมิปัญญาทางวัฒนธรรมจะได้รับรู้\n      ตระหนักและกล่าวถึงอย่างต่อเนื่อง","label-for":"support_detail","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({support_detail:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.support_detail),callback:function ($$v) {_vm.support_detail=$$v},expression:"support_detail"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"การขึ้นทะเบียนมรดกภูมิปัญญาทางวัฒนธรรม","label-for":"support_register","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({support_register:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.support_register),callback:function ($$v) {_vm.support_register=$$v},expression:"support_register"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"มาตรการในการป้องกันมรดกภูมิปัญญาทางวัฒนธรรม","label-for":"protection_detail","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({protection_detail:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.protection_detail),callback:function ($$v) {_vm.protection_detail=$$v},expression:"protection_detail"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ความต้องการมาตรการในการปกป้อง","label-for":"protection_need","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({protection_need:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.protection_need),callback:function ($$v) {_vm.protection_need=$$v},expression:"protection_need"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ชุมชน กลุ่มคน/บุคคล","label-for":"protection_people","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({protection_people:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.protection_people),callback:function ($$v) {_vm.protection_people=$$v},expression:"protection_people"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ภาครัฐ หน่วยงานภาคสังคม","label-for":"protection_government","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({protection_government:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.protection_government),callback:function ($$v) {_vm.protection_government=$$v},expression:"protection_government"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ผู้ที่มีส่วนเกี่ยวข้อง","label-for":"protection_other","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({protection_other:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.protection_other),callback:function ($$v) {_vm.protection_other=$$v},expression:"protection_other"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"การมีส่วนร่วมของชุมชนที่มีต่อมรดกภูมิปัญญาทางวัฒนธรรม","label-for":"participation_heritage","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({participation_heritage:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.participation_heritage),callback:function ($$v) {_vm.participation_heritage=$$v},expression:"participation_heritage"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"หน่วยงานในระดับชุมชน","label-for":"participation_contact","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({participation_contact:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.participation_contact),callback:function ($$v) {_vm.participation_contact=$$v},expression:"participation_contact"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"มาตรการในการส่งเสริมและการรักษามรดกภูมิปัญญาทางวัฒนธรรม","label-for":"treatment_detail","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({treatment_detail:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.treatment_detail),callback:function ($$v) {_vm.treatment_detail=$$v},expression:"treatment_detail"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"การสืบสานและถ่ายทอด (ระบุวิธีดำเนินงาน พื้นที่ ชุมชน ระยะเวลา\n      และงบประมาณ)","label-for":"treatment_relay","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({treatment_relay:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.treatment_relay),callback:function ($$v) {_vm.treatment_relay=$$v},expression:"treatment_relay"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"หมายเหตุ","label-for":"note","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({note:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{staticClass:"uploadLabel",attrs:{"label":"ไฟล์ .pdf","label-for":"file_pdf","label-cols-sm":"5"}},[(_vm.heritageDetail.file_pdf)?_c('b-button',{staticClass:"labelBtnOpenFile",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openFile(_vm.heritageDetail.file_pdf)}}},[_vm._v(" เปิดไฟล์")]):_vm._e(),_c('label',{staticClass:"labelBtnUploadFile",attrs:{"for":"uploadPdf"}},[_vm._v(" อัปโหลดไฟล์ ")]),_c('input',{ref:"pdfFile",attrs:{"id":"uploadPdf","type":"file","accept":".pdf"},on:{"change":function($event){return _vm.uploadPdfFile()}}})],1),_c('b-form-group',{attrs:{"label":"ไฟล์ .docx","label-for":"file_word","label-cols-sm":"5"}},[(_vm.heritageDetail.file_word)?_c('b-button',{staticClass:"labelBtnOpenFile",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.openFile(_vm.heritageDetail.file_word)}}},[_vm._v(" เปิดไฟล์")]):_vm._e(),_c('label',{staticClass:"labelBtnUploadFile",attrs:{"for":"uploadWord"}},[_vm._v(" อัปโหลดไฟล์ ")]),_c('input',{ref:"wordFile",attrs:{"id":"uploadWord","type":"file","accept":".docx, .doc"},on:{"change":function($event){return _vm.uploadWordFile()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }