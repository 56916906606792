/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import userAPI from '@/api/user';
// import _ from 'lodash';

const state = {
  tableData: [],
  listUsers: [],
  selectedUserType: 'All',
  listUserType: ['All', 'admin', 'user'],
  showLoading: false,
  userDetail: {},
};

const getters = {
  listUsers: (state) => state.listUsers,
  selectedUserType: (state) => state.selectedUserType,
  listUserType: (state) => state.listUserType,
  showLoading: (state) => state.showLoading,
  userDetail: (state) => state.userDetail,
};

const actions = {
  async getData({
    commit,
  }) {
    commit('SET_SHOW_LOADING', true);
    const params = {
      $limit: 50000,
      $skip: 0,
    };
    const allUser = await userAPI.getAllUser(params);
    const listUser = allUser.data;

    console.log(listUser);

    listUser.splice(0, 1); // remove anonymous user

    await commit('SET_LIST_USER', listUser);
    await commit('SET_DATA', listUser);
    await commit('UPDATE_TABLE', listUser);
    commit('SET_SHOW_LOADING', false);
  },

  async getUserDetail({
    commit,
  }, id) {
    commit('SET_SHOW_LOADING', true);

    const userDetail = await userAPI.getUserDetail(id);
    await commit('SET_USER_DETAIL', userDetail);
    commit('SET_SHOW_LOADING', false);
  },
  async updateUserDetail({
    commit,
    state,
  }) {
    commit('SET_SHOW_LOADING', true);
    const updateDetail = state.userDetail;
    const updateUser = {
      email: updateDetail.email,
      name: updateDetail.name,
      profile_img: updateDetail.profile_img,
      role: updateDetail.role,
    };
    const userId = state.userDetail.id;
    const updateRes = await userAPI.updateUserDetail(userId, updateUser);

    if (updateRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  async deleteUser({
    commit,
  }, id) {
    commit('SET_SHOW_LOADING', true);
    const delRes = await userAPI.deleteUser(id);

    if (delRes) {
      commit('SET_SHOW_LOADING', false);
      return true;
    }
    commit('SET_SHOW_LOADING', false);
    return false;
  },
  setLoading({
    commit,
  }, data) {
    commit('SET_SHOW_LOADING', data);
  },
  setSelectedUserType({
    commit,
  }, data) {
    commit('SET_SELECTED_USER_TYPE', data);
  },
  filterCustom({
    commit,
  }, payload) {
    commit('SET_CUSTOM_FILTER', payload);
  },
  updateField({
    commit,
  }, data) {
    Object.keys(data).forEach((key) => {
      commit('UPDATE_FIELD', { [key]: data[key] });
    });
  },
  clearState({
    commit,
  }) {
    commit('CLEAR_STATE');
  },
};

const mutations = {
  UPDATE_TABLE(state, data) {
    state.data = data;
  },
  SET_DATA(state, data) {
    state.tableData = data;
  },
  SET_SHOW_LOADING(state, data) {
    state.showLoading = data;
  },
  SET_SELECTED_USER_TYPE(state, data) {
    state.selectedUserType = data;
  },
  SET_LIST_USER(state, data) {
    state.listUser = data;
  },
  SET_USER_DETAIL(state, data) {
    state.userDetail = data;
  },
  UPDATE_FIELD(state, data) {
    Object.assign(state.userDetail, data);
  },
  CLEAR_STATE(state) {
    state.selectedUserType = 'All';
    state.showLoading = false;
    state.userDetail = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
