import Knowledge from '@/views/Knowledge.vue';
import KnowledgeDetail from '@/views/KnowledgeDetail.vue';

export default {
  path: 'backoffice/knowledge',
  name: 'All knowledge',
  redirect: 'backoffice/knowledge/all',
  component: {
    render(h) {
      return h('router-view');
    },
  },
  children: [
    {
      path: 'all',
      name: '    ',
      component: Knowledge,
    },
    {
      path: 'detail/:id',
      name: 'Knowledge Details',
      component: KnowledgeDetail,
    },
  ],
};
