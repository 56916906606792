<template>
  <aside class="aside-menu">
    <div>test</div>
  </aside>
</template>

<script>
export default {
  name: 'c-aside',
};
</script>
