<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group label-for="knowledge_name_eng"
        label-cols-sm="5" class="nameHeigth" :style="checkNameEng ? 'color:red' : 'color:black'">
          <template v-slot:label>
            ชื่อมองรู้ดูออกภาษาอังกฤษ <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-form-input type="text" name="knowledge_name_eng" :value="knowledgeDetail.name_eng"
            @change="updateData({name_eng: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="แท็กของมองรู้ดูออกภาษาอังกฤษ" label-for="knowledge_tag_eng"
        label-cols-sm="5" class="nameHeigth">
          <b-input-group>
            <b-form-input type="text" name="knowledge_tag_eng" :value="knowledgeDetail.tag_eng"
              @change="updateData({tag_eng: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="รายละเอียดมองรู้ดูออกภาษาอังกฤษ" label-for="detail_eng"
        label-cols-sm="5" v-if="knowledgeDetail.is_publication || knowledgeDetail.is_article"
        class="gridLabel">
          <quill-editor v-model="detail_eng" :options="editorOption" class="editorHeight"
          @change="updateData({detail_eng:
          `<div class=&quot;ql-editor&quot;>${$event.html}</div>`})"></quill-editor>
          <input type="file" id="getFileEng" @change="uploadFunction($event)" />
         <br><br><br>
        </b-form-group>

        <br>
        <b-form-group label="ไฟล์ .pdf ภาษาอังกฤษ" label-for="knowledge_file_pdf_eng"
        label-cols-sm="5" v-if="knowledgeDetail.is_publication">
          <b-button variant="warning" @click="openFile(knowledgeDetail.file_pdf_eng)"
          v-if="knowledgeDetail.file_pdf_eng" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadKnowledgePdfEng" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadKnowledgePdfEng" type="file" ref="knowledge_pdfFile_eng" accept=".pdf"
          @change="uploadKnowledgePdfEngFile()"/>
        </b-form-group>

        <b-form-group label="ไฟล์ .docx ภาษาอังกฤษ" label-for="knowledge_file_word_eng"
        label-cols-sm="5" v-if="knowledgeDetail.is_publication">
          <b-button variant="warning" @click="openFile(knowledgeDetail.file_word_eng)"
          v-if="knowledgeDetail.file_word_eng" class="labelBtnOpenFile">
            เปิดไฟล์</b-button>
          <label for="uploadKnowledgeWordEng" class="labelBtnUploadFile">
            อัปโหลดไฟล์
          </label>
          <input id="uploadKnowledgeWordEng" type="file" ref="knowledge_wordFile_eng"
          accept=".docx, .doc" @change="uploadKnowledgeWordEngFile()"/>
        </b-form-group>

        <b-col sm="12" v-if="knowledgeDetail.is_media">
          <br>
          <video width="60%" height="auto" controls v-if="detail_eng">
            <source :src="detail_eng"/>
          </video>
          <br>
          <label for="uploadKnowledgeVdoEng" class="labelBtnUploadImage">
              อัปโหลดวิดีโอ
          </label>
          <input id="uploadKnowledgeVdoEng" type="file" ref="knowledgeVdoEng"
          accept=".mp4"
          @change="uploadKnowledgeVdoEng()"/>
          <br>
          <br>
          <b-form-group label="ไฟล์คำบรรยายภาษาอังกฤษ" label-for="knowledge_sub_eng_path"
          label-cols-sm="5" v-if="knowledgeDetail.is_media">
            <b-button variant="warning" @click="openFile(knowledgeDetail.sub_eng_path)"
            v-if="knowledgeDetail.sub_eng_path" class="labelBtnOpenFile subFile">
              เปิดไฟล์</b-button>
            <label for="uploadKnowledgeSubEng" class="labelBtnUploadFile subFile">
              อัปโหลดไฟล์
            </label>
            <input id="uploadKnowledgeSubEng" type="file" ref="knowledge_subPath_eng" accept=".vtt"
            @change="uploadKnowledgeSubEngFile()"/>
          </b-form-group>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Knowledge', ['knowledgeDetail', 'createMode', 'checkNameEng']),
    KnowledgeData() {
      return this.knowledgeDetail;
    },
  },
  watch: {
    KnowledgeData() {
      this.detail_eng = this.knowledgeDetail.detail_eng ? this.knowledgeDetail.detail_eng : '';
    },
  },
  data() {
    return {
      detail_eng: '',
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike', { align: ['', 'center', 'right'] }, 'blockquote', 'link',
                { color: ['#000000', '#e60000', '#0000ff', '#ffff00', '#008a00'] }, { list: 'ordered' }, { list: 'bullet' },
                'image', 'video'],
            ],
            handlers: {
              image: this.insertFile,
              video: this.insertFile,
            },
          },
        },
      },
    };
  },
  methods: {
    ...mapActions('Knowledge', ['updateField', 'setLoading']),
    openFile(data) {
      window.open(data, '_blank');
    },
    async uploadKnowledgePdfEngFile() {
      this.setLoading(true);
      if (this.$refs.knowledge_pdfFile_eng.files[0]) {
        const file = this.$refs.knowledge_pdfFile_eng.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_pdf_eng: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadKnowledgeWordEngFile() {
      this.setLoading(true);
      if (this.$refs.knowledge_wordFile_eng.files[0]) {
        const file = this.$refs.knowledge_wordFile_eng.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ file_word_eng: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    async uploadKnowledgeVdoEng() {
      this.setLoading(true);
      if (this.$refs.knowledgeVdoEng.files[0]) {
        this.detail = false;
        const file = this.$refs.knowledgeVdoEng.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ detail_eng: uploadRes.data.url });
        }
        this.detail_eng = uploadRes.data.url;
      }
      this.setLoading(false);
    },
    async uploadKnowledgeSubEngFile() {
      this.setLoading(true);
      if (this.$refs.knowledge_subPath_eng.files[0]) {
        const file = this.$refs.knowledge_subPath_eng.files[0];
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);
        if (uploadRes.status === 200) {
          this.updateData({ sub_eng_path: uploadRes.data.url });
        }
      }
      this.setLoading(false);
    },
    insertFile() {
      document.getElementById('getFileEng').click();
    },
    async uploadFunction(e) {
      console.log('uploadFunction', e.target.files);
      this.setLoading(true);
      const file = e.target.files[0];
      console.log(file);
      const uploadRes = await this.uploadLocalFile(file, file.name);
      const fileLocation = uploadRes.data.url;
      const splitFileType = file.type.split('/');
      console.log(splitFileType);

      if (splitFileType[0] === 'image') {
        this.detail_eng += `<img src="${fileLocation}"/>`;
      } else {
        console.log('else');
        this.detail_eng += `<video width="500" controls src="${fileLocation}"></video>`;
      }
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedKnowledgeDetail', this.knowledgeDetail);
    },
  },
};
</script>

<style scope>
  .editorHeight {
    height: 200px;
  }
  input[type="file"] {
    display: none;
  }
  .labelBtnOpenFile {
    margin-right: 20px;
    margin-bottom: 2px;
  }
  .labelBtnUploadFile {
    color: #FFF200!important;
    background-color: #532ce0!important;
    padding: 7px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #cccccc;
    color: #666666;
  }
  .nameHeigth {
    height: 65px;
  }
  .gridLabel label {
    height: 60px;
  }
  .subFile {
    margin-bottom: 20px;
  }
</style>
