/* eslint no-param-reassign: ["error", { "props": false  }] */
/* eslint no-shadow: ["error", { "allow": ["state"] }] */
/* eslint-disable import/no-cycle */
import conceptProductAPI from '@/api/conceptProduct';
import _ from 'lodash';

const state = {
  tableData: [],
  selectedPublic: 'All',
  listConceptProduct: [],
  showLoading: false,
  listPublicType: ['All', 'เผยแพร่', 'แบบร่าง'],
  conceptProductDetail: {},
  listArea: [],
  listHeritage: [],
  listCategory: [],
  createMode: false,
  selectedCategory: 'All',
  listCategoryFilter: [],
  checkNameTh: false,
  checkNameEng: false,
  editorOption: {
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike', { align: ['', 'center', 'right'] }, 'blockquote', 'link',
            { list: 'ordered' }, { list: 'bullet' }],
        ],
      },
    },
  },
};

const getters = {
  listConceptProduct: (state) => state.listConceptProduct,
  showLoading: (state) => state.showLoading,
  selectedPublic: (state) => state.selectedPublic,
  listPublicType: (state) => state.listPublicType,
  conceptProductDetail: (state) => state.conceptProductDetail,
  listArea: (state) => state.listArea,
  listHeritage: (state) => state.listHeritage,
  listCategory: (state) => state.listCategory,
  createMode: (state) => state.createMode,
  editorOption: (state) => state.editorOption,
  selectedCategory: (state) => state.selectedCategory,
  listCategoryFilter: (state) => state.listCategoryFilter,
  checkNameTh: (state) => state.checkNameTh,
  checkNameEng: (state) => state.checkNameEng,
};

const actions = {
  async getData({
    commit,
  }) {
    commit('SET_LOADING', true);
    commit('SET_LIST_CONCEPT_PRODUCT', []);
    const listConceptProduct = [];
    const allConceptProducts = await conceptProductAPI.getConceptProduct();
    const allConceptProductCategory = await conceptProductAPI.getConceptProductCategory();
    const allArea = await conceptProductAPI.getArea();
    const allHeritage = await conceptProductAPI.getHeritage();

    const conceptProductCategory = allConceptProductCategory.data;
    const area = allArea.data;
    const heritage = allHeritage.data;
    const conceptProducts = allConceptProducts.data;
    for (let i = 0; i < conceptProducts.length; i += 1) {
      let productCategory = '-';
      for (let j = 0; j < conceptProductCategory.length; j += 1) {
        if (conceptProducts[i].category_id === conceptProductCategory[j].id) {
          productCategory = conceptProductCategory[j].name;
          break;
        }
      }
      let productArea = '-';
      for (let j = 0; j < area.length; j += 1) {
        if (conceptProducts[i].area_id === area[j].id) {
          productArea = area[j].name;
          break;
        }
      }
      let productHeritage = '-';
      for (let j = 0; j < heritage.length; j += 1) {
        if (conceptProducts[i].heritage_id === heritage[j].id) {
          productHeritage = heritage[j].name;
          break;
        }
      }
      let publicText;
      if (conceptProducts[i].public === 0) {
        publicText = 'แบบร่าง';
      } else {
        publicText = 'เผยแพร่';
      }
      const combineConceptProduct = {
        name: conceptProducts[i].name,
        category_id: productCategory,
        area_id: productArea,
        heritage_id: productHeritage,
        public: publicText,
        view: conceptProducts[i].view,
        create_at: conceptProducts[i].create_at,
        update_at: conceptProducts[i].update_at,
        id: conceptProducts[i].id,
      };
      listConceptProduct.push(combineConceptProduct);
    }

    const listCategoryFilter = [];
    listCategoryFilter.push('All');
    for (let i = 0; i < conceptProductCategory.length; i += 1) {
      listCategoryFilter.push(conceptProductCategory[i].name);
    }
    commit('SET_LIST_CATEGORY_FILTER', listCategoryFilter);
    await commit('SET_LIST_CONCEPT_PRODUCT', listConceptProduct);
    await commit('SET_DATA', listConceptProduct);
    await commit('UPDATE_TABLE', listConceptProduct);
    commit('SET_LOADING', false);
  },
  async getConceptProductDetail({
    commit,
    dispatch,
  }, id) {
    commit('SET_LOADING', true);
    let allConceptProductDetail;
    if (id === 'create') {
      allConceptProductDetail = {
        name: '',
        name_eng: '',
        category_id: 1,
        area_id: 1,
        profile_img: null,
        banner_img: null,
        public: 0,
      };
      commit('SET_CREATE_MODE', true);
    } else {
      allConceptProductDetail = await conceptProductAPI.getConceptProductById(id);

      const conceptVdo = await conceptProductAPI.getConceptVdo(id);
      const allConceptSub = await conceptProductAPI.getConceptSub(id);

      const vdoTh = [];
      const vdoEng = [];
      const subTh = [];
      const subEng = [];
      for (let i = 0; i < conceptVdo.data.length; i += 1) {
        if (conceptVdo.data[i].name) {
          vdoTh.push(conceptVdo.data[i]);
        } else {
          vdoEng.push(conceptVdo.data[i]);
        }
      }

      for (let i = 0; i < allConceptSub.data.length; i += 1) {
        if (allConceptSub.data[i].name) {
          subTh.push(allConceptSub.data[i]);
        } else {
          subEng.push(allConceptSub.data[i]);
        }
      }

      _.extend(allConceptProductDetail, { conceptVdo: vdoTh },
        { conceptVdoEng: vdoEng });

      const conceptSub = subTh;
      let subTitle1;
      let subTitle2;

      switch (subTh.length) {
        case 2:
          if (conceptSub[0].concept_product_media_id === vdoTh[0].id) {
            // sub 1 == video 1
            subTitle1 = conceptSub[0]; // eslint-disable-line
            subTitle2 = conceptSub[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = conceptSub[1]; // eslint-disable-line
            subTitle2 = conceptSub[0]; // eslint-disable-line
          }
          _.extend(allConceptProductDetail, { sub1: subTitle1 }, { sub2: subTitle2 });
          break;
        case 1:
          if (vdoTh.length > 1) {
            if (conceptSub[0].concept_product_media_id === vdoTh[0].id) {
              // sub 1 == video 1
              subTitle1 = conceptSub[0]; // eslint-disable-line
              _.extend(allConceptProductDetail, { sub1: subTitle1 });
            } else {
              // sub 2 == vdo 1
              subTitle2 = conceptSub[0]; // eslint-disable-line
              _.extend(allConceptProductDetail, { sub2: subTitle2 });
            }
          } else {
            subTitle1 = conceptSub[0]; // eslint-disable-line
            _.extend(allConceptProductDetail, { sub1: subTitle1 });
          }
          break;
        default:
          break;
      }

      const conceptSubEng = subEng;
      let subTitleEng1;
      let subTitleEng2;

      switch (subEng.length) {
        case 2:
          if (conceptSubEng[0].concept_product_media_id === vdoEng[0].id) {
            // sub 1 == video 1
            subTitleEng1 = conceptSubEng[0]; // eslint-disable-line
            subTitleEng2 = conceptSubEng[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitleEng1 = conceptSubEng[1]; // eslint-disable-line
            subTitleEng2 = conceptSubEng[0]; // eslint-disable-line
          }
          _.extend(allConceptProductDetail, { subEng1: subTitleEng1 }, { subEng2: subTitleEng2 });
          break;
        case 1:
          if (vdoEng.length > 1) {
            if (conceptSubEng[0].concept_product_media_id === vdoEng[0].id) {
              // sub 1 == video 1
              subTitleEng1 = conceptSubEng[0]; // eslint-disable-line
              _.extend(allConceptProductDetail, { subEng1: subTitleEng1 });
            } else {
              // sub 2 == vdo 1
              subTitleEng2 = conceptSubEng[0]; // eslint-disable-line
              _.extend(allConceptProductDetail, { subEng2: subTitleEng2 });
            }
          } else {
            subTitleEng1 = conceptSubEng[0]; // eslint-disable-line
            _.extend(allConceptProductDetail, { subEng1: subTitleEng1 });
          }
          break;
        default:
          break;
      }

      const allConceptPicture = await conceptProductAPI.getConceptPicture(id);
      _.extend(allConceptProductDetail, { conceptMultiFilesOld: allConceptPicture.data });

      commit('SET_CREATE_MODE', false);
    }
    commit('SET_CONCEPT_PRODUCT_DETAIL', allConceptProductDetail);
    await dispatch('getAllOptions');
    commit('SET_LOADING', false);
  },
  async getAllOptions({
    commit,
  }) {
    const listArea = await conceptProductAPI.getArea();
    const listCategory = await conceptProductAPI.getConceptProductCategory();
    const listHeritage = await conceptProductAPI.getHeritage();

    const listHeritageData = listHeritage.data;
    const heritageOptions = [];

    for (let i = 0; i < listHeritageData.length; i += 1) {
      let heritageName;
      if (listHeritageData[i].name.length > 100) {
        heritageName = `${listHeritageData[i].name.substring(0, 101)}...`;
      } else {
        heritageName = listHeritageData[i].name;
      }

      const combindHeritageIdName = {
        id: listHeritageData[i].id,
        name: heritageName,
        name_eng: listHeritageData[i].name_eng,
      };
      heritageOptions.push(combindHeritageIdName);
    }

    await commit('SET_LIST_AREA', listArea.data);
    await commit('SET_LIST_CATEGORY', listCategory.data);
    await commit('SET_LIST_HERITAGE', heritageOptions);
  },
  async updateConceptProductDetail({
    state,
    commit,
  }) {
    console.log('Update ConceptProduct');
    commit('SET_LOADING', true);

    const updateDetail = state.conceptProductDetail;
    const timeNow = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const updateConceptProduct = {
      name: updateDetail.name,
      category_id: updateDetail.category_id,
      area_id: updateDetail.area_id,
      heritage_id: updateDetail.heritage_id,
      concept: updateDetail.concept,
      contact: updateDetail.contact,
      detail_concept: updateDetail.detail_concept,
      detail_design: updateDetail.detail_design,
      detail_material: updateDetail.detail_material,
      profile_img: updateDetail.profile_img,
      banner_img: updateDetail.banner_img,
      public: updateDetail.public,
      keyword: updateDetail.keyword,
      name_eng: updateDetail.name_eng,
      concept_eng: updateDetail.concept_eng,
      contact_eng: updateDetail.contact_eng,
      detail_concept_eng: updateDetail.detail_concept_eng,
      detail_design_eng: updateDetail.detail_design_eng,
      detail_material_eng: updateDetail.detail_material_eng,
      update_at: timeNow,
    };

    let updateRes;
    if (state.createMode) {
      _.extend(updateConceptProduct, { create_at: timeNow, view: 0 });
      updateRes = await conceptProductAPI.createConceptProduct(updateConceptProduct);

      if (updateDetail.vdo1) {
        const updateVdo1 = {
          concept_product_id: updateRes.id,
          name: updateDetail.vdo1.name,
          file_path: updateDetail.vdo1.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          concept_product_media_id: null,
          name_eng: null,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdo1 = await conceptProductAPI.createConceptMedia(updateVdo1);

        if (updateDetail.sub1) {
          // create sub 1
          const updateSub1 = {
            concept_product_id: updateRes.id,
            name: updateDetail.sub1.name,
            file_path: updateDetail.sub1.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            concept_product_media_id: vdo1.id,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await conceptProductAPI.createConceptMedia(updateSub1);
        }
      }

      if (updateDetail.vdo2) {
        const updateVdo2 = {
          concept_product_id: updateRes.id,
          name: updateDetail.vdo2.name,
          file_path: updateDetail.vdo2.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          concept_product_media_id: null,
          name_eng: null,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdo2 = await conceptProductAPI.createConceptMedia(updateVdo2);

        if (updateDetail.sub2) {
          // create sub 2
          const updateSub2 = {
            concept_product_id: updateRes.id,
            name: updateDetail.sub2.name,
            file_path: updateDetail.sub2.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            concept_product_media_id: vdo2.id,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await conceptProductAPI.createConceptMedia(updateSub2);
        }
      }

      if (updateDetail.vdoEng1) {
        const updateVdoEng1 = {
          concept_product_id: updateRes.id,
          name: null,
          file_path: updateDetail.vdoEng1.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          concept_product_media_id: null,
          name_eng: updateDetail.vdoEng1.name_eng,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdoEng1 = await conceptProductAPI.createConceptMedia(updateVdoEng1);

        if (updateDetail.subEng1) {
          // create sub 1
          const updateSubEng1 = {
            concept_product_id: updateRes.id,
            name: null,
            file_path: updateDetail.subEng1.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            concept_product_media_id: vdoEng1.id,
            name_eng: updateDetail.subEng1.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await conceptProductAPI.createConceptMedia(updateSubEng1);
        }
      }

      if (updateDetail.vdoEng2) {
        const updateVdoEng2 = {
          concept_product_id: updateRes.id,
          name: null,
          file_path: updateDetail.vdoEng2.file_path,
          is_picture: false,
          is_vdo: true,
          is_subtitle: false,
          concept_product_media_id: null,
          name_eng: updateDetail.vdoEng2.name_eng,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        const vdoEng2 = await conceptProductAPI.createConceptMedia(updateVdoEng2);

        if (updateDetail.subEng2) {
          // create sub 2
          const updateSubEng2 = {
            concept_product_id: updateRes.id,
            name: null,
            file_path: updateDetail.subEng2.file_path,
            is_picture: false,
            is_vdo: false,
            is_subtitle: true,
            concept_product_media_id: vdoEng2.id,
            name_eng: updateDetail.subEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          await conceptProductAPI.createConceptMedia(updateSubEng2);
        }
      }

      if (updateDetail.conceptMultiFiles) {
        const newMultiFile = [];
        for (let i = 0; i < updateDetail.conceptMultiFiles.length; i += 1) {
          const multiFile = {
            concept_product_id: updateRes.id,
            name: updateDetail.conceptMultiFiles[i].key,
            file_path: updateDetail.conceptMultiFiles[i].location,
            is_picture: true,
            is_vdo: false,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: updateDetail.conceptMultiFiles[i].key,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          newMultiFile.push(multiFile);
        }

        const promises = newMultiFile.map((val) => conceptProductAPI.createConceptMedia(val)
          .then((results) => {
            console.log(results);
            return val;
          }));
        Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
    } else {
      const idNum = updateDetail.id;
      updateRes = await conceptProductAPI.updateConceptProduct(idNum, updateConceptProduct);
      const allConceptVdo = await conceptProductAPI.getConceptVdo(idNum);
      const allConceptSub = await conceptProductAPI.getConceptSub(idNum);
      const conceptVdo = allConceptVdo.data;
      const conceptSub = allConceptSub.data;

      const vdoTh = [];
      const vdoEng = [];
      const subTh = [];
      const subEng = [];
      for (let i = 0; i < conceptVdo.length; i += 1) {
        if (conceptVdo[i].name) {
          vdoTh.push(conceptVdo[i]);
        } else {
          vdoEng.push(conceptVdo[i]);
        }
      }

      for (let i = 0; i < conceptSub.length; i += 1) {
        if (conceptSub[i].name) {
          subTh.push(conceptSub[i]);
        } else {
          subEng.push(conceptSub[i]);
        }
      }

      // create vdo sub th
      if (vdoTh.length === 2) {
        // Heritage has 2 vdo
        if (updateDetail.vdo1) {
          // update vdo 1
          const updateVdo1 = {
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
          };
          await conceptProductAPI.patchConceptMedia(vdoTh[0].id, updateVdo1);
        }

        if (updateDetail.vdo2) {
          // update vdo 2
          const updateVdo2 = {
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
          };
          await conceptProductAPI.patchConceptMedia(vdoTh[1].id, updateVdo2);
        }

        let subTitle1;
        let subTitle2;
        if (subTh.length === 2) {
          // have 2 subtitle
          if (subTh[0].concept_product_media_id === vdoTh[0].id) {
            // sub 1 == video 1
            subTitle1 = subTh[0]; // eslint-disable-line
            subTitle2 = subTh[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = subTh[1]; // eslint-disable-line
            subTitle2 = subTh[0]; // eslint-disable-line
          }

          if (updateDetail.sub1) {
            // update sub 1
            const updateSub1 = {
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
            };
            await conceptProductAPI.patchConceptMedia(subTitle1.id, updateSub1);
          }

          if (updateDetail.sub2) {
            // update sub 2
            const updateSub2 = {
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
            };
            await conceptProductAPI.patchConceptMedia(subTitle2.id, updateSub2);
          }
        }
        if (subTh.length === 1) {
          // heritage has 1 subtitle
          if (subTh[0].concept_product_media_id === vdoTh[0].id) {
            // has subtitle in vdo 1
            subTitle1 = subTh[0]; // eslint-disable-line

            if (updateDetail.sub1) {
              // update subtitle 1
              const updateSub1 = {
                name: updateDetail.sub1.name,
                file_path: updateDetail.sub1.file_path,
              };
              await conceptProductAPI.patchConceptMedia(subTitle1.id, updateSub1);
            }

            if (updateDetail.sub2) {
              // create subtitle 2
              const createSub2 = {
                concept_product_id: idNum,
                name: updateDetail.sub2.name,
                file_path: updateDetail.sub2.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                concept_product_media_id: vdoTh[1].id,
                name_eng: null,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await conceptProductAPI.createConceptMedia(createSub2);
            }
          } else {
            // has subtitle in vdo 2
            subTitle2 = subTh[0]; // eslint-disable-line

            if (updateDetail.sub2) {
              // update subtitle 2
              const updateSub2 = {
                name: updateDetail.sub2.name,
                file_path: updateDetail.sub2.file_path,
              };
              await conceptProductAPI.patchConceptMedia(subTitle2.id, updateSub2);
            }

            if (updateDetail.sub1) {
              // create subtitle 1
              const createSub1 = {
                concept_product_id: idNum,
                name: updateDetail.sub1.name,
                file_path: updateDetail.sub1.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                concept_product_media_id: vdoTh[0].id,
                name_eng: null,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await conceptProductAPI.createConceptMedia(createSub1);
            }
          }
        } else if (subTh.length === 0) {
          // heritage has no subtitle
          if (updateDetail.sub1) {
            // create subtitle 1
            const createSub1 = {
              concept_product_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdoTh[0].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(createSub1);
          }

          if (updateDetail.sub2) {
            // create subtitle 2
            const createSub2 = {
              concept_product_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdoTh[1].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(createSub2);
          }
        }
      } else if (vdoTh.length === 1) {
        // heritage has 1 video
        if (updateDetail.vdo1) {
          // update vdo 1
          const updateVdo1 = {
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
          };
          await conceptProductAPI.patchConceptMedia(vdoTh[0].id, updateVdo1);
        }

        let subTitle1;
        if (subTh.length === 1) {
          // heritage has 1 subtitle
          subTitle1 = subTh[0] // eslint-disable-line
          if (updateDetail.sub1) {
            // update subtitle 1
            const updateSub1 = {
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
            };
            await conceptProductAPI.patchConceptMedia(subTitle1.id, updateSub1);
          }
        } else {
          if (updateDetail.sub1) { // eslint-disable-line
            const updateSub1 = {
              concept_product_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdoTh[0].id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub1);
          }
        }

        if (updateDetail.vdo2) {
          // crate vdo 2
          const updateVdo2 = {
            concept_product_id: idNum,
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await conceptProductAPI.createConceptMedia(updateVdo2);

          if (updateDetail.sub2) {
            // create sub 2
            const updateSub2 = {
              concept_product_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdo2.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub2);
          }
        }
      } else {
        // heritage has no vdo
        if (updateDetail.vdo1) {
          // create vdo 1
          const updateVdo1 = {
            concept_product_id: idNum,
            name: updateDetail.vdo1.name,
            file_path: updateDetail.vdo1.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo1 = await conceptProductAPI.createConceptMedia(updateVdo1);

          if (updateDetail.sub1) {
            // create sub 2
            const updateSub1 = {
              concept_product_id: idNum,
              name: updateDetail.sub1.name,
              file_path: updateDetail.sub1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdo1.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub1);
          }
        }

        if (updateDetail.vdo2) {
          // create vdo 2
          const updateVdo2 = {
            concept_product_id: idNum,
            name: updateDetail.vdo2.name,
            file_path: updateDetail.vdo2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: null,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await conceptProductAPI.createConceptMedia(updateVdo2);

          if (updateDetail.sub2) {
            // create sub 2
            const updateSub2 = {
              concept_product_id: idNum,
              name: updateDetail.sub2.name,
              file_path: updateDetail.sub2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdo2.id,
              name_eng: null,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub2);
          }
        }
      }

      // create vdo sub eng
      if (vdoEng.length === 2) {
        // Heritage has 2 vdo
        if (updateDetail.vdoEng1) {
          // update vdo 1
          const updateVdo1 = {
            name_eng: updateDetail.vdoEng1.name_eng,
            file_path: updateDetail.vdoEng1.file_path,
          };
          await conceptProductAPI.patchConceptMedia(vdoEng[0].id, updateVdo1);
        }

        if (updateDetail.vdoEng2) {
          // update vdo 2
          const updateVdo2 = {
            name_eng: updateDetail.vdoEng2.name_eng,
            file_path: updateDetail.vdoEng2.file_path,
          };
          await conceptProductAPI.patchConceptMedia(vdoEng[1].id, updateVdo2);
        }

        let subTitle1;
        let subTitle2;
        if (subEng.length === 2) {
          // have 2 subtitle
          if (subEng[0].concept_product_media_id === vdoEng[0].id) {
            // sub 1 == video 1
            subTitle1 = subEng[0]; // eslint-disable-line
            subTitle2 = subEng[1]; // eslint-disable-line
          } else {
            // sub 2 == vdo 1
            subTitle1 = subEng[1]; // eslint-disable-line
            subTitle2 = subEng[0]; // eslint-disable-line
          }

          if (updateDetail.subEng1) {
            // update sub 1
            const updateSub1 = {
              name_eng: updateDetail.subEng1.name_eng,
              file_path: updateDetail.subEng1.file_path,
            };
            await conceptProductAPI.patchConceptMedia(subTitle1.id, updateSub1);
          }

          if (updateDetail.subEng2) {
            // update sub 2
            const updateSub2 = {
              name_eng: updateDetail.subEng2.name_eng,
              file_path: updateDetail.subEng2.file_path,
            };
            await conceptProductAPI.patchConceptMedia(subTitle2.id, updateSub2);
          }
        }
        if (subEng.length === 1) {
          // heritage has 1 subtitle
          if (subEng[0].concept_product_media_id === vdoEng[0].id) {
            // has subtitle in vdo 1
            subTitle1 = subEng[0]; // eslint-disable-line

            if (updateDetail.subEng1) {
              // update subtitle 1
              const updateSub1 = {
                name_eng: updateDetail.subEng1.name_eng,
                file_path: updateDetail.subEng1.file_path,
              };
              await conceptProductAPI.patchConceptMedia(subTitle1.id, updateSub1);
            }

            if (updateDetail.subEng2) {
              // create subtitle 2
              const createSub2 = {
                concept_product_id: idNum,
                name: null,
                file_path: updateDetail.subEng2.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                concept_product_media_id: vdoEng[1].id,
                name_eng: updateDetail.subEng2.name_eng,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await conceptProductAPI.createConceptMedia(createSub2);
            }
          } else {
            // has subtitle in vdo 2
            subTitle2 = subEng[0]; // eslint-disable-line

            if (updateDetail.subEng2) {
              // update subtitle 2
              const updateSub2 = {
                name_eng: updateDetail.subEng2.name_eng,
                file_path: updateDetail.subEng2.file_path,
              };
              await conceptProductAPI.patchConceptMedia(subTitle2.id, updateSub2);
            }

            if (updateDetail.subEng1) {
              // create subtitle 1
              const createSub1 = {
                concept_product_id: idNum,
                name: null,
                file_path: updateDetail.subEng1.file_path,
                is_picture: false,
                is_vdo: false,
                is_subtitle: true,
                concept_product_media_id: vdoEng[0].id,
                name_eng: updateDetail.subEng1.name_eng,
                create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
              };
              await conceptProductAPI.createConceptMedia(createSub1);
            }
          }
        } else if (subEng.length === 0) {
          // heritage has no subtitle
          if (updateDetail.subEng1) {
            // create subtitle 1
            const createSub1 = {
              concept_product_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdoEng[0].id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(createSub1);
          }

          if (updateDetail.subEng2) {
            // create subtitle 2
            const createSub2 = {
              concept_product_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdoEng[1].id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(createSub2);
          }
        }
      } else if (vdoEng.length === 1) {
        console.log('updateDetail Eng vdo 1', updateDetail.subEng1);
        // heritage has 1 video
        if (updateDetail.vdoEng1) {
          console.log('updateDetail Eng if 1', updateDetail.subEng1);
          // update vdo 1
          const updateVdo1 = {
            name_eng: updateDetail.vdoEng1.name_eng,
            file_path: updateDetail.vdoEng1.file_path,
          };
          await conceptProductAPI.patchConceptMedia(vdoEng[0].id, updateVdo1);
        }

        let subTitle1;
        if (subEng.length === 1) {
          // heritage has 1 subtitle
          subTitle1 = subEng[0] // eslint-disable-line
          if (updateDetail.subEng1) {
            console.log('updateDetail Eng else  1', updateDetail.subEng1);
            // update subtitle 1
            const updateSub1 = {
              name_eng: updateDetail.subEng1.name_eng,
              file_path: updateDetail.subEng1.file_path,
            };
            await conceptProductAPI.patchConceptMedia(subTitle1.id, updateSub1);
          }
        } else {
          console.log('updateDetail Eng 1', updateDetail.subEng1);

          if (updateDetail.subEng1) { // eslint-disable-line
            const updateSub1 = {
              concept_product_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdoEng[0].id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub1);
          }
        }

        if (updateDetail.vdoEng2) {
          // crate vdo 2

          console.log('create vdo 2');
          const updateVdo2 = {
            concept_product_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: updateDetail.vdoEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await conceptProductAPI.createConceptMedia(updateVdo2);

          if (updateDetail.subEng2) {
            // create sub 2
            console.log('create sub 2');

            const updateSub2 = {
              concept_product_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdo2.id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub2);
          }
        }
      } else {
        // heritage has no vdo
        if (updateDetail.vdoEng1) {
          // create vdo 1
          const updateVdo1 = {
            concept_product_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng1.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: updateDetail.vdoEng1.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo1 = await conceptProductAPI.createConceptMedia(updateVdo1);

          if (updateDetail.subEng1) {
            // create sub 2
            const updateSub1 = {
              concept_product_id: idNum,
              name: null,
              file_path: updateDetail.subEng1.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdo1.id,
              name_eng: updateDetail.subEng1.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub1);
          }
        }

        if (updateDetail.vdoEng2) {
          // create vdo 2
          const updateVdo2 = {
            concept_product_id: idNum,
            name: null,
            file_path: updateDetail.vdoEng2.file_path,
            is_picture: false,
            is_vdo: true,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: updateDetail.vdoEng2.name_eng,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          const vdo2 = await conceptProductAPI.createConceptMedia(updateVdo2);

          if (updateDetail.subEng2) {
            // create sub 2
            const updateSub2 = {
              concept_product_id: idNum,
              name: null,
              file_path: updateDetail.subEng2.file_path,
              is_picture: false,
              is_vdo: false,
              is_subtitle: true,
              concept_product_media_id: vdo2.id,
              name_eng: updateDetail.subEng2.name_eng,
              create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
            };
            await conceptProductAPI.createConceptMedia(updateSub2);
          }
        }
      }

      if (updateDetail.conceptMultiFiles) {
        const newMultiFile = [];
        for (let i = 0; i < updateDetail.conceptMultiFiles.length; i += 1) {
          const multiFile = {
            concept_product_id: updateRes.id,
            name: updateDetail.conceptMultiFiles[i].key,
            file_path: updateDetail.conceptMultiFiles[i].location,
            is_picture: true,
            is_vdo: false,
            is_subtitle: false,
            concept_product_media_id: null,
            name_eng: updateDetail.conceptMultiFiles[i].key,
            create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
          };
          newMultiFile.push(multiFile);
        }

        const promises = newMultiFile.map((val) => conceptProductAPI.createConceptMedia(val)
          .then((results) => {
            console.log(results);
            return val;
          }));
        Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }

      if (updateDetail.deleteImageFiles) {
        const promises = updateDetail.deleteImageFiles.map((val) => conceptProductAPI.deleteConceptMedia(val) // eslint-disable-line
          .then((results) => {
            console.log(results);
            return results;
          }));
        await Promise.all(promises).then(async (results) => {
          console.log('result', results);
        });
      }
    }

    if (updateRes) {
      commit('SET_LOADING', false);
      return true;
    }
    commit('SET_LOADING', false);
    return false;
  },
  async deleteConceptProduct({
    commit,
  }, idNum) {
    console.log('Delete Concept Product');
    commit('SET_LOADING', true);
    const deleteRes = await conceptProductAPI.delConceptProduct(idNum);

    if (deleteRes) {
      commit('SET_LOADING', false);
      return true;
    }
    commit('SET_LOADING', false);
    return false;
  },
  updateField({
    commit,
  }, data) {
    Object.keys(data).forEach((key) => {
      commit('UPDATE_FIELD', {
        [key]: data[key],
      });
    });
  },
  setLoading({
    commit,
  }, data) {
    commit('SET_LOADING', data);
  },
  setSelectedPublic({
    commit,
  }, data) {
    commit('SET_SELECTED_PUBLIC_TYPE', data);
  },
  setSelectedCategory({
    commit,
  }, data) {
    commit('SET_SELECTED_CATEGORY', data);
  },
  filterCustom({
    commit,
  }, payload) {
    commit('SET_CUSTOM_FILTER', payload);
  },
  setCheckNameTh({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_TH', data);
  },
  setCheckNameEng({
    commit,
  }, data) {
    commit('SET_CHECK_NAME_ENG', data);
  },
  clearState({
    commit,
  }) {
    commit('CLEAR_STATE');
  },
};

const mutations = {
  UPDATE_TABLE(state, data) {
    state.data = data;
  },
  SET_DATA(state, data) {
    state.tableData = data;
  },
  SET_LIST_CONCEPT_PRODUCT(state, data) {
    state.listConceptProduct = data;
  },
  SET_LOADING(state, data) {
    state.showLoading = data;
  },
  CLEAR_STATE(state) {
    state.showLoading = false;
    state.selectedPublic = 'All';
    state.selectedCategory = 'All';
    state.conceptProductDetail = {};
    state.createMode = false;
    state.checkNameTh = false;
    state.checkNameEng = false;
  },
  SET_SELECTED_PUBLIC_TYPE(state, data) {
    state.selectedPublic = data;
  },
  UPDATE_FIELD(state, data) {
    Object.assign(state.conceptProductDetail, data);
  },
  SET_LIST_PUBLIC_TYPE(state, data) {
    state.listPublicType = data;
  },
  SET_CONCEPT_PRODUCT_DETAIL(state, data) {
    state.conceptProductDetail = data;
  },
  SET_LIST_AREA(state, data) {
    state.listArea = data;
  },
  SET_LIST_CATEGORY(state, data) {
    state.listCategory = data;
  },
  SET_LIST_HERITAGE(state, data) {
    state.listHeritage = data;
  },
  SET_CREATE_MODE(state, data) {
    state.createMode = data;
  },
  SET_SELECTED_CATEGORY(state, data) {
    state.selectedCategory = data;
  },
  SET_LIST_CATEGORY_FILTER(state, data) {
    state.listCategoryFilter = data;
  },
  SET_CHECK_NAME_TH(state, data) {
    state.checkNameTh = data;
  },
  SET_CHECK_NAME_ENG(state, data) {
    state.checkNameEng = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
