/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  getKnowledge(params) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/knowledge',
        params,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  getKnowledgeCategory() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/knowledge-category',
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  getUser() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/users',
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  getKnowledgeById(id) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/knowledge/${id}`,
        params: {
          backoffice: 1,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  getUserById(id) {
    return new Promise(((resolve, reject) => {
      API.get({
        url: `/users/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  updateKnowledge(id, knowledge) {
    return new Promise(((resolve, reject) => {
      API.patch({
        url: `/knowledge/${id}`,
        data: {
          ...knowledge,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  createKnowledge(knowledge) {
    return new Promise(((resolve, reject) => {
      API.post({
        url: '/knowledge',
        data: {
          ...knowledge,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
  deleteKnowledge(id) {
    return new Promise(((resolve, reject) => {
      API.del({
        url: `/knowledge/${id}`,
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((error) => {
        reject(error);
      });
    }));
  },
};
