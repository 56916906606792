import AllConceptProduct from '@/views/ConceptProduct.vue';
import ConceptProductDetail from '@/views/ConceptProductDetail.vue';

export default {
  path: 'backoffice/conceptproduct',
  name: 'All Concept Product',
  redirect: 'backoffice/conceptproduct/all',
  component: {
    render(h) {
      return h('router-view');
    },
  },
  children: [
    {
      path: 'all',
      name: ' ',
      component: AllConceptProduct,
    },
    {
      path: 'detail/:id',
      name: 'Concept Product Details',
      component: ConceptProductDetail,
    },
  ],
};
