<template>
  <div class="app">
    <Header/>
    <div class="app-body">
      <Sidebar :navItems="nav" />
      <main class="main">
        <breadcrumb :list="list" />
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <!-- <AppAside/> -->
    </div>
    <AppFooter/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header/Header.vue';
import navData from '../_nav';
import {
  Sidebar,
  // Aside as AppAside,
  Footer as AppFooter,
  Breadcrumb,
} from '../components';

export default {
  name: 'full',
  components: {
    Header,
    Sidebar,
    // AppAside,
    AppFooter,
    Breadcrumb,
  },
  data() {
    return {
      nav: navData.items,
    };
  },
  computed: {
    ...mapGetters('User', ['role']),
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched;
    },
  },
  mounted() {
    this.checkRole();
  },
  methods: {
    checkRole() {
      console.log('role', this.role);
      if (this.role !== 'admin') {
        console.log('not admin', this.nav);
        const newNav = [];
        for (let i = 0; i < this.nav.length; i += 1) {
          if (this.nav[i].id === 1 || this.nav[i].id === 5) {
            newNav.push(this.nav[i]);
          }
        }
        this.nav = newNav;
      }
      // const newNav = [];
      // for (let i = 0; i < this.nav.length; i += 1) {
      //   if (this.nav[i].id === 1 || this.nav[i].id === 2) {
      //     newNav.push(this.nav[i]);
      //   }
      // }
      // this.nav = newNav;
    },
  },
};
</script>

<style>
  @import '../../static/css/bootstrap.min.css'; /* store file in local because not use when build */
  @import '../../static/css/custom.css';
  @import '../../static/css/jkanban.min.css';
  @import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;700&display=swap');

  #app {
    font-family: K2D, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
  }

  .modal {
    font-family: K2D, Helvetica, Arial, sans-serif;
  }

  .img-avatar {
    border: 1px solid #d0d0d0;
  }

  .headerDropdown ul {
    top: 7px!important;
    width: 184px;
    border-radius: 0 0 20px 20px;
  }

  .dropdownMenu .dropdown-header {
    color: white;
    background-color: #532ce0;
  }

  .dropdownMenu .dropdown-item {
    text-align: center;
  }

  .dropdownMenu {
    border: 1px solid #532ce0;
    border-radius: 0 0 20px 20px;
  }

  .btnLogout a:hover{
    border-radius: 0px 0px 20px 20px;
  }

  #nav {
    padding: 30px;
  }

  #nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  #nav a.router-link-exact-active {
    color: #42b983;
  }
  html, body {
    font-family: 'Roboto', sans-serif;
  }
  .app-body {
    background-color: #fff;
  }

  .form-row {
    text-align: left;
  }

  .form-inline label {
    justify-content: end!important;
    padding: 5px 0;
    font-weight: 600;
  }

  .form-inline {
    margin: 0 20px 15px 20px;
  }

  .table-bordered td, .table-bordered td {
    border: none!important;
  }

  .VueTables__sortable {
    background-color: #532ce0!important;
    color: #FFF200;
  }

  .VueTables__heading {
    color: #FFF200!important;
  }

  table td {
    color: #173B6B;
  }
  .tableLatestArticle .thead-light th {
    color: #FFF200!important;
    background-color: #532ce0!important;
  }

  .tableLatestArticle {
    overflow-x: auto;
  }

  .pagination li.active .page-link {
    background-color: #532ce0;
    border-color: #532ce0;
  }

  .pagination li .page-link {
    color: #532ce0;
  }

  #checkStatusModal .modal-header .close {
    display: none;
  }

  .form-control {
    border-radius: 15px!important;
  }

  .modal-title{
    color: #FFF200!important;
  }
  .modal-header {
    background-color: #532ce0!important;
  }

  @media (min-width: 576px) {
    .form-inline .form-control {
      display: inline-block;
      width: 100%!important;
      vertical-align: middle;
    }
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.5px #f6a122;
    border-color: #f6a122;
  }

  .modal-footer .btn-primary,
  .modal-footer .btn-secondary {
    border-color: #f6a122;
    box-shadow: 0 0 0 0.5px #f6a122;
  }

  .btn-primary {
    background-color: #f6a122;
    color: #fff;
    border-color: #f6a122;
    margin-top: 0;
    box-shadow: 0 0 0 0.5px #f6a122;
  }

  .btn-primary:hover {
    background-color: #f6a122;
    border-color: #f6a122;
  }

  @media (min-width: 768px) {
    .VueTables__search-field {
      width: 300px;
    }
    .modalBatch .modal-dialog {
      max-width: 70%;
    }
    .modalBatch .form-inline label {
      justify-content: end;
      padding: 5px 0;
      font-weight: 600;
    }
  }

  .mx-datepicker input {
    border-radius: 20px;
  }

  .VueTables__search-field input {
    border-radius: 20px;
  }

  .VueTables__limit-field select {
    border-radius: 20px;
  }

  .form-control:focus {
    box-shadow: 0 0 0 0.5px #f6a122;
    border-color: #f6a122;
  }

  .VueTables__sortable {
    background-color: #f2f2f2;
    border: 0px;
  }

  .VueTables__heading {
    color: #565656;
  }

  .VuePagination {
    margin-left: 10px;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  .table thead th {
    border: 0px;
  }

  .table-bordered th {
    border: 0px;
  }

  .table-bordered td {
    border: 0px;
    border-bottom: 1px solid #a4b7c1;
  }

  .main .container-fluid {
    padding: 0;
  }

  .page-link.active {
    color: white;
  }

  .card {
    border: none;
  }

  .vicp-wrap {
    border-radius: 20px!important;
    height: 280px!important;
  }

  .vicp-step1 {
    border-radius: 20px;
    border: 1px solid #f6a122;
  }

  .vue-image-crop-upload {
    border-radius: 20px;
  }
  @media (max-width: 576px) {
    .vue-image-crop-upload .vicp-wrap {
      width: 90%!important;
    }
  }
  .vicp-drop-area {
    border-radius: 20px;
    background-color: white!important;
  }

  .vicp-operate .btn-secondary {
    border-color: #f6a122;
    background-color: white;
    color: #f6a122;
    box-shadow: 0 0 0 0.5px #f6a122;
  }

  @media screen and (max-width: 768px) {
    .VueTables >.row > .col-md-12> .VueTables__limit {
      float: left !important;
    }
  }
</style>
