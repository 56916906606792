<template>
  <div>
    <b-row>
      <b-col sm="3">
        <b-form-group label="มรดกที่เกี่ยวข้อง" label-for="heritage"
        class="gridLabelConcept">
          <b-form-select @input="updateData({heritage_id: $event})"
          :value="conceptProductDetail.heritage_id ?
          conceptProductDetail.heritage_id : listHeritage[0].id ">
          <option v-for="heritage in listHeritage" :key="heritage.id"
          :value="heritage.id">{{heritage.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="หมวดหมู่แนวคิดผลิตภัณฑ์" label-for="category"
        class="gridLabelConcept">
          <b-form-select @input="updateData({category_id: $event})"
          :value="conceptProductDetail.category_id">
          <option v-for="category in listCategory" :key="category.id"
          :value="category.id">{{category.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="พื้นที่ตั้งของผลิตภัณฑ์" label-for="area" class="gridLabelConcept">
          <b-form-select @input="updateData({area_id: $event})"
          :value="conceptProductDetail.area_id">
          <option v-for="area in listArea" :key="area.id"
          :value="area.id">{{area.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="สถานะ" label-for="public" class="gridLabelConcept">
          <b-form-select @input="updateData({public: $event})"
          :value="conceptProductDetail.public">
          <option v-for="publics in pub" :key="publics.value"
          :value="publics.value">{{publics.text}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <b-form-group label="รูปปก" label-for="profile_img">
          <b-img :src="conceptProductDetail.profile_img" class="showImage"
          v-if="conceptProductDetail.profile_img !== null" alt="profile image"></b-img>
          <br>
          <label for="uploadConceptProductProfile" class="labelBtnUploadImage">
            อัปโหลดรูปปก
          </label>
          <input id="uploadConceptProductProfile" type="file" ref="conceptProductProfile"
          accept=".jpg, .png, .bmp" @change="uploadConceptProfileFile()"/>
          <p v-if="verifySizeProfile">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="รูป banner" label-for="banner_img">
          <b-img :src="conceptProductDetail.banner_img" class="showImage"
          v-if="conceptProductDetail.banner_img !== null" alt="banner image"></b-img>
          <br>
          <label for="uploadConceptProductBanner" class="labelBtnUploadImage">
            อัปโหลด banner
          </label>
          <input id="uploadConceptProductBanner" type="file" ref="conceptProductBanner"
          accept=".jpg, .png, .bmp" @change="uploadConceptBannerFile()"/>
          <p v-if="verifySizeBanner">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
        </b-form-group>
      </b-col>

      <b-col sm="6">
        <b-form-group label="keyword ที่ใช้ SEO" label-for="keyword" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="keyword" :value="conceptProductDetail.keyword"
            @change="updateData({keyword: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <br>
    <br>

    <b-row>
      <b-col md="6">
        <p>วิดีโอภาษาไทย</p>
        <b-row>
          <b-col sm="6">
            <video width="100%" height="auto" controls v-if="vdo1">
              <source :src="vdo1"/>
            </video>
            <label for="uploadConceptVideo1" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadConceptVideo1" type="file" ref="conceptVideo1"
              accept=".mp4"
              @change="uploadConceptVideo1()"/>
              <br>
              <br>
              <p>{{sub1}}</p>
              <label for="uploadConceptSubtitle1" :class="vdo1 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadConceptSubtitle1" type="file" ref="conceptSubtitle1"
              accept=".vtt"
              @change="uploadConceptSubtitle1()" :disabled="!vdo1"/>
          </b-col>

          <b-col sm="6" v-if="vdo1">
            <video width="100%" height="auto" controls v-if="vdo2">
              <source :src="vdo2"/>
            </video>
            <label for="uploadConceptVideo2" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadConceptVideo2" type="file" ref="conceptVideo2"
              accept=".mp4"
              @change="uploadConceptVideo2()"/>
              <br>
              <br>
              <p>{{sub2}}</p>
              <label for="uploadConceptSubtitle2" :class="vdo2 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadConceptSubtitle2" type="file" ref="conceptSubtitle2"
              accept=".vtt"
              @change="uploadConceptSubtitle2()" :disabled="!vdo2"/>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <p>วิดีโอภาษาอังกฤษ</p>
        <b-row>
          <b-col sm="6">
            <video width="100%" height="auto" controls v-if="vdoEng1">
              <source :src="vdoEng1"/>
            </video>
            <label for="uploadConceptVideoEng1" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadConceptVideoEng1" type="file" ref="conceptVideoEng1"
              accept=".mp4"
              @change="uploadConceptVideoEng1()"/>
              <br>
              <br>
              <p>{{subEng1}}</p>
              <label for="uploadConceptSubtitleEng1" :class="vdoEng1 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadConceptSubtitleEng1" type="file" ref="conceptSubtitleEng1"
              accept=".vtt"
              @change="uploadConceptSubtitleEng1()" :disabled="!vdoEng1"/>
          </b-col>

          <b-col sm="6" v-if="vdoEng1">
            <video width="100%" height="auto" controls v-if="vdoEng2">
              <source :src="vdoEng2"/>
            </video>
            <label for="uploadConceptVideoEng2" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadConceptVideoEng2" type="file" ref="conceptVideoEng2"
              accept=".mp4"
              @change="uploadConceptVideoEng2()"/>
              <br>
              <br>
              <p>{{subEng2}}</p>
              <label for="uploadConceptSubtitleEng2" :class="vdoEng2 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadConceptSubtitleEng2" type="file" ref="conceptSubtitleEng2"
              accept=".vtt"
              @change="uploadConceptSubtitleEng2()" :disabled="!vdoEng2"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br>
    <br>
    <br>
    <b-row>
      <b-col sm="12">
       <p>รูปที่เกี่ยวข้อง</p>
       <div v-if="showMultipleFile" style="display: flex; flex-wrap: wrap;">
          <span v-for="item in conceptProductDetail.conceptMultiFilesOld" :key="item.key">
            <img :src="item.file_path" class="imgBox"/>
            <br>
            <b-form-checkbox :id="item.id.toString()" :value="item.id" v-if="deleteImage"
            @change="deleteImageSelected(item.id)">
              เลือกลบรูปภาพ
            </b-form-checkbox>
            <br>
          </span>
        </div>
        <div v-if="!deleteImage">
          <label for="uploadConceptMulti" class="labelBtnUploadImage">
              อัปโหลดรูปที่เกี่ยวข้อง
          </label>
          <input id="uploadConceptMulti" type="file" ref="multipleConceptFile"
            v-on:change="uploadMultipleConceptFile()" multiple/>
            <b-button variant="danger" style="margin-left: 10px;" @click="deleteImageClick(true)">
            เลือกลบรูปที่เกี่ยวข้อง
          </b-button>
        </div>
        <div v-else>
          <b-button variant="success" style="margin-left: 10px;" @click="confirmDeleteImage">
            ลบรูปภาพ
          </b-button>
          <b-button variant="danger" style="margin-left: 10px;" @click="deleteImageClick(false)">
            ยกเลิก
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="!createMode">
      <p>เวลาที่สร้าง {{formateDate(conceptProductDetail.create_at)}}</p>
      <p>เวลาที่อัปเดตล่าสุด {{formateDate(conceptProductDetail.update_at)}}</p>
    </div>
    <b-modal title="ยืนยันลบรูปที่เกี่ยวข้อง"
    id="confirmDeleteModal" centered class="modal-primary"
    v-model="confirmDeleteModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmDeleteModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="deleteMultiImage">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('ConceptProduct', ['conceptProductDetail', 'listHeritage', 'listArea', 'listCategory', 'createMode']),

    ConceptProductData() {
      return this.conceptProductDetail;
    },
  },
  watch: {
    ConceptProductData() {
      if (this.conceptProductDetail.conceptVdo) {
        this.vdo1 = this.conceptProductDetail.conceptVdo.length > 0 ? this.conceptProductDetail.conceptVdo[0].file_path : '';
        this.vdo2 = this.conceptProductDetail.conceptVdo.length > 1 ? this.conceptProductDetail.conceptVdo[1].file_path : '';
        this.sub1 = this.conceptProductDetail.sub1 ? this.conceptProductDetail.sub1.name : '';
        this.sub2 = this.conceptProductDetail.sub2 ? this.conceptProductDetail.sub2.name : '';
      }

      if (this.conceptProductDetail.conceptVdoEng) {
        this.vdoEng1 = this.conceptProductDetail.conceptVdoEng.length > 0 ? this.conceptProductDetail.conceptVdoEng[0].file_path : '';
        this.vdoEng2 = this.conceptProductDetail.conceptVdoEng.length > 1 ? this.conceptProductDetail.conceptVdoEng[1].file_path : '';
        this.subEng1 = this.conceptProductDetail.subEng1 ? this.conceptProductDetail.subEng1.name_eng : '';
        this.subEng2 = this.conceptProductDetail.subEng2 ? this.conceptProductDetail.subEng2.name_eng : '';
      }
    },
  },
  data() {
    return {
      pub: [{
        value: 0,
        text: 'แบบร่าง',
      },
      {
        value: 1,
        text: 'เผยแพร่',
      },
      ],
      verifySizeProfile: false,
      verifySizeBanner: false,
      vdo1: '',
      vdo2: '',
      sub1: '',
      sub2: '',
      vdoEng1: '',
      vdoEng2: '',
      subEng1: '',
      subEng2: '',
      showMultipleFile: true,
      deleteImage: false,
      deleteImageId: [],
      allDeleteImageId: [],
      confirmDeleteModal: false,
    };
  },
  methods: {
    ...mapActions('ConceptProduct', ['updateField', 'setLoading']),
    formateDate(date) {
      return date ? moment(date).format('DD/MM/yyyy, h:mm:ss a') : moment(this.conceptProductDetail.create_at).format('DD/MM/yyyy, h:mm:ss a');
    },
    deleteImageClick(selected) {
      if (selected) {
        this.deleteImage = true;
      } else {
        this.deleteImage = false;
        this.deleteImageId = [];
      }
    },
    deleteImageSelected(id) {
      const idIndex = this.deleteImageId.findIndex((element) => element === id);
      if (idIndex !== -1) {
        this.deleteImageId.splice(idIndex, 1);
      } else {
        this.deleteImageId.push(id);
      }
      console.log(this.deleteImageId);
    },
    confirmDeleteImage() {
      this.confirmDeleteModal = true;
    },
    async deleteMultiImage() {
      this.confirmDeleteModal = false;
      this.deleteImage = false;
      this.showMultipleFile = false;
      const imgFilesOld = _.cloneDeep(this.conceptProductDetail.conceptMultiFilesOld);
      for (let i = 0; i < this.deleteImageId.length; i += 1) {
        for (let j = 0; j < imgFilesOld.length; j += 1) {
          if (imgFilesOld[j].id === this.deleteImageId[i]) {
            imgFilesOld.splice(j, 1);
          }
        }
      }
      this.allDeleteImageId.push(...this.deleteImageId);
      this.updateData({ conceptMultiFilesOld: imgFilesOld });
      this.updateData({ deleteImageFiles: this.allDeleteImageId });
      this.showMultipleFile = true;
      this.deleteImageId = [];
    },
    async uploadConceptProfileFile() {
      this.setLoading(true);
      if (this.$refs.conceptProductProfile.files[0]) {
        const file = this.$refs.conceptProductProfile.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeProfile = true;
        } else {
          this.verifySizeProfile = false;
          const uploadRes = await this.uploadLocalFile(file, name);
          if (uploadRes.status === 200) {
            this.updateData({ profile_img: uploadRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadConceptBannerFile() {
      this.setLoading(true);
      if (this.$refs.conceptProductBanner.files[0]) {
        const file = this.$refs.conceptProductBanner.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeBanner = true;
        } else {
          this.verifySizeBanner = false;
          const uplaodRes = await this.uploadLocalFile(file, name);
          if (uplaodRes.status === 200) {
            this.updateData({ banner_img: uplaodRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    async uploadConceptVideo1() {
      this.setLoading(true);
      if (this.$refs.conceptVideo1.files[0]) {
        this.vdo1 = false;
        const file = this.$refs.conceptVideo1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo1 = {
            name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdo1: objVdo1 });
          this.vdo1 = uploadRes.data.url;
          console.log('vdo1', this.vdo1);
        }
      }
      this.setLoading(false);
    },
    async uploadConceptSubtitle1() {
      this.setLoading(true);
      if (this.$refs.conceptSubtitle1.files[0]) {
        const file = this.$refs.conceptSubtitle1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub1 = {
            name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub1);
          this.updateData({ sub1: objSub1 });
          this.sub1 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadConceptVideo2() {
      this.setLoading(true);
      if (this.$refs.conceptVideo2.files[0]) {
        this.vdo2 = false;
        const file = this.$refs.conceptVideo2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo2 = {
            name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdo2: objVdo2 });
          this.vdo2 = uploadRes.data.url;
        }
      }
      this.setLoading(false);
    },
    async uploadConceptSubtitle2() {
      this.setLoading(true);
      if (this.$refs.conceptSubtitle2.files[0]) {
        const file = this.$refs.conceptSubtitle2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub2 = {
            name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub2);
          this.updateData({ sub2: objSub2 });
          this.sub2 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadConceptVideoEng1() {
      this.setLoading(true);
      if (this.$refs.conceptVideoEng1.files[0]) {
        this.vdoEng1 = false;
        const file = this.$refs.conceptVideoEng1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo1 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdoEng1: objVdo1 });
          this.vdoEng1 = uploadRes.data.url;
        }
      }
      this.setLoading(false);
    },
    async uploadConceptSubtitleEng1() {
      this.setLoading(true);
      if (this.$refs.conceptSubtitleEng1.files[0]) {
        const file = this.$refs.conceptSubtitleEng1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub1 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub1);
          this.updateData({ subEng1: objSub1 });
          this.subEng1 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadConceptVideoEng2() {
      this.setLoading(true);
      if (this.$refs.conceptVideoEng2.files[0]) {
        this.vdoEng2 = false;
        const file = this.$refs.conceptVideoEng2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo2 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdoEng2: objVdo2 });
          this.vdoEng2 = uploadRes.data.url;
        }
      }
      this.setLoading(false);
    },
    async uploadConceptSubtitleEng2() {
      this.setLoading(true);
      if (this.$refs.conceptSubtitleEng2.files[0]) {
        const file = this.$refs.conceptSubtitleEng2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub2 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub2);
          this.updateData({ subEng2: objSub2 });
          this.subEng2 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadMultipleConceptFile() {
      this.setLoading(true);
      this.showMultipleFile = false;
      const file = this.$refs.multipleConceptFile.files;
      const dataFile = new FormData();
      for (let i = 0; i < file.length; i += 1) {
        dataFile.append('file', file[i]);
      }
      const uploadRes = await uploadAPI.uploadFiles(dataFile);
      const combineOldMultiFile = this.conceptProductDetail.conceptMultiFilesOld
        ? _.cloneDeep(this.conceptProductDetail.conceptMultiFilesOld) : [];
      for (let i = 0; i < uploadRes.data.url.length; i += 1) {
        const multiFile = {
          name: uploadRes.data.url[i].key,
          file_path: uploadRes.data.url[i].location,
          is_picture: true,
          is_vdo: false,
          is_subtitle: false,
          concept_product_media_id: null,
          name_eng: uploadRes.data.url[i].key,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        combineOldMultiFile.push(multiFile);
      }
      this.updateData({ conceptMultiFilesOld: combineOldMultiFile });
      this.updateData({ conceptMultiFiles: uploadRes.data.url });
      this.showMultipleFile = true;
      this.setLoading(false);
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
    },
  },
};
</script>

<style>
  .labelBtnUploadImage {
    margin-top: 10px;
    color: #FFF200!important;
    background-color: #532ce0!important;
    padding: 6px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #cccccc;
    color: #666666;
    height: 40px;
  }
  .disableLabelBtnUploadFile {
    background-color: #cccccc;
    color: #666666;
    padding: 6px;
    border-radius: 5px;
  }

  .showImage {
    height: 100px;
    width: auto;
  }

  @media (max-width: 768px) {
    .gridLabelConcept label {
      height: 45px;
    }
  }

  @media (max-width: 450px) {
    .gridLabelConcept label {
      height: 30px;
    }
  }
</style>
