<template>
  <div>
    <p class="titleDetailUser">ข้อมูลผู้ใช้</p>
    <b-row>
      <b-col md="6">
        <b-form-group label="ชื่อผู้ใช้" label-for="user_name" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="user_name" :value="userDetail.name"
            @change="updateData({name: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="อีเมล" label-for="user_email" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="user_email" :value="userDetail.email"
            @change="updateData({email: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="สิทธิ์การใช้งาน" label-for="user_role" label-cols-sm="5">
          <b-form-radio-group id="radio-group-1" v-model="selected" name="user_role"
            :options="options" @change="changeRole"
          ></b-form-radio-group>
        </b-form-group>
      </b-col>

      <b-col md="5" offset-md="1">
        <b-form-group label="รูปปกผู้ใช้" label-for="profile_img">
          <b-img :src="userDetail.profile_img" width="auto" height="130px"
          v-if="userDetail.profile_img" alt="profile image"></b-img>
          <br>
          <label for="uploadUserProfile" class="labelBtnUploadImage">
            อัปโหลดรูปปก
          </label>
          <input id="uploadUserProfile" type="file" ref="userProfile"
          accept=".jpg, .png, .bmp" @change="uploadUserProfileFile()"/>
          <p v-if="verifySizeProfile">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
        </b-form-group>
      </b-col>
    </b-row>

    <p :class="resultTextClass">{{resultText}}</p>
    <b-button variant="danger" class="btnForm1Detail"
    @click="confirmDeleteModal=true">
      ลบ
    </b-button>
    <b-button variant="success" class="btnForm1Detail"
    @click="confirmUpdateUserModal=true">
      บันทึกข้อมูล
    </b-button>
    <b-modal :title="titleUpdateUserModal"
    id="confirmUpdateUserModal" centered class="modal-primary"
    v-model="confirmUpdateUserModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmUpdateUserModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="updateUser">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal :title="titleDeleteUserModal"
    id="confirmDeleteModal" class="modal-primary" v-model="confirmDeleteModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmDeleteModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success"
            @click="delUser">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Users', ['userDetail']),
    userData() {
      return this.userDetail.name;
    },
  },
  watch: {
    userData() {
      this.selected = this.userDetail.role;
      this.titleUpdateUserModal = `คุณแน่ใจใช่ไหมที่แก้ไขข้อมูล ${this.userDetail.name}`;
      this.titleDeleteUserModal = `คุณแน่ใจใช่ไหมที่จะลบ ${this.userDetail.name}`;
    },
  },
  data() {
    return {
      confirmUpdateUserModal: false,
      titleUpdateUserModal: '',
      resultText: '',
      resultTextClass: '',
      confirmDeleteModal: false,
      titleDeleteUserModal: '',
      verifySizeProfile: '',
      options: [
        { text: 'admin', value: 'admin' },
        { text: 'user', value: 'user' },
      ],
      selected: '',
    };
  },
  methods: {
    ...mapActions('Users', ['updateField', 'setLoading', 'updateUserDetail', 'deleteUser']),
    async updateUser() {
      this.confirmUpdateUserModal = false;
      const result = await this.updateUserDetail();
      this.resultText = result ? 'อัปเดตข้อมูลสำเร็จ' : 'อัปเดตข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
      this.resultTextClass = result ? 'resultSuccess' : 'resultFail';
    },
    async delUser() {
      this.confirmDeleteModal = false;
      const result = await this.deleteUser(this.userDetail.id);
      if (result) {
        this.$router.push('/backoffice/users/all');
      } else {
        this.resultText = 'ลบข้อมูลไม่สำเร็จ กรุณาลองใหม่หรือติดต่อแอดมิน';
        this.resultTextClass = 'resultFail';
      }
    },
    changeRole() {
      console.log(this.selected);
      this.updateField({ role: this.selected });
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
    },
    async uploadUserProfileFile() {
      this.setLoading(true);
      if (this.$refs.userProfile.files[0]) {
        const file = this.$refs.userProfile.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeProfile = true;
        } else {
          this.verifySizeProfile = false;
          const uploadRes = await this.uploadLocal(file, name);
          if (uploadRes.status === 200) {
            this.updateData({ profile_img: uploadRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadLocal(file, fileName) {
      const uploadRes = await uploadAPI.uploadFile(file, fileName);
      return uploadRes;
    },
  },
};
</script>

<style scope>
  .titleDetailUser {
    font-size: 20px;
    color: #173b6b;
  }
  .resultSuccess {
    color: #28a745;
    font-size: 20px;
  }
  .resultFail {
    color: red;
    font-size: 20px;
  }
</style>
