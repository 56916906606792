var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{staticClass:"nameLabelProduct",style:(_vm.checkNameEng ? 'color:red' : 'color:black'),attrs:{"label-for":"product_name_eng","label-cols-sm":"5"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" ชื่อผลิตภัณฑ์ภาษาอังกฤษ "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"product_name_eng","value":_vm.productDetail.name_eng},on:{"change":function($event){return _vm.updateData({name_eng: $event})}}})],1)],1),_c('b-form-group',{attrs:{"label":"แนวคิดจากชุมชนภาษาอังกฤษ","label-for":"product_concept_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({concept_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.concept_eng),callback:function ($$v) {_vm.concept_eng=$$v},expression:"concept_eng"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ผู้ติดต่อประสานงานภาษาอังกฤษ","label-for":"contact_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({contact_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.contact_eng),callback:function ($$v) {_vm.contact_eng=$$v},expression:"contact_eng"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ทรัพย์สินทางปัญญาภาษาอังกฤษ","label-for":"product_ip_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({product_ip_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.product_ip_eng),callback:function ($$v) {_vm.product_ip_eng=$$v},expression:"product_ip_eng"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"แนวความคิดภาษาอังกฤษ","label-for":"detail_concept_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_concept_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_concept_eng),callback:function ($$v) {_vm.detail_concept_eng=$$v},expression:"detail_concept_eng"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"รูปแบบการแสดงภาษาอังกฤษ","label-for":"detail_show_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_show_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_show_eng),callback:function ($$v) {_vm.detail_show_eng=$$v},expression:"detail_show_eng"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"จำนวนนักแสดงภาษาอังกฤษ","label-for":"detail_number_people_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_number_people_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_number_people_eng),callback:function ($$v) {_vm.detail_number_people_eng=$$v},expression:"detail_number_people_eng"}})],1),_c('br'),_c('br'),_c('br'),_c('b-form-group',{attrs:{"label":"ความยาวของการแสดงภาษาอังกฤษ","label-for":"detail_length_eng","label-cols-sm":"5"}},[_c('quill-editor',{staticClass:"editorHeight",attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData({detail_length_eng:
        ("<div class=\"ql-editor\">" + ($event.html) + "</div>")})}},model:{value:(_vm.detail_length_eng),callback:function ($$v) {_vm.detail_length_eng=$$v},expression:"detail_length_eng"}})],1),_c('br'),_c('br'),_c('br')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }