<template>
  <div>
    <b-row>
      <b-col sm="3">
        <b-form-group label="ลักษณะของมรดกภูมิปัญญาทางวัฒนธรรม" label-for="category"
        class="gridLabelHeri">
          <b-form-select @input="updateData({category_id: $event})"
          :value="heritageDetail.category_id">
          <option v-for="category in listCategory" :key="category.id"
          :value="category.id">{{category.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="พื้นที่ตั้งของมรดก" label-for="area" class="gridLabelHeri">
          <b-form-select @input="updateData({area_id: $event})"
          :value="heritageDetail.area_id">
          <option v-for="area in listArea" :key="area.id"
          :value="area.id">{{area.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="หมวดหมู่ที่ตั้งมรดกภูมิปัญญาทางวัฒนธรรม" label-for="heritage"
        class="gridLabelHeri">
          <b-form-select @input="updateData({area_heritage_category_id: $event})"
          :value="heritageDetail.area_heritage_category_id">
          <option v-for="areaHeritage in listAreaHeritageCategory" :key="areaHeritage.id"
          :value="areaHeritage.id">{{areaHeritage.name}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="3">
        <b-form-group label="สถานะ" label-for="public" class="gridLabelHeri">
          <b-form-select @input="updateData({public: $event})"
          :value="heritageDetail.public">
          <option v-for="publics in pub" :key="publics.value"
          :value="publics.value">{{publics.text}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="3">
        <b-form-group label="รูปปกของมรดกภูมิปัญญาทางวัฒนธรรม" label-for="profile_img"
        class="gridLabelHeri">
          <b-img :src="heritageDetail.profile_img" class="showImage"
          v-if="heritageDetail.profile_img" alt="profile image"></b-img>
        </b-form-group>
        <br>
        <label for="uploadHeritageProfile" class="labelBtnUploadImage">
          อัปโหลดรูปปก
        </label>
        <input id="uploadHeritageProfile" type="file" ref="heritageProfile"
        accept=".jpg, .png, .bmp" @change="uploadHeritageProfileFile()"/>
        <p v-if="verifySizeProfile">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col sm="3">
        <b-form-group label="รูป banner" label-for="banner_img" class="gridLabelHeri">
          <b-img :src="heritageDetail.banner_img" class="showImage"
          v-if="heritageDetail.banner_img" alt="banner image"></b-img>
        </b-form-group>
        <br>
        <label for="uploadHeritageBanner" class="labelBtnUploadImage">
          อัปโหลด Banner
        </label>
        <input id="uploadHeritageBanner" type="file" ref="heritageBanner"
        accept=".jpg, .png, .bmp" @change="uploadHeritageBannerFile()"/>
        <p v-if="verifySizeBanner">ขนาดไฟล์ใหญ่เกิน 3 MB</p>
      </b-col>

      <b-col sm="6">
        <b-form-group label="ละติจูดของมรดกภูมิปัญญาทางวัฒนธรรม" label-for="lat" label-cols-sm="5"
        class="locationHeigth">
          <b-input-group>
            <b-form-input type="text" name="lat" :value="heritageDetail.lat"
            @change="updateData({lat: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="ลองจิจูดของมรดกภูมิปัญญาทางวัฒนธรรม" label-for="lng" label-cols-sm="5"
        class="locationHeigth">
          <b-input-group>
            <b-form-input type="text" name="lng" :value="heritageDetail.lng"
            @change="updateData({lng: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="keyword ที่ใช้ SEO" label-for="keyword" label-cols-sm="5">
          <b-input-group>
            <b-form-input type="text" name="keyword" :value="heritageDetail.keyword"
            @change="updateData({keyword: $event})">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <br>

    <b-row>
      <b-col md="6">
        <p>วิดีโอภาษาไทย</p>
        <b-row>
          <b-col sm="6">
            <video width="100%" height="auto" controls v-if="vdo1">
              <source :src="vdo1"/>
            </video>
            <label for="uploadHeritageVideo1" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadHeritageVideo1" type="file" ref="heritageVideo1"
              accept=".mp4"
              @change="uploadHeritageVideo1()"/>
              <br>
              <br>
              <p>{{sub1}}</p>
              <label for="uploadHeritageSubtitle1" :class="vdo1 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadHeritageSubtitle1" type="file" ref="heritageSubtitle1"
              accept=".vtt"
              @change="uploadHeritageSubtitle1()" :disabled="!vdo1"/>
          </b-col>

          <b-col sm="6" v-if="vdo1">
            <video width="100%" height="auto" controls v-if="vdo2">
              <source :src="vdo2"/>
            </video>
            <label for="uploadHeritageVideo2" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadHeritageVideo2" type="file" ref="heritageVideo2"
              accept=".mp4"
              @change="uploadHeritageVideo2()"/>
              <br>
              <br>
              <p>{{sub2}}</p>
              <label for="uploadHeritageSubtitle2" :class="vdo2 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadHeritageSubtitle2" type="file" ref="heritageSubtitle2"
              accept=".vtt"
              @change="uploadHeritageSubtitle2()" :disabled="!vdo2"/>
          </b-col>
        </b-row>
      </b-col>

      <b-col md="6">
        <p>วิดีโอภาษาอังกฤษ</p>
        <b-row>
          <b-col sm="6">
            <video width="100%" height="auto" controls v-if="vdoEng1">
              <source :src="vdoEng1"/>
            </video>
            <label for="uploadHeritageVideoEng1" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadHeritageVideoEng1" type="file" ref="heritageVideoEng1"
              accept=".mp4"
              @change="uploadHeritageVideoEng1()"/>
              <br>
              <br>
              <p>{{subEng1}}</p>
              <label for="uploadHeritageSubtitleEng1" :class="vdoEng1 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadHeritageSubtitleEng1" type="file" ref="heritageSubtitleEng1"
              accept=".vtt"
              @change="uploadHeritageSubtitleEng1()" :disabled="!vdoEng1"/>
          </b-col>

          <b-col sm="6" v-if="vdoEng1">
            <video width="100%" height="auto" controls v-if="vdoEng2">
              <source :src="vdoEng2"/>
            </video>
            <label for="uploadHeritageVideoEng2" class="labelBtnUploadImage">
                อัปโหลดวิดีโอ
              </label>
              <input id="uploadHeritageVideoEng2" type="file" ref="heritageVideoEng2"
              accept=".mp4"
              @change="uploadHeritageVideoEng2()"/>
              <br>
              <br>
              <p>{{subEng2}}</p>
              <label for="uploadHeritageSubtitleEng2" :class="vdoEng2 ? 'labelBtnUploadImage'
              : 'disableLabelBtnUploadFile'">
                อัปโหลดคำบรรยาย
              </label>
              <input id="uploadHeritageSubtitleEng2" type="file" ref="heritageSubtitleEng2"
              accept=".vtt"
              @change="uploadHeritageSubtitleEng2()" :disabled="!vdoEng2"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <br>
    <br>
    <br>
    <b-row>
      <b-col sm="12">
        <p>รูปที่เกี่ยวข้อง</p>
        <div v-if="showMultipleFile" style="display: flex; flex-wrap: wrap;">
          <span v-for="item in heritageDetail.heritageMultiFilesOld" :key="item.key">
            <img :src="item.file_path" class="imgBox"/>
            <br>
            <b-form-checkbox :id="item.id.toString()" :value="item.id" v-if="deleteImage"
            @change="deleteImageSelected(item.id)">
              เลือกลบรูปภาพ
            </b-form-checkbox>
            <br>
          </span>
        </div>
        <div v-if="!deleteImage">
          <label for="uploadHeritageMulti" class="labelBtnUploadImage">
              อัปโหลดรูปที่เกี่ยวข้อง
          </label>
          <input id="uploadHeritageMulti" type="file" ref="multipleHeritageFile"
            v-on:change="uploadMultipleHeritageFile()" multiple/>
          <b-button variant="danger" style="margin-left: 10px;" @click="deleteImageClick(true)">
            เลือกลบรูปที่เกี่ยวข้อง
          </b-button>
        </div>
        <div v-else>
          <b-button variant="success" style="margin-left: 10px;" @click="confirmDeleteImage">
            ลบรูปภาพ
          </b-button>
          <b-button variant="danger" style="margin-left: 10px;" @click="deleteImageClick(false)">
            ยกเลิก
          </b-button>
        </div>
      </b-col>
    </b-row>
    <div v-if="!createMode">
      <br>
      <p>เวลาที่สร้าง {{formateDate(heritageDetail.create_at)}}</p>
      <p>เวลาที่อัปเดตล่าสุด {{formateDate(heritageDetail.update_at)}}</p>
    </div>
    <b-modal title="ยืนยันลบรูปที่เกี่ยวข้อง"
    id="confirmDeleteModal" centered class="modal-primary"
    v-model="confirmDeleteModal">
      <div slot="modal-footer" class="w-100">
        <b-row class="float-right">
          <b-col sm="12">
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="danger" @click="confirmDeleteModal=false">
              ยกเลิก
            </b-btn>
            <b-btn size="lg" style="margin-right:20px;margin-top:15px;"
            class="float-right" variant="success" @click="deleteMultiImage">
              ยืนยัน
            </b-btn>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import uploadAPI from '@/api/upload';

export default {
  computed: {
    ...mapGetters('Heritage', ['heritageDetail', 'listAreaHeritageCategory', 'listArea', 'listCategory', 'createMode']),
    HeritageData() {
      return this.heritageDetail;
    },
  },
  watch: {
    HeritageData() {
      if (this.heritageDetail.heritageVdo) {
        this.vdo1 = this.heritageDetail.heritageVdo.length > 0 ? this.heritageDetail.heritageVdo[0].file_path : '';
        this.vdo2 = this.heritageDetail.heritageVdo.length > 1 ? this.heritageDetail.heritageVdo[1].file_path : '';
        this.sub1 = this.heritageDetail.sub1 ? this.heritageDetail.sub1.name : '';
        this.sub2 = this.heritageDetail.sub2 ? this.heritageDetail.sub2.name : '';
      }

      if (this.heritageDetail.heritageVdoEng) {
        this.vdoEng1 = this.heritageDetail.heritageVdoEng.length > 0 ? this.heritageDetail.heritageVdoEng[0].file_path : '';
        this.vdoEng2 = this.heritageDetail.heritageVdoEng.length > 1 ? this.heritageDetail.heritageVdoEng[1].file_path : '';
        this.subEng1 = this.heritageDetail.subEng1 ? this.heritageDetail.subEng1.name_eng : '';
        this.subEng2 = this.heritageDetail.subEng2 ? this.heritageDetail.subEng2.name_eng : '';
      }
    },
  },
  data() {
    return {
      pub: [
        {
          value: 0,
          text: 'แบบร่าง',
        },
        {
          value: 1,
          text: 'เผยแพร่',
        },
      ],
      verifySizeBanner: false,
      verifySizeProfile: false,
      vdo1: '',
      vdo2: '',
      sub1: '',
      sub2: '',
      vdoEng1: '',
      vdoEng2: '',
      subEng1: '',
      subEng2: '',
      showMultipleFile: true,
      deleteImage: false,
      deleteImageId: [],
      allDeleteImageId: [],
      confirmDeleteModal: false,
    };
  },
  methods: {
    ...mapActions('Heritage', ['updateField', 'setLoading']),
    formateDate(date) {
      return date ? moment(date).format('DD/MM/yyyy, h:mm:ss a') : moment(this.heritageDetail.create_at).format('DD/MM/yyyy, h:mm:ss a');
    },
    deleteImageClick(selected) {
      if (selected) {
        this.deleteImage = true;
      } else {
        this.deleteImage = false;
        this.deleteImageId = [];
      }
    },
    deleteImageSelected(id) {
      const idIndex = this.deleteImageId.findIndex((element) => element === id);
      if (idIndex !== -1) {
        this.deleteImageId.splice(idIndex, 1);
      } else {
        this.deleteImageId.push(id);
      }
      console.log(this.deleteImageId);
    },
    confirmDeleteImage() {
      this.confirmDeleteModal = true;
    },
    async deleteMultiImage() {
      this.confirmDeleteModal = false;
      this.deleteImage = false;
      this.showMultipleFile = false;
      const imgFilesOld = _.cloneDeep(this.heritageDetail.heritageMultiFilesOld);
      for (let i = 0; i < this.deleteImageId.length; i += 1) {
        for (let j = 0; j < imgFilesOld.length; j += 1) {
          if (imgFilesOld[j].id === this.deleteImageId[i]) {
            imgFilesOld.splice(j, 1);
          }
        }
      }
      this.allDeleteImageId.push(...this.deleteImageId);
      this.updateData({ heritageMultiFilesOld: imgFilesOld });
      this.updateData({ deleteImageFiles: this.allDeleteImageId });
      this.showMultipleFile = true;
      this.deleteImageId = [];
    },
    async uploadHeritageProfileFile() {
      this.setLoading(true);
      if (this.$refs.heritageProfile.files[0]) {
        const file = this.$refs.heritageProfile.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeProfile = true;
        } else {
          this.verifySizeProfile = false;
          const uploadRes = await this.uploadLocalFile(file, name);
          if (uploadRes.status === 200) {
            this.updateData({ profile_img: uploadRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageBannerFile() {
      this.setLoading(true);
      if (this.$refs.heritageBanner.files[0]) {
        const file = this.$refs.heritageBanner.files[0];
        const { name, size } = file;
        if (size > 3000000) {
          this.verifySizeBanner = true;
        } else {
          this.verifySizeBanner = false;
          const uplaodRes = await this.uploadLocalFile(file, name);
          if (uplaodRes.status === 200) {
            this.updateData({ banner_img: uplaodRes.data.url });
          }
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageVideo1() {
      this.setLoading(true);
      if (this.$refs.heritageVideo1.files[0]) {
        this.vdo1 = false;
        const file = this.$refs.heritageVideo1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo1 = {
            name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdo1: objVdo1 });
          this.vdo1 = uploadRes.data.url;
          console.log('vdo1', this.vdo1);
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageSubtitle1() {
      this.setLoading(true);
      if (this.$refs.heritageSubtitle1.files[0]) {
        const file = this.$refs.heritageSubtitle1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub1 = {
            name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub1);
          this.updateData({ sub1: objSub1 });
          this.sub1 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageVideo2() {
      this.setLoading(true);
      if (this.$refs.heritageVideo2.files[0]) {
        this.vdo2 = false;
        const file = this.$refs.heritageVideo2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo2 = {
            name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdo2: objVdo2 });
          this.vdo2 = uploadRes.data.url;
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageSubtitle2() {
      this.setLoading(true);
      if (this.$refs.heritageSubtitle2.files[0]) {
        const file = this.$refs.heritageSubtitle2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub2 = {
            name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub2);
          this.updateData({ sub2: objSub2 });
          this.sub2 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageVideoEng1() {
      this.setLoading(true);
      if (this.$refs.heritageVideoEng1.files[0]) {
        this.vdoEng1 = false;
        const file = this.$refs.heritageVideoEng1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo1 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdoEng1: objVdo1 });
          this.vdoEng1 = uploadRes.data.url;
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageSubtitleEng1() {
      this.setLoading(true);
      if (this.$refs.heritageSubtitleEng1.files[0]) {
        const file = this.$refs.heritageSubtitleEng1.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub1 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub1);
          this.updateData({ subEng1: objSub1 });
          this.subEng1 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageVideoEng2() {
      this.setLoading(true);
      if (this.$refs.heritageVideoEng2.files[0]) {
        this.vdoEng2 = false;
        const file = this.$refs.heritageVideoEng2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objVdo2 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          this.updateData({ vdoEng2: objVdo2 });
          this.vdoEng2 = uploadRes.data.url;
        }
      }
      this.setLoading(false);
    },
    async uploadHeritageSubtitleEng2() {
      this.setLoading(true);
      if (this.$refs.heritageSubtitleEng2.files[0]) {
        const file = this.$refs.heritageSubtitleEng2.files[0];
        console.log(file);
        const { name } = file;
        const uploadRes = await this.uploadLocalFile(file, name);

        if (uploadRes.status === 200) {
          const objSub2 = {
            name_eng: name,
            file_path: uploadRes.data.url,
          };
          console.log(objSub2);
          this.updateData({ subEng2: objSub2 });
          this.subEng2 = name;
        }
      }
      this.setLoading(false);
    },
    async uploadMultipleHeritageFile() {
      this.setLoading(true);
      this.showMultipleFile = false;
      const file = this.$refs.multipleHeritageFile.files;
      const dataFile = new FormData();
      for (let i = 0; i < file.length; i += 1) {
        dataFile.append('file', file[i]);
      }
      const uploadRes = await uploadAPI.uploadFiles(dataFile);
      const combineOldMultiFile = this.heritageDetail.heritageMultiFilesOld
        ? _.cloneDeep(this.heritageDetail.heritageMultiFilesOld) : [];
      for (let i = 0; i < uploadRes.data.url.length; i += 1) {
        const multiFile = {
          name: uploadRes.data.url[i].key,
          file_path: uploadRes.data.url[i].location,
          is_picture: true,
          is_vdo: false,
          is_subtitle: false,
          heritage_media_id: null,
          name_eng: uploadRes.data.url[i].key,
          create_at: new Date().toISOString().slice(0, 19).replace('T', ' '),
        };
        combineOldMultiFile.push(multiFile);
      }
      this.updateData({ heritageMultiFilesOld: combineOldMultiFile });
      this.updateData({ heritageMultiFiles: uploadRes.data.url });
      this.showMultipleFile = true;
      this.setLoading(false);
    },
    async uploadLocalFile(file, filename) {
      const uploadRes = await uploadAPI.uploadFile(file, filename);
      return uploadRes;
    },
    updateData(data) {
      console.log('updateData', data);
      this.updateField(data);
      console.log('updatedHeritageDetail', this.heritageDetail);
    },
  },
};
</script>

<style scope>
  .titleStatusRegist {
    font-size: 30px;
    color: #173B6B;
  }
  .lastNote {
    margin-bottom: 0;
  }
  .locationHeigth {
    height: 65px;
  }
  .imgBox {
    width: 200px;
    margin-right: 20px;
    margin-bottom: 30px;
  }

  .labelBtnUploadImage {
    margin-top: 10px;
    color: #FFF200!important;
    background-color: #532ce0!important;
    padding: 6px;
    border-radius: 3px;
    cursor: pointer;
    background-color: #cccccc;
    color: #666666;
  }

  .showImage {
    height: 100px;
    width: auto;
  }

  @media (max-width: 1072px) {
    .gridLabelHeri label {
      height: 65px;
    }
  }

  @media (max-width: 450px) {
    .gridLabelHeri label {
      height: 20px;
    }
  }
</style>
