/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Dashboard from './modules/dashboard';
import User from './modules/user';
import ConceptProduct from './modules/conceptProduct';
import Product from './modules/products';
import Heritage from './modules/heritage';
import Knowledge from './modules/knowledge';
import Detail from './modules/detail';
import Users from './modules/users';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    Dashboard,
    User,
    ConceptProduct,
    Product,
    Heritage,
    Knowledge,
    Detail,
    Users,
  },
  strict: debug,
  plugins: [createPersistedState()],
});
