<template>
  <div class="latestArticle">
    <b-card md="12">
      <b-row style="margin-bottom: 25px;flex-wrap: initial;">
        <b-col sm="6">
          <h4 class="card-title mb-0">บทความล่าสุด</h4>
        </b-col>
        <b-col sm="6" v-if="role !== 'admin'">
          <router-link to="knowledge/detail/create">
            <b-btn class="float-right createArticleBtn">
              เขียนบทความ
            </b-btn>
          </router-link>
        </b-col>
      </b-row>
      <b-table class="mb-0 table-outline tableLatestArticle"
      responsive="sm" hover :items="latestArticle"
      :fields="role == 'admin' ? tableFieldsAdmin : tableFields" head-variant="light">
      </b-table>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      tableFieldsAdmin: [
        {
          key: 'name',
          label: 'ชื่อบทความ',
        },
        {
          key: 'name_eng',
          label: 'ชื่อบทความภาษาอังกฤษ',
        },
        {
          key: 'owner',
          label: 'ผู้เผยแพร่',
        },
        {
          key: 'public',
          label: 'สถานะ',
        },
        {
          key: 'create_at',
          label: 'วันที่เขียน',
        },
        {
          key: 'update_at',
          label: 'อัปเดตล่าสุด',
        },
      ],
      tableFields: [
        {
          key: 'name',
          label: 'ชื่อบทความ',
        },
        {
          key: 'name_eng',
          label: 'ชื่อบทความภาษาอังกฤษ',
        },
        {
          key: 'public',
          label: 'สถานะ',
        },
        {
          key: 'create_at',
          label: 'วันที่เขียน',
        },
        {
          key: 'update_at',
          label: 'อัปเดตล่าสุด',
        },
      ],
    };
  },
  async created() {
    await this.$store.dispatch('Dashboard/clearState');
    await this.$store.dispatch('Dashboard/getDashboardData');
  },
  computed: {
    ...mapGetters('User', ['role']),
    ...mapGetters('Dashboard', ['latestArticle']),
  },
};
</script>

<style scoped>
  .latestArticle {
    padding: 0 30px;
  }
  .titleTable {
    padding-left: 30px;
    font-size: 20px;
    margin-bottom: 0;
    color: #173B6B;
  }
  .card {
    border: none;
  }
  .card-body {
    padding: 0;
  }
  .createArticleBtn {
    background: transparent linear-gradient(105deg,#002dff,#ff2aa1) 0 0 no-repeat padding-box;
  }
</style>
