import AllProduct from '@/views/Product.vue';
import ProductDetail from '@/views/ProductDetail.vue';

export default {
  path: 'backoffice/product',
  name: 'All Product',
  redirect: 'backoffice/product/all',
  component: {
    render(h) {
      return h('router-view');
    },
  },
  children: [
    {
      path: 'all',
      name: '  ',
      component: AllProduct,
    },
    {
      path: 'detail/:id',
      name: 'Product Details',
      component: ProductDetail,
    },
  ],
};
