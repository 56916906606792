<template>
  <div id="productDetail">
    <div class="overlay" v-if="showLoading">
      <div div style="position:fixed;
          top: 50%;
          left: 50%;
          z-index:999999">
        <vue-loaders-ball-spin-fade-loader color="#173B6B" scale="1.5">
        </vue-loaders-ball-spin-fade-loader>
      </div>
    </div>
    <span class="titleProduct">{{HeritageTitleName}}</span>
    <HeritageDetail></HeritageDetail>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeritageDetail from '../components/Heritage/HeritageDetail.vue';

export default {
  components: {
    HeritageDetail,
  },
  async created() {
    const { id } = this.$route.params;
    await this.$store.dispatch('Heritage/clearState');
    await this.$store.dispatch('Heritage/getHeritageDetail', id);
  },
  computed: {
    ...mapGetters('Heritage', ['heritageDetail', 'showLoading']),
    HeritageName() {
      return this.heritageDetail.name;
    },
  },
  watch: {
    HeritageName() {
      if (this.heritageDetail.name !== null && this.heritageDetail.name !== ''
        && this.heritageDetail.name !== undefined) {
        const trimTitle = this.heritageDetail.name.trim();
        if (trimTitle.length !== 0) {
          this.HeritageTitleName = this.heritageDetail.name;
        } else {
          this.HeritageTitleName = 'ชื่อมรดกภูมิปัญญาทางวัฒนธรรม';
        }
      } else {
        this.HeritageTitleName = 'ชื่อมรดกภูมิปัญญาทางวัฒนธรรม';
      }
    },
  },
  data() {
    return {
      HeritageTitleName: 'ชื่อมรดกภูมิปัญญาทางวัฒนธรรม',
    };
  },
};
</script>

<style>
  .titleProduct {
    font-size: 30px;
    color: #173B6B;
  }
  .adminLatestUpdate {
    margin-left: 30px;
    color: #28a745;
  }
  .smeLatestUpdate {
    margin-left: 30px;
    color: red;
  }
  #productDetail {
    padding-left: 20px;
  }
  .overlay {
    background-color:#EFEFEF;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0px;
    left: 0px;
    opacity: 0.3;
  }
</style>
