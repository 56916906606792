<template>
  <footer class="app-footer">
    <span>Copyright &copy; 2021 EPISG Mae Fah Luang University All Rights Reserved.</span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer',
};
</script>
<style scoped>
  .app-footer {
    background-color: #fff;
    border: none;
    border-top: 1px solid #c2cfd6;
  }
</style>
