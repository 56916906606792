import { render, staticRenderFns } from "./LatestArticle.vue?vue&type=template&id=c87ed6be&scoped=true&"
import script from "./LatestArticle.vue?vue&type=script&lang=js&"
export * from "./LatestArticle.vue?vue&type=script&lang=js&"
import style0 from "./LatestArticle.vue?vue&type=style&index=0&id=c87ed6be&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c87ed6be",
  null
  
)

export default component.exports