/* eslint-disable import/no-cycle */
import * as API from './base';

export default {
  getReportViewStat() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/view-stat',
        params: {
          $limit: 500000,
          report: true,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getReportUsageStat() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/usage-stat',
        params: {
          $limit: 500000,
          report: true,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
  getReportSearchStat() {
    return new Promise(((resolve, reject) => {
      API.get({
        url: '/search-history',
        params: {
          $limit: 500000,
          report: true,
        },
      }).then((response) => {
        resolve(response.axiosData);
      }).catch((err) => {
        reject(err);
      });
    }));
  },
};
