<template>
  <div v-if="role=='admin'">
    <br>
    <b-row>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">มรดกภูมิปัญญาทางวัฒนธรรม</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalHeritage}}</p>
        </div>
      </b-col>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">แนวคิดผลิตภัณฑ์</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalConceptProduct}}</p>
        </div>
      </b-col>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">ผลิตภัณฑ์ต้นแบบ</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalProduct}}</p>
        </div>
      </b-col>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">มองรู้ดูออก</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalKnowledge}}</p>
        </div>
      </b-col>
    </b-row>
    <br>
    <br>
    <b-row>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">บทความ(ที่เผยแพร่)</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalArticlePublic}}</p>
        </div>
      </b-col>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">บทความ(แบบร่าง)</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalArticlePrivate}}</p>
        </div>
      </b-col>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">สื่อมีเดีย</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalMedia}}</p>
        </div>
      </b-col>
      <b-col md="3">
        <div class="statusBox">
          <p class="textStatus">สิ่งพิมพ์</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalPublication}}</p>
        </div>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <br>
    <b-row>
      <b-col md="6">
        <div class="statusBox">
          <p class="textStatus">บทความ(ที่เผยแพร่)</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalArticlePublic}}</p>
        </div>
      </b-col>
      <b-col md="6">
        <div class="statusBox">
          <p class="textStatus">บทความ(แบบร่าง)</p>
          <br>
          <br>
          <br>
          <p class="valueStatus">{{totalArticlePrivate}}</p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      heritage: 25,
      concept_product: 12,
      product: 9,
      knowledge: 32,
    };
  },
  computed: {
    ...mapGetters('User', ['role']),
    ...mapGetters('Dashboard', ['totalHeritage', 'totalConceptProduct', 'totalProduct', 'totalKnowledge',
      'totalArticlePublic', 'totalArticlePrivate', 'totalMedia', 'totalPublication']),
  },
};
</script>

<style scoped>
  .row {
    padding: 0 30px;
  }
  .statusBox {
    background-color: #532ce0;
    padding: 15px;
    color: #FFF200;
    border-radius: 20px;
    padding-right: 20px;
  }
  .statusBox .textStatus {
    float: left;
    font-size: 25px;
  }
  .statusBox .icon {
    float: right;
    font-size: 30px;
  }
  .statusBox .valueStatus {
    font-size: 40px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 1300px) {
    .statusBox .textStatus {
      font-size: 20px;
    }
    .statusBox .icon {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 1120px) {
    .statusBox .textStatus {
      font-size: 18px;
    }
    .statusBox .icon {
      font-size: 14px;
    }
  }
    @media screen and (max-width: 1024px) {
    .statusBox .textStatus {
      font-size: 18px;
    }
    .statusBox .icon {
      display: none;
    }
  }
  @media screen and (max-width: 450px) {
    .statusBox {
      margin-bottom: 20px;
    }
    .statusBox .textStatus {
      font-size: 30px;
    }
    .statusBox .icon {
      display: block;
      font-size: 25px;
    }
  }
  @media screen and (max-width: 320px) {
    .statusBox .textStatus {
      font-size: 26px;
    }
    .statusBox .icon {
      font-size: 20px;
    }
  }
</style>
