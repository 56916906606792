<template>
  <div v-if="isExternalLink">
    <a :href="url" :class="classList">
      {{name}}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </a>
  </div>
  <div v-else>
    <router-link :to="url" :class="classList">
      {{name}}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{badge.text}}</b-badge>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'sidebar-nav-link',
  props: {
    name: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    badge: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: '',
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    classList() {
      return [
        'nav-link',
        this.linkVariant,
        ...this.itemClasses,
        'border-sidenav',
      ];
    },
    linkVariant() {
      return this.variant ? `nav-link-${this.variant}` : '';
    },
    itemClasses() {
      return this.classes ? this.classes.split(' ') : [];
    },
    isExternalLink() {
      if (this.url.substring(0, 4) === 'http') {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
  .nav-link {
    color: black;
  }
  .nav-link i {
    color: black;
  }
  .router-link-exact-active {
    color: white;
    background: #532ce0;
  }
  .router-link-active.open, .router-link-active i{
    color: white!important;
    background: #532ce0!important;
  }
  .nav-link:hover {
    background: #532ce0!important;
  }
  a {
    color: #532ce0;
  }
  /*
  .border-sidenav {
    border-right: 0.5px solid rgb(164, 183, 193) !important;
  }
  */
</style>
